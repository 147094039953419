import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import noDiaryPic from '../../../Assets/No_data.jpg';

const HorizontalScrollImageGallery = ({ imageUrls }) => {
    const { t } = useTranslation();
    return (
        <>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, mt: 2 }} variant="h6">{t('TodayUploadedPhotos') + ": " + (imageUrls.length)}</Typography>
            <Box
                sx={{
                    marginY: 2,
                    height: 120,
                    display: 'flex',
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': { height: '8px' },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                }}
            >
                {imageUrls !== undefined && imageUrls.length > 0 ?
                    <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                        {imageUrls.map((url, index) => (
                            <Grid item key={index}>
                                <Box
                                    component="img"
                                    src={url}
                                    alt={`Image ${index}`}
                                    sx={{
                                        width: 150,
                                        height: 100,
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid> :
                    <img src={noDiaryPic} width={150} height={100} style={{ border: '2px solid', borderColor: '#C3C3C3', borderRadius: '16px' }} />
                }

            </Box>
        </>
    );
};

export default HorizontalScrollImageGallery;
