import { Button, CircularProgress, Dialog, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { SignPeopleDTO } from '../../../../ApiOld/ApiServerVov';
import { Check, Close, Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface SignaturesUserInfoDialogProps {
    diaryRecordId: number;
    isOpen: boolean;
    onClose: () => void;
    isInvestor: boolean;
}

export default function SignaturesUserInfoDialog(props: SignaturesUserInfoDialogProps) {
    const { diaryRecordId, isOpen, onClose, isInvestor } = props;
    const [users, setUsers] = useState<SignPeopleDTO[]>([]);
    const [startingFlow, setStartingFlow] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (isOpen)
            new ApiDiaryBaseHelper(false, isInvestor).GetUsersForSignFlow(diaryRecordId).then((x) => { if (x && x.users) setUsers(x.users); })
    }, [isOpen]);
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle height='40px' sx={{ zIndex: "28" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        Osoby k podpisu
                    </div>
                    <div>
                        <IconButton onClick={onClose}><Close /></IconButton>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex', margin: '20px', flexDirection: 'column', minWidth: '300px' }} >
                {!startingFlow && <div>
                    <div >
                        {users.map((user) => {
                            return <div style={{ display: 'flex', color: user.isInvestor ? '#000000' : '#555555' }}>
                                <div>{user.isOkForSignWorkflow ? <Check /> : <Warning color='error' />}</div>
                                <div style={{ marginLeft: '12px', marginTop: '2px' }}>{user.name}</div>
                            </div>;
                        })}
                    </div >
                    <Button variant='contained' sx={{ margin: '20px' }}
                        onClick={() => {
                            setStartingFlow(true);
                            new ApiDiaryBaseHelper(false, isInvestor).StartSigniWorkflow(diaryRecordId).then(() => { onClose(); setStartingFlow(false); })
                        }}
                        disabled={users.filter(x => x.isOkForSignWorkflow === false).length > 0}>{t('files.startSigning')}</Button>
                </div>}
                {startingFlow && <CircularProgress />}
            </div>
        </Dialog >
    )
}
