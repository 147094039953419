import { Engineering } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';

import { ColumnDirective, ColumnsDirective, GridComponent, Inject, RecordDoubleClickEventArgs, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit, Column, IEditCell, ToolbarItems, EditSettingsModel, colGroup, CheckBoxChangeEventArgs } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { EmployeeGroupDTO, EmployeeInGroupDTO, GetWeatherForRecordDTO, GetWorkerForRecordDTO, SaveWorkerReqDTO } from '../../../../ApiOld/ApiServerVov';
import { useDiaryWorkersCopyStore } from '../../../../Contexts/DiaryWorkersCopyZContext';
import InitSfGridLocale from '../../../../Localization/SfGridLanguageInitializer';
import delay from '../../../Shared/Functions/Delay';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import EmployeesWithGroupsDialog from '../../Employees/EmployeesWithGroupsChooseDialog';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import tabProps from '../../../Shared/Tabs/TabProps';
import { TabPanel } from '../../../Shared/Tabs/TabPanel';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import { setValue } from '@syncfusion/ej2-base';




InitSfGridLocale();

interface WorkersCopyWidgetProps {
    buildingDiaryRecordId: number | undefined;
    workersInput?: GetWorkerForRecordDTO[];
    workersNote?: string;
    noteChanged?: (t: string) => void;
    onSelectionChanged?: (sel: number[]) => void;
    saveChanged?: boolean;
    isCopying: boolean;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    buildingName?: string;
    date?: Date;
    showWorkHours: boolean;
    showGroups: boolean;
}

export default function WorkersCopyWidget(props: WorkersCopyWidgetProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, workersInput, workersNote, noteChanged, onSelectionChanged, saveChanged,
        isCopying, isInvestor, isSubdodavatel, showGroups, showWorkHours } = props;
    const [workers, setWorkers] = useState<GetWorkerForRecordDTO[]>();
    const [copying, setCopying] = useState<boolean>(false);
    const [openEmployeesDialog, setOpenEmployeesDialog] = useState<boolean>(false);
    const theme = useTheme();
    /* const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', showConfirmDialog: false };
    const toolbarOptions: object[] = [{ text: t('Add'), id: 'grid-component-workers_add', prefixIcon: 'e-add' },
    { text: t('Delete'), id: 'grid-component-workers_delete', prefixIcon: 'e-delete' },
    { text: t('save'), id: 'grid-component-workers_update', prefixIcon: 'e-update' },
    { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-workers_cancel' },
    { text: t('diary.AddWorkers'), tooltipText: 'Načíst osoby se systému', prefixIcon: 'e-expand', id: 'loadWorkersFromSystem' }]; */
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { workersStore } = useDiaryWorkersCopyStore();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [workersNoteLocal, setWorkersNoteLocal] = useState<string | undefined>();

    const saving = useRef<boolean>(false);

    function reloadData() {
        if (isCopying) {
            setWorkers(workersStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetWorkersForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (grid.current)
                        grid.current!.dataSource = d;
                    setWorkers(d);
                    saving.current = false;
                });
            else
                saving.current = false;
        }
    }

    useEffect(() => {
        setWorkers(workersStore);

    }, [workersStore]);

    useEffect(() => {
        if (grid.current !== null) {
            showGroups ? grid.current.showColumns(t('diary.GroupName')) : grid.current.hideColumns(t('diary.GroupName'));
        }
    }, [showGroups]);

    useEffect(() => {
        if (grid.current !== null) {
            showWorkHours ? grid.current.showColumns(t('diary.hoursHeadings')) : grid.current.hideColumns(t('diary.hoursHeadings'));
        }
    }, [showWorkHours]);

    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);

    useEffect(() => {
        if (workersInput) {
            setWorkers(workersInput);
        }
    }, [workersInput]);

    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        setWorkersNoteLocal(workersNote);
    }, [workersNote]);
    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);
    const grid = useRef<GridComponent>(null);
    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    const codeRules: object = { required: false, maxLength: 6 };
    return (

        <div style={{ background: 'white' }}>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => {
                        setSelectedTabIndex(n);
                    }} aria-label="basic tabs example">
                        <Tab label={t('table')} {...tabProps(0)} />
                        {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <GridComponent
                        style={{ borderColor: 'transparent' }} id="grid-component-workers"
                        allowSorting={true}
                        ref={grid}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        resizeSettings={{ mode: 'Normal' }}
                        allowFiltering={false}
                        checkBoxChange={(e: CheckBoxChangeEventArgs) => { if (onSelectionChanged) onSelectionChanged(e.selectedRowIndexes ? e.selectedRowIndexes : []); }}
                        dataSource={workers}
                    >
                        <ColumnsDirective>
                            <ColumnDirective headerText='' type='checkbox' width='50' field='selected' />
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                            <ColumnDirective field='code' width='100' headerText={t('log.code')} />
                            <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='groupName' headerText={t('diary.GroupName')} maxWidth='400' width='200' allowEditing={false} />
                            <ColumnDirective field='workHours' editType='numericedit' maxWidth='200' width='100' textAlign='Right'
                                edit={numericParams}
                                headerText={t('diary.hoursHeadings')} format='N2' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='200' />
                            <ColumnDirective field='authorName' headerText={t('CreatedBy')} maxWidth='800' allowEditing={false} width='300' minWidth='200' />
                            <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' />
                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div style={{ margin: '-10px -24px' }}>
                        <RichTextEditorComponent actionBegin={(e) => { }} height={400} locale={i18n.language} value={workersNoteLocal} change={(eve: ChangeEventArgs) => {
                            if (isSubdodavatel !== true) {
                                setWorkersNoteLocal(eve.value);
                                if (noteChanged)
                                    noteChanged(eve.value);
                            }
                        }} >
                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                </TabPanel>
            </Box>
        </div>
    )
}