import { useEffect, useRef, useState } from "react";
import { GetWeatherForRecordDTO, SaveWeatherReqDTO } from "../../../../../ApiOld/ApiServerVov";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { ColumnChooser, ColumnDirective, ColumnsDirective, Edit, EditSettingsModel, ExcelExport, Filter, GridComponent, Group, Inject, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import tabProps from "../../../../Shared/Tabs/TabProps";
import standardFilterOptions from "../../../../Shared/Grids/StandardFilterOptions";
import { TimeEditTemplate, TimeTemplate } from "../../../../Shared/Grids/TimePickerCellTemplates";
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import { TabPanel } from "../../../../Shared/Tabs/TabPanel";
import DiaryWeatherInputDialog from "../DiaryWeatherInputDialog";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface WeatherWidgetSceneProps {
    buildingDiaryRecordId: number;
    isExpanded?: boolean;
    weathersInput?: GetWeatherForRecordDTO[];
    weatherNote?: string;
    noteChanged?: (t: string) => void;
    onSaved?: () => void;
    onSaveChanged?: () => void;
    saveChanged?: boolean;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    date?: Date;
}

export default function WeatherWidgetScene(props: WeatherWidgetSceneProps) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, weathersInput, weatherNote, noteChanged, isExpanded, saveChanged, isLocked, isInvestor, isSubdodavatel, onSaved, onSaveChanged, date } = props;
    const [weathers, setWeathers] = useState<GetWeatherForRecordDTO[] | undefined>([]);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [weatherNoteLocal, setWeatherNoteLocal] = useState<string | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [inputDialogOpen, setInputDialogOpen] = useState<boolean>(false);
    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };
    const toolbarOptions: object[] = [
        { text: t('Add'), id: 'grid-component-weather_add', prefixIcon: 'e-add' },
        { text: t('Delete'), id: 'grid-component-weather_delete', prefixIcon: 'e-delete' },
        { text: t('save'), id: 'grid-component-weather_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-weather_cancel' },
        { text: t('loadFromSystem'), prefixIcon: 'e-expand', id: 'loadWeatherFromSystem' }];
    const toolbarOptionsNoDelete: object[] = [
        { text: t('Add'), id: 'grid-component-weather_add', prefixIcon: 'e-add' },
        { text: t('save'), id: 'grid-component-weather_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-weather_cancel' },
        { text: t('loadFromSystem'), prefixIcon: 'e-expand', id: 'loadWeatherFromSystem' }];
    const { permissions } = useDiaryTabsContext();
    const saving = useRef<boolean>(false);

    function reloadData() {
        if (buildingDiaryRecordId)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetWeatherForRecord(buildingDiaryRecordId, false).then((d) => {
                if (grid.current)
                    grid.current!.dataSource = d;
                setWeathers(d);
                saving.current = false;
            });
        else
            saving.current = false;
    }

    function toolBarClick(e: any) {
        if (isLocked) {
            e.cancel = true;
            return;
        }
        let id = e.item.properties.id;
        if (id === 'loadWeatherFromSystem')
            setInputDialogOpen(true);
    }

    useEffect(() => {
        if (isLocked) {
            if (document.getElementById('grid-component-weather_add'))
                document.getElementById('grid-component-weather_add')!.className += ' e-disabled';
            if (document.getElementById('grid-component-weather_delete'))
                document.getElementById('grid-component-weather_delete')!.className += ' e-disabled';
            if (document.getElementById('grid-component-weather_update'))
                document.getElementById('grid-component-weather_update')!.className += ' e-disabled';
            if (document.getElementById('grid-component-weather_cancel'))
                document.getElementById('grid-component-weather_cancel')!.className += ' e-disabled';
            if (document.getElementById('loadWeatherFromSystem'))
                document.getElementById('loadWeatherFromSystem')!.className += ' e-disabled';
        }
    }, [isLocked]);

    useEffect(() => {
        console.log(weathersInput);
        if (weathersInput !== undefined)
            setWeathers(weathersInput);

    }, [weathersInput]);
    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        if (isExpanded !== undefined)
            setExpanded(isExpanded);
    }, [isExpanded]);
    useEffect(() => {
        setWeatherNoteLocal(weatherNote);
    }, [weatherNote]);
    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);
    function setDefaultData(e: any) {
        e.defaultData.id = 0;
        e.defaultData.description = 'jasno';
        e.defaultData.humidity = '';
        e.defaultData.time = new Date();
        e.defaultData.temperature = '';
        e.defaultData.precipitation = '';
        e.defaultData.wind = '';
        e.defaultData.authorName = '';
        e.defaultData.isShared = true;

    }
    function beforeDeleteAsk(e: any) {
        // e.cancel = true;
    }
    function save(e: any) {
        e.cancel = true;
        if (saving.current) return;
        saving.current = true;
        let added = e.batchChanges.addedRecords.map((add: any) => {
            // console.log(add.time.getHours() + ':' + add.time.getMinutes());
            return new SaveWeatherReqDTO({ id: 0, time: new Date(add.time).getHours() + ':' + new Date(add.time).getMinutes(), isShared: add.isShared, description: add.description, temperature: add.temperature, humidity: add.humidity, precipitation: add.precipitation, wind: add.wind })
        });
        let changed = e.batchChanges.changedRecords.map((chang: any) => {
            return new SaveWeatherReqDTO({ id: chang.id, isShared: chang.isShared, time: new Date(chang.time).getHours() + ':' + new Date(chang.time).getMinutes(), description: chang.description, temperature: chang.temperature, humidity: chang.humidity, precipitation: chang.precipitation, wind: chang.wind })
        });
        let deleted = permissions?.canDeleteInsideRecords == true ? e.batchChanges.deletedRecords.map((del: any) => {
            return new SaveWeatherReqDTO({ id: -(del.id), time: '' })
        }) : [];
        let b: SaveWeatherReqDTO[] = [...added, ...changed, ...deleted];
        console.log(b);
        if (buildingDiaryRecordId)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveWeathers(buildingDiaryRecordId, b).then((r) => {
                if (r) reloadData(); else saving.current = false; if (onSaved) onSaved();
            })
        /* console.log(e);
        if (onSaved)
            onSaved(); */

        // e.batch
    }
    function clickHandler(e: any) {
        console.log('tady');
        // console.log(e);
        let instance = (document.getElementById("grid-component-weather") as HTMLElement);
        if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
            console.log((e.target as HTMLElement));
            //@ts-ignore
            //@ts-ignore
            let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));

            //@ts-ignore
            let colindex: number = parseInt((e.target as HTMLElement).getAttribute("data-colindex"));
            //@ts-ignore
            let field: string = instance.ej2_instances[0].getColumns()[colindex].field;
            // if (index === grid.current!.recordsCount - 1)
            //     delay(800).then(() => { grid.current!.editCell(index - 1, field); });
            // else 
            grid.current!.editCell(index - 1, field);


            // }

            // instance.ej2_instances[0].editModule.editCell((index - 1), field);
        }
        else {
            console.log(e.target as HTMLElement);
            console.log((e.target as HTMLElement).parentElement as HTMLElement);
            if (((e.target as HTMLElement).parentElement as HTMLElement).classList.contains("e-rowcell")) {

                let index: number = parseInt(((e.target as HTMLElement).parentElement as HTMLElement).parentElement!.getAttribute("aria-rowindex")!);
                grid.current!.editCell(index - 1, "time");
            }

        }
    }
    const loaded = useRef(false);
    const singleClickLoad = (id: string): void => {
        //@ts-ignore
        let instance = (document.getElementById(id) as HTMLElement);
        // console.log(instance);
        if (instance) {
            if (!loaded.current) {
                instance.removeEventListener('mouseup', clickHandler);
                instance.addEventListener('mouseup', clickHandler);
                loaded.current = true;
            }
        }
    }



    const grid = useRef<GridComponent>(null);

    return (
        <Box sx={{ marginX: '40px', background: 'white', borderRadius: '15px', overflow: 'hidden', minHeight: 500 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={(e, n) => {
                    setSelectedTabIndex(n);
                }} aria-label="basic tabs example">
                    <Tab label={t('table')} {...tabProps(0)} />
                    {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                </Tabs>
            </Box>
            <TabPanel value={selectedTabIndex} index={0}>
                <div>
                    {date && <DiaryWeatherInputDialog diaryRecordId={buildingDiaryRecordId}
                        open={inputDialogOpen} onClose={() => { setInputDialogOpen(false); reloadData(); }} date={date} />}
                    <GridComponent
                        style={{ borderColor: 'transparent' }} id="grid-component-weather"
                        allowSorting={true}
                        beforeBatchDelete={(e) => { beforeDeleteAsk(e) }}
                        beforeBatchAdd={(e) => { setDefaultData(e); }}
                        beforeBatchSave={(e) => { save(e) }}
                        ref={grid}
                        cellEdit={() => { if (onSaveChanged) onSaveChanged() }}
                        load={(e) => { singleClickLoad("grid-component-weather") }}
                        editSettings={editSettings}
                        toolbar={(permissions && permissions.canEditDiaryRecord && !isLocked) ? (permissions.canDeleteInsideRecords ? toolbarOptions : toolbarOptionsNoDelete) : undefined}
                        toolbarClick={toolBarClick}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        resizeSettings={{ mode: 'Normal' }}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={false}

                    >
                        <ColumnsDirective>
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                            <ColumnDirective field='description' headerText={t('Description')} maxWidth='800' width='300' minWidth='200' />

                            <ColumnDirective field='time'
                                editTemplate={TimeEditTemplate}
                                template={TimeTemplate}
                                headerText={t('diary.time')}
                                width='160' minWidth='160' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='30' />
                            <ColumnDirective field='temperature' headerText={t('diary.temperature')} width='100' />
                            <ColumnDirective field='humidity' headerText={t('diary.humidity')} width='100' />
                            <ColumnDirective field='precipitation' headerText={t('diary.precipitation')} width='100' />
                            <ColumnDirective field='wind' headerText={t('diary.wind')} width='100' />
                            <ColumnDirective field='authorName' allowEditing={false} headerText={t('CreatedBy')} maxWidth='800' width='80' minWidth='60' />
                            <ColumnDirective field='created' visible={false} type='datetime' format='dd.MM.yyyy HH:mm' headerText={t('Created')} maxWidth='800' width='80' minWidth='60' />
                            <ColumnDirective field='lastUpdate' visible={false} type='datetime' format='dd.MM.yyyy HH:mm' headerText={t('Updated')} maxWidth='800' width='80' minWidth='60' />

                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                </div>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
                <div>
                    <RichTextEditorComponent actionBegin={(e) => { }} height={400} locale={i18n.language} value={weatherNoteLocal} change={(eve: ChangeEventArgs) => {
                        if (isSubdodavatel !== true) {
                            setWeatherNoteLocal(eve.value);
                            if (noteChanged)
                                noteChanged(eve.value);
                        }
                    }} >
                        <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                    </RichTextEditorComponent>
                </div>
            </TabPanel>
        </Box>
    );
}

