import { Book, Clear, Delete, Done, RemoveCircle } from '@mui/icons-material';
import { Button, IconButton, Link, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, FilterSettingsModel, GridComponent, Inject, RecordDoubleClickEventArgs, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { AdminUsersDTO, GetDiariesForUserDTO } from '../../../ApiOld/ApiServerVov';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIVovKrosAdminHelper } from '../../../ApiOld/ApiKrosAdminOldClient';
import DeleteDialog from '../../Shared/Layouts/DeleteDialog';
import CreateUserDialogKrosAdmin from './Details/CreateUserKrosAdminDialog';


interface KrosAdminUsersListSceneProps {
}

export default function KrosAdminUsersListScene(props: KrosAdminUsersListSceneProps) {
    InitSfGridLocale();
    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [users, setUsers] = useState<AdminUsersDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState<boolean>(false);
    const [deleteData, setDeleteData] = useState<AdminUsersDTO | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [createUserOpen, setCreateUserOpen] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const params = useParams();
    const { t, i18n } = useTranslation();
    function toDiaryDetail(props: GetDiariesForUserDTO) {
        // grid.current?.filterModule.
        /*  if (isSubdodavatel) {
             navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/diaryDetail/' + props.id);
             window.parent.postMessage("diarydetailchanged:" + props.id, '*');
         }
         else {
             if (fromExternal === undefined || fromExternal === false)
                 navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryDetail/' + props.id);
             else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/' + props.id);
             window.parent.postMessage("diarydetailchanged:" + props.id, '*');
         } */
    }
    function gridTemplate(props: AdminUsersDTO) {
        return (<div>
            <IconButton sx={{ width: '32px', height: '32px' }} onClick={() => { setDeleteData(props); setDeleteDialogOpen(true); }}>
                <Delete />
            </IconButton>
        </div >);
    }
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const grid = useRef<GridComponent>(null);
    function reloadData() {
        setUsers(null);
        new APIVovKrosAdminHelper().GetAdminUsers().then((r) => {
            setUsers(r);
            if (grid.current)
                grid.current.refresh();
        });
    }
    useEffect(() => {
        if (users === null)
            reloadData();
    }, []);

    function deleteDialogClose(e: any) {
        // e.cancel = true;
        setDeleteDialogOpen(false);
        setDeleteData(null);
    }

    function userDelete(args: any) {
        if (deleteData && deleteData.id) {
            new APIVovKrosAdminHelper().DeleteUserKrosAdmin(deleteData.id).then(e => {
                if (e === true) {
                    setDeleteDialogOpen(false);
                    setDeleteData(null);
                    reloadData();
                }
            });
        }
    }

    function createUser(args: any) {
        setCreateUserOpen(true);
    }

    if (users !== null)
        return <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
            <UpperMenu moduleName='Prehľad užívateľov'
                gridType={GridType.Users}
                canAdd={true}
                onCreate={createUser}
                canAddSecond={false}
                addLabel={t('createUser')}
                canDelete={false}
                onDelete={(e) => {
                    /* new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).DeleteDiaries(e as number[]).then((e) => {
                        if (e)
                            reloadData();
                    }) */
                }}
                canChangeEyeView={false}
                grid={grid} gridId='grid-component-users' canChangeGroup canToolbarGrid
                menuName={t('View')}
                canTrash={false}
                onThrashClicked={() => { setTrashOpen(true) }}
                icon={<Book />}
                canExcelExport
            />
            <div>
                <div style={{ display: 'flex' }}>
                    {/* <Link sx={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '0px' }} onClick={() => {
                        grid.current?.clearFiltering();
                    }}>{t('clearFiltering')}</Link> */}
                    {/* <Button sx={{ marginLeft: 'auto', marginRight: '0px', width: '200px', fontSize: '10px', marginBttom: '-24px'/* , overflow: 'hidden'  }} startIcon={<Clear />} aria-label="create" variant='contained' size='small' onClick={(event) => {

                        // grid.current?.filterModule.
                    }} disabled={false} color="primary">
                        {t('clearFiltering')}
                    </Button> */}
                </div>
                <GridComponent ref={grid}
                    id="grid-component-users" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={false}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        /* toDiaryDetail(e.rowData as GetDiariesForUserDTO) */
                        navigate('/' + params['lang'] + '/kros/admin/users/' + (e.rowData as GetDiariesForUserDTO).id);
                    }}
                    filterSettings={filterOptions}
                    allowPaging={true}

                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={users}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                        <ColumnDirective field='name' headerText={t('Name')} width='120' />
                        <ColumnDirective field='email' headerText={'Email'} width='120' />
                        <ColumnDirective field='application' headerText={'Aplikácia'} width='100' />
                        <ColumnDirective template={gridTemplate} width='50' />
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent></div>
            {/* <TrashGridDialog open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) reloadData(); }} trashType={TrashType.Diaries} /> */}

            <DeleteDialog onClose={deleteDialogClose} open={deleteDialogOpen} data={deleteData} onDeleteAgree={event => userDelete(event)} />
            <CreateUserDialogKrosAdmin open={createUserOpen} onClose={() => { setCreateUserOpen(false); /* reloadData(); */ }} onCreated={() => reloadData()} />
        </div >
    return <div>{t('noRecords')}</div>;
}