import React, { useEffect, useRef } from 'react';
import { TextField, List, CircularProgress, InputAdornment, Button, Typography, IconButton, Box } from '@mui/material';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { PreviousMessagesDTO } from '../../../ApiOld/ApiServerVov';
import { Close, ContentCopy, Delete, Send } from '@mui/icons-material';
import './GptChatScene.css';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import stavarioPic from '../../../Assets/login-logo-stavario.svg';
import stavarioPicSmall from '../../../Assets/logo512.png';
import { useMediaPredicate } from 'react-media-hook';
import ReallyCloseGptDialog from './ReallyCloseGptDialog';

interface GptChatSceneInTabProps {
    onCopy?: (text: string) => void;
}

function GptChatSceneInTab(props: GptChatSceneInTabProps) {
    const [inputValue, setInputValue] = React.useState('');
    const [messages, setMessages] = React.useState<PreviousMessagesDTO[]>([]);
    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const [closingChatOpen, setClosingChatOpen] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    useEffect(() => {
        if (params['lang'] !== i18n.language)
            i18n.changeLanguage(params['lang']!);
    }, [i18n, params]);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        if (messages.length > 0 && messages[messages.length - 1].role === "user") {
            new APIVovInvestorHelper().SendGptMessage(inputValue, messages).then((text) => {
                setMessages([...messages, new PreviousMessagesDTO({ content: text, role: "system" })]);
                setInputValue('');
                setIsLoadingResponse(false);
            });
        }
    }, [inputValue, messages]);

    const handleSendMessage = async () => {
        setIsLoadingResponse(true);
        setMessages([...messages, new PreviousMessagesDTO({ content: inputValue, role: "user" })]);
    };


    const copyAllMessages = () => {
        let text = '';
        messages.forEach((m) => {
            text += m.content;
        });
        if (props.onCopy) props.onCopy(text);
        return text;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '400px', border: '1px solid lightgrey', borderRadius: '10px' }}>
            <ReallyCloseGptDialog
                open={closingChatOpen}
                handleClose={(r) => {
                    if (r) navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks');
                    setClosingChatOpen(false);
                }}
            />
            {props.onCopy === undefined && (
                <div style={{ width: '100%', display: 'flex', height: '48px', justifyContent: 'space-between', background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    <div style={{ display: 'flex', margin: '10px' }}>
                        <img
                            src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks')}
                            alt="stavario"
                            width={biggerThan700 ? "128px" : "35px"}
                            height={biggerThan700 ? "26px" : "35px"} />
                        <Typography variant='h5' sx={{ marginTop: '3px', marginLeft: '10px' }} fontWeight={'bold'} fontSize={18}>
                            {t('aiHelper')}
                        </Typography>
                    </div>
                    <Button
                        color="secondary"
                        sx={{ margin: '10px' }}
                        endIcon={<Close />}
                        onClick={() => {
                            if (messages.length > 0) setClosingChatOpen(true);
                            else navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks');
                        }}
                    >
                        {biggerThan700 ? t('backToInvestorInterface') : ''}
                    </Button>
                </div>
            )}
            <Box sx={{ width: '100%', height: '42px', background: '#f8f8f8', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}><Typography sx={{ fontWeight: 'bold', margin: '10px' }}>AI Poradce</Typography></Box>
            <List style={{ flexGrow: 1, overflowY: 'auto', padding: '10px', display: 'flex', flexDirection: 'column', height: 500 }}>
                {messages.map((message, i) => (
                    <div key={i}>
                        <div key={'message' + i} className={message.role === 'user' ? 'chat-left' : 'chat-right'}>
                            {props.onCopy && (
                                <IconButton onClick={() => { if (props.onCopy && message.content) props.onCopy(message.content); }}>
                                    <ContentCopy sx={{ position: 'relative', gridArea: '1/1' }} />
                                </IconButton>
                            )}
                            <div style={{ gridArea: '1/1' }}>{message.content}</div>
                        </div>
                    </div>
                ))}
            </List>

            <div style={{ display: 'flex', width: '100%', alignItems: 'center', padding: '5px' }}>
                <TextField
                    placeholder={t('enterQuestionForAI')}
                    label={t('hereWriteQuestion')}
                    sx={{ width: '80%' }}
                    disabled={isLoadingResponse}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {!isLoadingResponse && (
                                    <Button variant="contained" startIcon={<Send />} onClick={handleSendMessage}>
                                        {t('send')}
                                    </Button>
                                )}
                                {isLoadingResponse && (
                                    <Button size="small" variant="contained">
                                        <CircularProgress color="secondary" size={16} />
                                        <Typography sx={{ marginLeft: '8px' }}>
                                            {t('loadingAIResponse')}
                                        </Typography>
                                    </Button>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ marginLeft: '10px', width: '28px', height: '28px', marginTop: '12px' }}
                    size="small"
                    onClick={() => setMessages([])}
                >
                    <Delete style={{ width: '20px', height: '20px' }} />
                </Button>
                {props.onCopy && (
                    <Button sx={{ marginLeft: '10px' }} size="small" onClick={copyAllMessages} startIcon={<ContentCopy />}>
                        {t('diary.copyToDiary')}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default GptChatSceneInTab;