
import React, { useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, CircularProgress, InputAdornment, Button, Typography, IconButton, Box, Grid, Tooltip } from '@mui/material';

import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { PreviousMessagesDTO } from '../../../ApiOld/ApiServerVov';
import delay from '../../Shared/Functions/Delay';
import { Close, ContentCopy, Delete, Send } from '@mui/icons-material';
import './GptChatScene.css';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import stavarioPic from '../../../Assets/login-logo-stavario.svg'; // with import
import stavarioPicSmall from '../../../Assets/logo512.png'; // with import
import { useMediaPredicate } from 'react-media-hook';
import ReallyCloseGptDialog from './ReallyCloseGptDialog';

interface GptChatSceneProps {
    onCopy?: (text: string) => void;
}
function GptChatScene(props: GptChatSceneProps) {
    // console.log(useStyles);
    const [inputValue, setInputValue] = React.useState('');
    const [messages, setMessages] = React.useState<PreviousMessagesDTO[]>([]);
    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const [closingChatOpen, setClosingChatOpen] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    useEffect(() => {
        if (params['lang'] !== i18n.language)
            i18n.changeLanguage(params['lang']!);
    }, [params]);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };
    useEffect(() => {
        if (messages.length > 0 && messages[0].role === "user") {
            new APIVovInvestorHelper().SendGptMessage(inputValue, messages).then((text) => {
                setMessages([new PreviousMessagesDTO({ content: text, role: "assistant" }), ...messages]);
                setInputValue('');
                setIsLoadingResponse(false);
            });
        }
    }, [messages])

    const handleSendMessage = async () => {
        setIsLoadingResponse(true);
        setMessages([new PreviousMessagesDTO({ content: inputValue, role: "user" }), ...messages]);

    };
    const copyAllMessages = () => {
        let text = '';
        let a = messages.reverse();
        a.forEach((m) => {
            text += m.content;
        });
        if (props.onCopy)
            props.onCopy(text);
        return text;
    }
    return (
        <>
            <ReallyCloseGptDialog open={closingChatOpen} handleClose={(r) => { if (r) navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks'); setClosingChatOpen(false) }} />
            {props.onCopy === undefined && <div style={{ width: '100%', display: 'flex', height: '48px', justifyContent: 'space-between', background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <img src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                        style={{ cursor: 'pointer' }}
                        onClick={e => { navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks') }}
                        alt="stavario" width={biggerThan700 ? "128px" : "35px"}
                        height={biggerThan700 ? "26px" : "35px"} ></img>
                    <Typography variant='h5' sx={{ marginTop: '3px', marginLeft: '10px' }} fontWeight={'bold'} fontSize={18}>{t('aiHelper')}</Typography>
                </div>
                <Button color='secondary' sx={{ margin: '10px' }} endIcon={<Close />} onClick={() => {
                    if (messages.length > 0)
                        setClosingChatOpen(true);
                    else navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/tasks');
                }}>{biggerThan700 ? t('backToInvestorInterface') : ''}</Button>
            </div>}

            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <TextField
                                placeholder={t('enterQuestionForAI')}
                                label={t('hereWriteQuestion')}
                                disabled={isLoadingResponse}
                                value={inputValue}
                                onChange={handleInputChange}
                                sx={{ width: '100%', }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSendMessage();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!isLoadingResponse && <Button sx={{ height: '32px' }} variant='contained' onClick={handleSendMessage}>
                                                <Send />
                                            </Button>}
                                            {isLoadingResponse && <Button variant='contained'>
                                                <CircularProgress color="secondary" size={20} />
                                            </Button>}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction={'column'} xs={'auto'} spacing={0.5}>
                                <Grid item>
                                    <Tooltip enterTouchDelay={0} title={t('DeleteMessages')}>
                                        <Button variant='contained' size='small' onClick={() => setMessages([])}><Delete style={{ width: '16px', height: '16px' }} /></Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    {props.onCopy &&
                                        <Tooltip enterDelay={0} title={t('diary.copyToDiary')}>
                                            <Button variant='contained' size='small' onClick={copyAllMessages}><ContentCopy style={{ width: '16px', height: '16px' }} /></Button>
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <List >
                        {messages.map((message, i) => (
                            <div key={i}>
                                <div key={'message' + i} className={message.role === 'user' ? 'chat-left' : 'chat-right'}>
                                    {props.onCopy && <IconButton onClick={e => { if (props.onCopy && message.content) props.onCopy(message.content); }}><ContentCopy sx={{ position: 'relative', gridArea: '1/1' }} /></IconButton>}
                                    <div style={{ gridArea: '1/1' }}> {message.content}</div>
                                </div>
                            </div>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </>
    );
}
export default (GptChatScene);
