import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetBuildingWeatherByDiaryIdResponse } from '../../../../ApiOld/ApiServerVov';
import TimeSelector from '../../../Shared/TimeSelector'
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';

interface DiaryWeatherInputDialogProps {
    open: boolean;
    onClose: () => void;
    date: Date;
    diaryRecordId: number;
    isSubdodavatel?: boolean;
}
export default function DiaryWeatherInputDialog(props: DiaryWeatherInputDialogProps) {
    const { open, onClose, date, diaryRecordId, isSubdodavatel } = props;
    const [hour, setHour] = React.useState<number>(8);
    const [weather, setWeather] = React.useState<GetBuildingWeatherByDiaryIdResponse | null>(null);
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ marginTop: '32px' }}
        >
            <DialogTitle id="weather-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '400px' }}>{t('diary.selectTimeToInsert')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose() }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ marginTop: '20px' }}>
                        <TimeSelector onChange={(date, hour) => {
                            setHour(hour);
                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetWeatherFromBuildingForRecord(diaryRecordId, hour, date).then((weather) => {
                                setWeather(weather === undefined ? null : weather);
                            });
                        }} date={date} />
                    </div>
                    {weather !== null && <div style={{ height: '200px', display: 'flex', flexDirection: 'column' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-90px' }}>
                            <div style={{ margin: '5px 0' }}><span style={{ fontWeight: 'bold' }}>{t('Description')}:</span> {weather.description}</div>
                            <div style={{ margin: '5px 0' }}><span style={{ fontWeight: 'bold' }}>{t('diary.humidity')}:</span> {weather.humidity}%</div>
                            <div style={{ margin: '5px 0' }}><span style={{ fontWeight: 'bold' }}>{t('diary.precipitation')}:</span> {weather.precipitation} mm</div>
                            <div style={{ margin: '5px 0' }}><span style={{ fontWeight: 'bold' }}>{t('diary.temperature')}:</span> {weather.temperature} °C</div>
                            <div style={{ margin: '5px 0' }}><span style={{ fontWeight: 'bold' }}>{t('diary.wind')}:</span> {weather.wind} m/s</div>
                        </div>

                    </div>}
                    <Button variant='contained' onClick={() => {
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).SaveWeatherFromBuildingForRecord(diaryRecordId, hour, date).then((weather) => {
                            onClose();
                        });
                    }}>{t('save')}</Button>
                </div></DialogContent>
        </Dialog>

    )
}
