

import { Save, Close, CloudUploadOutlined, Upload } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { FileParameter, FileToTransferDTO, ImageDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import BasicUpload from '../../../Shared/Files/BasicUploadComponent';
import delay from '../../../Shared/Functions/Delay';
import NoRights from '../../../Shared/Layouts/NoRights';
import '../Styles/ChoosePhotoDialog.css';
import { useTranslation } from "react-i18next";
import ChooseProjectPhotos from './ChooseProjectPhotos';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { DiaryCommonVovClient } from '../../../../ApiOld/Api/ApiServerVov';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

interface ChoosePhotoDialogProps {
    recordId?: number;
    isUploading?: (open: boolean) => void;
    onUploaded?: (url: string) => void;
    onClose: (refresh: boolean) => void;
    open: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}
export interface FileUpload {
    data: any;
    fileName: string;
    isUploading: boolean;
}
export default function ChoosePhotoDialog(props: ChoosePhotoDialogProps) {
    const { recordId, isUploading, onUploaded, onClose, open, isInvestor, isSubdodavatel } = props;
    const [projectImages, setProjectImages] = useState<ImageDiaryDTO[]>([]);
    const changedSelectedProjectImages = useRef<ImageDiaryDTO[]>([]);
    // const [changedSelectedProjectImages, setChangeSelectedProjectImages] = useState<ImageDiaryDTO[]>([]);

    const [refresh, setRefresh] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { permissions } = useDiaryTabsContext();
    const { t, i18n } = useTranslation();
    const { authorizedApi } = useApi(DiaryCommonVovClient);

    useEffect(() => {

        if (recordId && open && (isSubdodavatel !== true)) {
            authorizedApi.getPhotosBuildingToChoose(recordId, false).then((imgs) => {
                if (imgs.images)
                    setProjectImages(imgs.images);
            })
        }

    }, [recordId, open, isSubdodavatel, authorizedApi]);

    const handleClose = () => {
        if (!uploading)
            onClose(refresh);
    };
    return (
        <Dialog
            maxWidth='lg'
            open={open}
            fullWidth={isSubdodavatel !== true}
            sx={{ zIndex: 50002 }}
            PaperProps={{
                sx: {
                    minWidth: isSubdodavatel !== true ? '80%' : '40%'
                }
            }}
            onClose={() => { handleClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('files.insertPhotos')}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => handleClose()} >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    {isSubdodavatel !== true && <Grid container spacing={6}>
                        <Grid item xs={biggerThan700 && ((permissions && permissions.directAttachments) || isInvestor) ? 4 : 1}>
                            {((permissions && permissions.directAttachments) || isInvestor) &&
                                <BasicUpload isSubdodavatel={isSubdodavatel} isInvestor={isInvestor === true} uploadStarted={() => { setUploading(true) }} uploadCompleted={() => { setUploading(false) }} recordId={recordId} onlyImages={true} onNeedRefresh={() => { setRefresh(true) }} />}
                        </Grid>
                        {((permissions && permissions.attachments) || isInvestor) && <Grid item xs={8}>
                            <div style={{ width: '95%', margin: '12px' }}>
                                <Button disabled={uploading} variant='contained' sx={{ marginLeft: '8px' }} onClick={(e) => {
                                    if (recordId) {
                                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).TransferPhotosFromProject(recordId, changedSelectedProjectImages.current.map((change) => {
                                            return new FileToTransferDTO({ isNewlySelected: change.isSelected, name: change.fileName });
                                        })).then((e) => {
                                            onClose(true);
                                            changedSelectedProjectImages.current = [];
                                        })
                                    }

                                }}>{biggerThan700 ? t('diary.insertIntoDiary') : <Save />}</Button>
                                <ChooseProjectPhotos isProjectChoosing={true} isSubdodavatel={isSubdodavatel} isInvestor={isInvestor} fullWidth={false} canSelect
                                    biggerHeight={420} images={projectImages}
                                    imageSingleSelectedChanged={(e) => {
                                        let exists = changedSelectedProjectImages.current.find(x => x.fileName === e.fileName);
                                        if (exists) {
                                            changedSelectedProjectImages.current = changedSelectedProjectImages.current.filter(x => x.fileName !== exists!.fileName);
                                            // console.log(changedSelectedProjectImages.current);
                                        }
                                        else {
                                            changedSelectedProjectImages.current = [...changedSelectedProjectImages.current, e];
                                            // console.log(changedSelectedProjectImages.current);
                                        }
                                    }}
                                    imagesSelectedChanged={(e) => { setProjectImages(e) }}
                                    recordId={recordId}></ChooseProjectPhotos>
                            </div>
                        </Grid>}
                        {!isInvestor && (permissions === undefined || permissions?.attachments === undefined || permissions.attachments === false) && <NoRights title={t('noSelectFromProjectPermissions')} />}
                    </Grid>}
                    {isSubdodavatel === true && ((permissions && permissions.directAttachments) || isInvestor) && <div style={{ marginRight: '32px ' }}>
                        <BasicUpload isSubdodavatel={isSubdodavatel} isInvestor={isInvestor === true} uploadStarted={() => { setUploading(true) }} uploadCompleted={() => { setUploading(false) }} recordId={recordId} onlyImages={true} onNeedRefresh={() => { setRefresh(true) }} />
                    </div>
                    }
                </div >
            </DialogContent>
        </Dialog >
    );

}