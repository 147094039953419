import React, { useCallback, useEffect } from 'react'

import { CustomFieldDto, CustomFieldsVovClient } from '../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import AddUpdateApplicationCustomFieldDialog from './AddUpdateApplicationCustomFieldDialog';
import { customDataFieldsCaptions } from './CustomDataFieldsCaption';
import ClickableCheckbox from '../Shared/ClickableCheckbox';
import DeleteDialog from '../Shared/Layouts/DeleteDialog';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function ApplicationCustomFields() {
  const { authorizedApi } = useApi(CustomFieldsVovClient);
  const [customFields, setCustomFields] = React.useState<CustomFieldDto[]>([]);
  const [editingCustomField, setEditingCustomField] = React.useState<CustomFieldDto | null>(null);
  const [deletingCustomField, setDeletingCustomField] = React.useState<CustomFieldDto | null>(null);
  const [addCustomField, setAddCustomField] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const refresh = useCallback(async () => {
    const customFields = await authorizedApi.getCustomFieldsForApplication();
    setCustomFields(customFields.customFields ?? []);
  }, [authorizedApi]);
  const deleteCustomField = useCallback(async (item: CustomFieldDto) => {
    console.log(item)
    if (!item.id) return;
    const ok = await authorizedApi.deleteCustomField(item.id);
    if (ok) {
      refresh();
    }
  }, [authorizedApi, refresh]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  return (
    <Box sx={{ minWidth: 500 }}>
      <AddUpdateApplicationCustomFieldDialog open={addCustomField} onClose={(needRefresh) => {
        setAddCustomField(false)
        if (needRefresh) refresh();
      }} data={new CustomFieldDto()} />
      <AddUpdateApplicationCustomFieldDialog open={editingCustomField !== null} onClose={(needRefresh) => { if (needRefresh) refresh(); setEditingCustomField(null); }} data={editingCustomField} />
      <DeleteDialog data={deletingCustomField} onClose={(data) => { setDeletingCustomField(null) }} onDeleteAgree={(data) => { console.log(data); deleteCustomField(data); setDeletingCustomField(null); }} open={deletingCustomField !== null} />
      <Button sx={{ mb: 2 }} startIcon={<AddOutlined />} variant='contained' onClick={() => { setAddCustomField(true) }}>{t('AddCustomField')}</Button>
      {customFields.map((customField, index) => {
        return (
          <Box display='flex' justifyContent='space-between' sx={{ minWidth: 300 }} key={index}>
            <Box display='flex'>
              <IconButton onClick={() => setEditingCustomField(customField)}><EditOutlined /></IconButton>
              <IconButton onClick={() => setDeletingCustomField(customField)}><DeleteOutline /></IconButton>
              <Box>
                <Typography fontWeight={'bold'}>{customField.name}</Typography>
                <Typography >{t(customDataFieldsCaptions.find((option) => option.value === customField.dataType)?.label ?? "")}</Typography>
              </Box>
            </Box>
            <ClickableCheckbox label='Stavba' boxSX={{ mt: '10px' }} checked={customField.showOnBuilding} onChange={(event, checked) => {
              if (customField.id)
                authorizedApi.changeBuildingCustomFieldVisibility(customField.id, checked).then(() => refresh());

            }}></ClickableCheckbox>
          </Box>
        )
      })}
    </Box >
  )
}
