import { useTranslation } from "react-i18next";
import { BuildingNoteDTO, GetBuildingDataDetailResponse } from "../../../ApiOld/ApiServerVov";
import { useBuildingsPermissionContext } from '../../../Contexts/Permissions/BuildingsPermissionContext';
import { useEffect } from 'react';
import ProjectNotesItem from './Components/ProjectNotesItem';
import { Box, Grid } from "@mui/material";

interface ProjectNotesProps {
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setProjectData: React.Dispatch<React.SetStateAction<Partial<GetBuildingDataDetailResponse> | null>>;
}

export default function ProjectNotes(props: ProjectNotesProps) {
    const { projectData, setProjectData } = props;
    const { t, i18n } = useTranslation();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', // 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }

    if (projectData === null)
        return <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>{t('Loading')}</Box>;
    else if (!projectData.noteList || projectData.noteList.length === 0)
        return <Box sx={{ background: 'white', padding: '10px', boxShadow: 3, borderRadius: '10px', width: '100%' }}>{t('noRecords')}</Box>;
    else
        return (<Grid container spacing={1} bgcolor={'white'} sx={{ paddingTop: '8px', boxShadow: 3 }}>
            {projectData.noteList.map((note, index) => {
                return <ProjectNotesItem key={note.id} note={note} toolbarSettings={toolbarSettings} onNoteChange={(newNote) => {
                    var newNotes = [...projectData.noteList!];
                    newNotes[index] = newNote;
                    console.log(newNote);
                    setProjectData({ ...projectData, noteList: newNotes });
                }}
                    onDelete={(id) => {
                        var newNotes = projectData.noteList!.filter((note) => note.id !== id);
                        setProjectData({ ...projectData, noteList: newNotes })
                    }}
                />
            })}
        </Grid>
        );
}