import { useEffect, useRef, useState } from "react";
import { FileDiaryDTO } from "../../../../../ApiOld/ApiServerVov";
import { useTranslation } from "react-i18next";
import { ColumnChooser, ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Group, Inject, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import { Box, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { Delete, Download, InsertDriveFile } from "@mui/icons-material";
import ChooseFileDialog from "../ChooseFileDialog";
import DeleteDialog from "../../../../Shared/Layouts/DeleteDialog";
import standardFilterOptions from "../../../../Shared/Grids/StandardFilterOptions";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface AttachmentsWidgetSceneProps {
    recordId?: number;
    onDocumentChooseOpen?: () => void;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}

export default function AttachmentsWidgetScene(props: AttachmentsWidgetSceneProps) {
    const { recordId, onDocumentChooseOpen, isLocked, isInvestor, isSubdodavatel } = props;
    const [chooseFileOpen, setChooseFileOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isItemsSelected, setIsItemsSelected] = useState<boolean>(false);
    const [fileToDelete, setFileToDelete] = useState<FileDiaryDTO | null>(null);
    const [files, setFiles] = useState<FileDiaryDTO[]>([]);
    const { t, i18n } = useTranslation();
    const grid = useRef<GridComponent>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const { permissions } = useDiaryTabsContext();
    function reloadData() {
        if (recordId !== undefined)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetAttachmentsForRecord(recordId).then((data) => {
                if (data !== undefined && data !== null) {
                    if (grid.current)
                        grid.current.dataSource = data;
                    setFiles(data);
                }

            });
    }

    useEffect(() => {
        reloadData();
    }, [recordId]);
    useEffect(() => {
        if (fileToDelete !== null)
            setDeleteDialogOpen(true);
    }, [fileToDelete]);

    async function download(item: FileDiaryDTO) {

        if (item.url && item.fileName) {
            const link = document.createElement('a');
            link.href = item.url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            try {
                document.body.removeChild(link);
            } catch (e) {
                console.log(e);
            }
        }
    }
    async function deleteFile(item: FileDiaryDTO) {

        if (item.fileId && recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).deleteRecordFileRel(recordId, item.fileId).then((data) => {
                reloadData();
                setFileToDelete(null);
                setDeleteDialogOpen(false);
            });
        }
    }

    return (
        <Box sx={{ marginX: '40px', background: 'white', borderRadius: '15px', overflow: 'hidden', minHeight: 500 }}>
            <div>
                <ChooseFileDialog recordId={recordId} open={chooseFileOpen} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onClose={(e) => {
                    if (e)
                        reloadData();
                    setChooseFileOpen(false);
                }} />
                <DeleteDialog
                    data={fileToDelete} open={deleteDialogOpen} onClose={(e) => { setDeleteDialogOpen(false) }} onDeleteAgree={(e) => {
                        deleteFile(e);
                    }} />
            </div>
            <Box sx={{ padding: '20px', paddingBottom: '5px' }}>
                {((permissions && permissions.canEditDiaryRecord) || isInvestor) && !isLocked && <Button sx={{ marginRight: '20px', marginTop: '-2px', height: '24px', padding: '16px', fontSize: '12px' }}
                    onClick={(e) => {

                        e.preventDefault();
                        e.stopPropagation();
                        setChooseFileOpen(true);
                        // setChoosePhotoOpen(true);
                    }}
                    variant='contained' size='small' startIcon={biggerThan700 ? <InsertDriveFile /> : undefined}>{biggerThan700 ? t('files.addFiles') : <InsertDriveFile sx={{ height: '20px' }} />}</Button>}
                <Button size='small' variant='contained' sx={{ height: '24px', padding: '16px', fontSize: '12px' }} startIcon={<Download />} disabled={!isItemsSelected}
                    onClick={() => {
                        if (grid.current && grid.current.getSelectedRecords() !== null && grid.current.getSelectedRecords().length > 0)
                            download(grid.current.getSelectedRecords()[0] as FileDiaryDTO);
                    }}
                >Stáhnout</Button>
                {permissions && permissions.deleteAttachments && (isInvestor === undefined || isInvestor === false) && <Button
                    onClick={() => {
                        if (grid.current && grid.current.getSelectedRecords() !== null && grid.current.getSelectedRecords().length > 0)
                            setFileToDelete(grid.current.getSelectedRecords()[0] as FileDiaryDTO);
                    }}
                    size='small' sx={{ marginLeft: '20px', height: '24px', padding: '16px', fontSize: '12px' }} variant='contained' startIcon={<Delete />} disabled={!isItemsSelected}>Smazat</Button>}
            </Box>
            <GridComponent style={{ borderColor: 'transparent' }}
                id="grid-component-docs"
                ref={grid}
                allowReordering={true}
                allowResizing={true}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick', type: 'Single' }}
                locale={i18n.language}
                enablePersistence={false}
                filterSettings={standardFilterOptions}
                rowSelected={(e) => { setIsItemsSelected(true) }}
                rowDeselected={(e) => { setIsItemsSelected(false) }}
                allowFiltering={true}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                    <ColumnDirective field='fileName' headerText={t('Name')} maxWidth='800' width='180' minWidth='100' />
                    <ColumnDirective field='createdAt' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Created')} maxWidth='800' width='180' minWidth='100' />
                    <ColumnDirective field='extension' headerText={t('Extension')} maxWidth='800' width='80' minWidth='20' />


                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
            </GridComponent>
        </Box>
    );
}