import { useEffect, useState } from "react";
import { WithLoadingProps } from "../Shared/Loader";
import DiaryMainSceneTabs from "./DiaryMainSceneTabs";
import DiaryMainScene from "./DiaryMainScene";
import { useMediaPredicate } from "react-media-hook";

interface DiaryMainSceneProps extends WithLoadingProps {
    buildingDiaryRecordIdProps?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal?: boolean;
}

export default function DiaryMainSceneWrapper(props: DiaryMainSceneProps) {
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [displayAsTabs, setDisplayAsTabs] = useState(() => {
        const savedDisplayAsTabs = localStorage.getItem("displayAsTabs");
        return savedDisplayAsTabs !== null ? JSON.parse(savedDisplayAsTabs) : false;
    });

    useEffect(() => {
        localStorage.setItem("displayAsTabs", JSON.stringify(displayAsTabs));
    }, [displayAsTabs]);

    return (
        <>
            {biggerThan700 && displayAsTabs ? (
                <DiaryMainSceneTabs
                    {...props}
                    displayAsTabs={displayAsTabs}
                    setDisplayAsTabs={(e) => {
                        setDisplayAsTabs(e);
                    }}
                />
            ) : (
                <DiaryMainScene
                    {...props}
                    displayAsTabs={displayAsTabs}
                    setDisplayAsTabs={(e) => {
                        setDisplayAsTabs(e);
                    }}
                />
            )}
        </>
    );
}
