import React, { useEffect } from "react";
import { Autocomplete, Dialog, DialogTitle, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { SolveInvestorProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../../Contexts/LoginOldContext";
import { BuildingDTO } from "../../../ApiOld/ApiServerVov";

export interface InvestorProjectListProps {
    selectedProjectId?: number | null;
    isReadOnly: boolean;
    onChange: (event: BuildingDTO | null) => void;
    width?: string;
    backgroundColor?: string;
    emptyText?: string;
    catchError?: boolean;
    isMandatory?: boolean;
}
export default function InvestorBuildingSelect(props: InvestorProjectListProps) {
    const { onChange, selectedProjectId, isReadOnly, width, backgroundColor, emptyText, catchError, isMandatory } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedProject, setSelectedProject] = React.useState<BuildingDTO | null>(null);
    const [projects, setProjects] = React.useState<BuildingDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;
    const [open, setOpen] = React.useState(false);

    const handleChange = (event: SelectChangeEvent<typeof selectedProject>) => {
        if (event.target.value !== undefined) {
            setSelectedProject(event.target.value as (BuildingDTO | null));

            onChange(event.target.value as (BuildingDTO | null));
        }

    };
    useEffect(() => {

        SolveInvestorProjectsContext(globalContext, setGlobal).then((res) => {
            setProjects(res)
        });

    }, []);
    useEffect(() => {
        if (projects !== undefined && projects !== null && projects.length > 0
            && selectedProjectId !== undefined &&
            selectedProjectId !== null && selectedProjectId > 0) {
            let q = projects.find(x => x.id === selectedProjectId);
            if (q !== undefined)
                setSelectedProject(q);
        }
        else if (projects !== undefined && projects.length === 1) {
            setSelectedProject(projects[0]);
        }

    }, [projects, selectedProjectId]);
    return (
        <div style={{ width: width === undefined ? '280px' : width }}>
            {catchError &&/*  <ValidationGroup> */
                <div><Validate name={'building-validate-input'} required={[true, t('FieldRequired')]}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        onChange={(e, v) => {
                            setSelectedProject(v);
                            onChange(v);
                        }}
                        readOnly={isReadOnly}
                        getOptionLabel={o => o !== null ? o!.name! : ""}
                        value={selectedProject}
                        options={projects}
                        sx={{ width: width === undefined ? 280 : width, backgroundColor: backgroundColor === undefined ? '#FFFFFF' : backgroundColor }}
                        renderInput={(params) => <TextField
                            error={isMandatory === true && selectedProject === null}
                            {...params} label={emptyText === undefined ? t('Projekt') : (selectedProject === null ? emptyText : '')}

                        />}
                    /></Validate>
                    {selectedProject === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '0px', marginTop: '8px', color: theme.palette.error.main, fontSize: 13 }}>{t('FieldRequired')}</p>}
                </div>
            }
            {catchError === false || catchError === undefined && <div><Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedProject(v);
                    onChange(v);
                }}
                readOnly={isReadOnly}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedProject}
                options={projects}

                sx={{ width: width === undefined ? 280 : width, backgroundColor: backgroundColor === undefined ? '#FFFFFF' : backgroundColor }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedProject === null} {...params} label={emptyText === undefined ? t('Projekt') : (selectedProject === null ? emptyText : '')}


                />}
            />

                {selectedProject === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '0px', marginTop: '8px', color: theme.palette.error.main, fontSize: 13 }}>{t('FieldRequired')}</p>}
            </div>
            }
        </div>
    );
}