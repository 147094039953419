import { Box, Button, CssBaseline, Divider, Grid, IconButton, Popover, styled, Tab, Tabs, ThemeProvider, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation, Route, Routes, useNavigate, useParams } from 'react-router';
import { TabPanel } from '../Shared/Tabs/TabPanel';
import tabProps from '../Shared/Tabs/TabProps';
import { AccountCircle, BookOutlined, CameraAltOutlined, DocumentScanner, MenuOutlined, NewspaperOutlined, NotificationAdd, Psychology, Search } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useMediaPredicate } from 'react-media-hook';
import stavarioPic from '../../Assets/login-logo-stavario.svg'; // with import
import stavarioPicSmall from '../../Assets/logo512.png'; // with import
import LanguagePicker from '../Shared/LanguagePicker';
import { StavarioOldGlobalContext } from '../../Contexts/LoginOldContext';
import createThemeStavario from '../Shared/Themes/CreateTheme';
import InvestorCreditsChangeScene from './Components/InvestorCreditsChangeScene';
import InvestorPasswordChangeScene from './Components/InvestorPasswordChangeScene';
import { loadInvestorStore, useInvestorStore } from '../../Contexts/InvestorZContext';
import { BuildingDTO, GetInvestorInfoResponse } from '../../ApiOld/ApiServerVov';
import InvestorDocumentsScene from './Components/InvestorDocumentsScene';
import InvestorBuildingSelect from './Components/InvestorBuildingSelect';
import InvestorBuildingSelectDialog from './Components/InvestorBuildingSelectDialog';
import InvestorTasksScene from './InvestorTasksScene';
import InvestorPhotosScene from './InvestorPhotosScene';
import InvestorAttendanceScene from './InvestorAttendanceScene';
import InvestorDiaryRecordsScene from './InvestorDiaryRecordsScene';
import InvestorDiaryMainScene from './InvestorDiaryMainScene';


interface InvestorTabsProps {
    selectedIndex?: number
}

export default function InvestorTabs(props: InvestorTabsProps) {

    const { selectedIndex } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const navigate = useNavigate();
    //const [selectedTab, setSelectedTab] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();
    const theme = createThemeStavario();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openUserMenu = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;
    const params = useParams();
    const [creditsOpen, setCreditsOpen] = React.useState(false);
    const [buildingSelectOpen, setBuildingSelectOpen] = React.useState(false);
    const [diaryRecordId, setDiaryRecordId] = React.useState<number>();
    const [building, setBuilding] = React.useState<BuildingDTO>();
    const [passOpen, setPassOpen] = React.useState(false);
    const { credentials, load } = useInvestorStore();

    const drawerWidth = 240;

    useEffect(() => {
        if (selectedIndex)
            handleTabSelected(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        // handleTabSelected(selectedIndex);
        console.log('investor tabs');
        if (credentials === undefined)
            loadInvestorStore().then((d: GetInvestorInfoResponse) => {
                load(d);
            })
    }, []);

    function logout(): void {
        localStorage.removeItem('investor_token_old');
        navigate({
            pathname: '/' + params['lang'] + '/' + params['domain'] + '/investor',
            // search: '?sort=date&order=newest',
        });
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {

        console.log("wtf:" + i18n.language);
        if (newValue === 0) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/tasks');
            // navigate('tasks');
        }
        else if (newValue === 1) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/photos');
            // navigate('photos');
        }
        else if (newValue === 2) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/diary');
            // navigate('diary');
        }
        else if (newValue === 3) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/dochazka');
            // navigate('/dochazka');
        }
        else if (newValue === 4) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/documents');
            // navigate('/dochazka');
        }
        else if (newValue === 5) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/diary/' + params['recordId']!);
            setDiaryRecordId(parseInt(params['recordId']!));
            // navigate('/dochazka');
        }
        if (newValue !== 5)
            setValue(newValue);
        else setValue(2);
    };

    const handleTabSelected = (newValue: number) => {
        console.log("wtf:" + i18n.language);
        if (newValue === 0) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/tasks');
            // navigate('tasks');
        }
        else if (newValue === 1) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/photos');
            // navigate('photos');
        }
        else if (newValue === 2) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/diary');
            setDiaryRecordId(undefined);
            // navigate('diary');
        }
        else if (newValue === 3) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/dochazka');
            // navigate('/dochazka');
        }
        else if (newValue === 4) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/documents');
            // navigate('/dochazka');
        }
        else if (newValue === 5) {
            navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/tabs/diary/' + params['recordId']!);
            setDiaryRecordId(parseInt(params['recordId']!));
            // navigate('/dochazka');
        }
        if (newValue !== 5)
            setValue(newValue);
        else setValue(2);
    };


    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    function creditsOpenClosed() { setCreditsOpen(false); }
    function passOpenClosed() { setPassOpen(false); }
    function buildingSelectOpenClosed() { setBuildingSelectOpen(false); }

    return (
        <div >
            <div style={{ display: 'flex', position: 'fixed', top: 0, width: '100%', backgroundColor: '#F5F5F5', height: '50px', zIndex: 5000 }}>
                <Grid container spacing={6}>
                    <Grid item xs={8}>
                        <div style={{ float: 'left', margin: '5px', marginTop: '10px', marginLeft: '10px' }}>
                            <img src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                                alt="stavario" width={biggerThan700 ? "128px" : "35px"}
                                height={biggerThan700 ? "26px" : "35px"} ></img>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ float: 'right', margin: '5px', marginTop: '10px', marginRight: '10px', display: 'flex' }}>
                            <div style={{ marginRight: '16px', marginLeft: '10px', marginTop: '4px' }}>
                                <IconButton sx={{ width: '40px', height: '40px', marginTop: '-10px', marginBottom: '10px' }} onClick={() => { navigate('/' + i18n.language + '/' + params['domain'] + '/investor/main/ai'); }}>
                                    <Psychology sx={{ width: '36px', height: '36px' }} />
                                </IconButton>
                            </div>
                            <div style={{ marginRight: '16px', marginLeft: '10px', marginTop: '4px' }}>
                                <LanguagePicker showText={biggerThan700} onLanguageChange={(e) => {

                                    console.log('měním state');
                                    navigate('/' + e + '/' + params['domain'] + '/investor/main/tabs/tasks');
                                }}></LanguagePicker>
                            </div>
                            <div style={{ textAlign: 'end', marginTop: '6px' }}>
                                <Typography variant={biggerThan700 ? "subtitle2" : "caption"} sx={{ margin: '-4px' }} noWrap component="div">
                                    {credentials === undefined ? '' : credentials.name}
                                </Typography>
                                <Typography variant="caption" fontWeight={'400'} color={theme.palette.primary.main}
                                    noWrap component="div" sx={{ margin: '-4px' }}>
                                    {credentials === undefined ? '' : credentials!.companyName}
                                </Typography>

                            </div>

                            <div style={{

                                width: "40px",
                                height: "40px",
                                marginTop: '-2px'
                            }}>
                                <Button onClick={handleUserMenuClick} style={{
                                    width: "40px",
                                    height: "40px"
                                }} aria-describedby={id}>
                                    <div className="rounded-image">
                                        {/* <Typography color='white'>{globalContext.name}</Typography> */}
                                        <AccountCircle sx={{
                                            width: "40px",
                                            height: "40px"
                                        }} />
                                    </div>
                                </Button>
                                <Popover
                                    id={id}
                                    sx={{ zIndex: 50002 }}
                                    open={openUserMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div className="popup-content-div" >
                                        {/* <div>
    <Button color="secondary" sx={{ width: "160px", paddingRight: "12px", justifyContent: "flex-start" }}>

        <NotificationAdd />
        <Typography sx={{ marginLeft: "10px", marginRight: "6px" }}>Notifikace</Typography>

    </Button>
</div> */}
                                        <div>
                                            <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setCreditsOpen(true); }}>
                                                {t('myProfile')}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setPassOpen(true); }}>
                                                {t('login.changeOfPassword')}
                                            </Button>
                                        </div>
                                        <Divider sx={{ bgcolor: "primary.main" }} />

                                        <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                            {t('login.logout')}
                                        </Button>

                                    </div>
                                </Popover>
                            </div>



                        </div>
                    </Grid>
                </Grid>


            </div>

            {/* <CssBaseline /> */}
            {/* <AppBar color='default' position="fixed" open={true}> */}

            {/*   </AppBar> */}
            <div style={{ backgroundColor: '#FFC600' }}>
                <Box style={{ borderBottom: 3, borderColor: 'lightgray', background: '#FFC600', top: '50px', width: biggerThan700 ? '15%' : '10%', height: '50px', position: 'fixed', zIndex: 5000, }}>
                    <div style={{ margin: '5px', marginTop: '7px' }}>
                        {biggerThan700 && <InvestorBuildingSelect catchError={false} emptyText={t('VsechnyProjekty')} backgroundColor='#FFC600' isReadOnly={false} onChange={(e) => { if (e !== null) setBuilding(e); else setBuilding(undefined); }} selectedProjectId={building?.id} width={'auto'} />
                        }
                        {!biggerThan700 && <Button color="primary" onClick={() => { console.log('kkt'); setBuildingSelectOpen(true); }}>
                            <Search sx={{ color: 'black', marginLeft: '-5px' }} />
                        </Button>
                        }
                    </div>
                </Box>
                <Box sx={{ borderBottom: 3, borderColor: 'lightgray', background: '#FFC600', top: '50px', width: biggerThan700 ? '85%' : '90%', position: 'fixed', zIndex: 5000, marginLeft: biggerThan700 ? '15%' : '10%' }}>

                    <Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label="basic tabs example"
                        sx={{
                            "& button": { color: 'black' },
                            "& button:hover": { color: 'white' },
                            "& button:focus": { color: 'white' },
                            "& button:active": { color: 'white' },
                            "& button.Mui-selected": { color: 'white' },
                            height: biggerThan700 ? '50px' : '25px'
                        }}
                        TabIndicatorProps={{ sx: { backgroundColor: 'white', height: '5px' } }}>

                        <Tab icon={<BookOutlined style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>{t('todo.tasks')}</div>} {...tabProps(0)} iconPosition='start' />
                        <Tab icon={<CameraAltOutlined style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>{t('dashboard.photos')}</div>} {...tabProps(1)} iconPosition='start' />
                        <Tab icon={<NewspaperOutlined style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>{t('menu.constructionDiary')}</div>} {...tabProps(2)} iconPosition='start' />
                        <Tab icon={<AccessTimeIcon style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>{t('dashboard.attendance')}</div>} {...tabProps(3)} iconPosition='start' />
                        <Tab icon={<DocumentScanner style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>{t('Documents')}</div>} {...tabProps(4)} iconPosition='start' />

                    </Tabs>
                </Box>
            </div>
            <TabPanel value={value} index={0}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '100px' }}>
                    <InvestorTasksScene buildingId={building !== undefined ? building.id : undefined} />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '100px' }}>
                    <InvestorPhotosScene buildingId={building !== undefined ? building.id : undefined} buildingName={building !== undefined ? building.name : undefined} />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '100px' }}>
                    {diaryRecordId === undefined && <InvestorDiaryRecordsScene buildingId={building !== undefined ? building.id : undefined} />}
                    {diaryRecordId !== undefined && <div ><InvestorDiaryMainScene /></div>}
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '100px' }}>
                    {/* <GptChatScene /> */}
                    <InvestorAttendanceScene buildingId={building !== undefined ? building.id : undefined} />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '100px' }}>
                    <InvestorDocumentsScene buildingId={building !== undefined ? building.id : undefined} />
                </Box>
            </TabPanel>

            <InvestorCreditsChangeScene onRefresh={() => { }} open={creditsOpen} onClose={creditsOpenClosed} />
            <InvestorPasswordChangeScene onRefresh={() => { }} open={passOpen} onClose={passOpenClosed} />
            {!biggerThan700 && <InvestorBuildingSelectDialog
                catchError={false}
                emptyText={t('VsechnyProjekty')}
                backgroundColor='#FFC600'
                isReadOnly={false}
                onChange={(e) => {
                    if (e !== null)
                        setBuilding(e); else
                        setBuilding(undefined);
                }}
                selectedProjectId={building?.id}
                width={'auto'}
                open={buildingSelectOpen}
                onClose={buildingSelectOpenClosed} />
            }
        </div >
    );
}

