import { ArrowBack, ArrowLeft } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, IconButton, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BookOnlineOutlined, ConnectWithoutContact, ConstructionOutlined, Dashboard, FolderOpenOutlined, FolderOutlined, Group, HelpOutline, Home, MailOutline, Outbound, PrecisionManufacturingOutlined, QueryStatsOutlined, Report, Settings, SettingsOutlined, Task } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { StavarioModule } from './StavarioModule';
import { useNavigate } from 'react-router';
import UserAvatar from './UserAvatar';
import LayoutUserMenu from './LayoutUserMenu';

interface UserLayoutUpperMenuProps {
  drawerOpen: boolean;
  onOpenDrawer: () => void;
  onChangeView: () => void;
  showMenuExpandButton: boolean;
}

export default function UserLayoutUpperMenu(props: Readonly<UserLayoutUpperMenuProps>) {
  const { drawerOpen, onOpenDrawer, onChangeView, showMenuExpandButton } = props;
  const biggerThan700 = useMediaQuery('(min-width:700px)');
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const { activeModule, setActiveModule } = useUserLayoutContext();
  const handleClose = () => {
    setShowMobileMenu(false);
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isProjectActive = React.useMemo(() => activeModule === StavarioModule.ProjectDetail || activeModule === StavarioModule.ProjectList, [activeModule]);
  const isDiaryActive = React.useMemo(() => activeModule === StavarioModule.DiariesList || activeModule === StavarioModule.DiaryDetail || activeModule === StavarioModule.DiaryRecordsList || activeModule === StavarioModule.DiaryRecordDetail, [activeModule]);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };
  const [anchorElAvatar, setAnchorElAvatar] = React.useState<null | HTMLElement>(null);
  const handleCloseAvatarMenu = () => {
    setAnchorElAvatar(null);
  };
  return (
    <Box sx={{ flexGrow: 1, width: '100%', }}>
      <AppBar color='transparent' position="static">
        <Toolbar sx={{ minHeight: '64px', maxHeight: '64px' }}>
          <Box alignItems='center' display='flex' justifyContent='space-between' width='100%' >
            <Box>
              {showMenuExpandButton && <IconButton
                onClick={onOpenDrawer}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                {!drawerOpen && <MenuIcon />}
                {drawerOpen && <ArrowBack />}
              </IconButton>}
              {!biggerThan700 && <IconButton
                onClick={() => { setShowMobileMenu(p => !p) }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                {!showMobileMenu && <MenuIcon />}
                {showMobileMenu && <Close />}
              </IconButton>}
              <Menu
                anchorEl={anchorEl}
                open={showMobileMenu}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </MenuItem>
                <MenuItem sx={{ background: isProjectActive ? theme.palette.primary.main : 'inherit' }} onClick={() => { setActiveModule(StavarioModule.ProjectList); handleClose(); navigate('projects'); }}>
                  <ListItemIcon>
                    <ConstructionOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Projekty" />
                </MenuItem>
                <MenuItem sx={{ background: isDiaryActive ? theme.palette.primary.main : 'inherit' }} onClick={() => { setActiveModule(StavarioModule.DiaryRecordsList); handleClose(); navigate('diary/diaryvov'); }}>
                  <ListItemIcon>
                    <BookOnlineOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Stavební deník" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Group />
                  </ListItemIcon>
                  <ListItemText primary="Zaměstnanci" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PrecisionManufacturingOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Majetek" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Task />
                  </ListItemIcon>
                  <ListItemText primary="Úkoly" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Sklad" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <QueryStatsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Reporty" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <FolderOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Dokumenty" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <SettingsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Nastavení" />
                </MenuItem>
              </Menu>
              <img
                onClick={() => { onChangeView(); }}
                // onClick={handleBackToStavario}
                // style={{ marginTop: '10px', marginLeft: '20px' }}
                width="160px"
                height="24px"
                src="http://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                alt="stavario"
              ></img>
            </Box>
            {biggerThan700 && <Box display='flex' alignItems='center'>
              <Typography fontSize={12} fontWeight='bold'>Poraď se s ostatními</Typography>
              <Typography fontSize={12} ml={0.5}> a buď aktivní v komunitě Stavario</Typography>
              <Box onClick={() => { window.location.href = 'https://stavario.zendesk.com/hc/cs/community/topics'; }}>
                <Typography fontSize={12} ml={0.5} sx={{ textDecoration: 'underline', cursor: 'pointer' }} fontWeight='bold'> více</Typography>
              </Box>
              <Avatar sx={{ boxShadow: 2, ml: 1 }} src="https://cc.cz/wp-content/uploads/fly-images/132861/josefbenes-820x436-c.jpg"></Avatar>
            </Box>}
            {!biggerThan700 && <Box display='flex' alignItems='center'>
              <UserAvatar isUppside handleAvatarClick={handleAvatarClick} />
              <LayoutUserMenu isUppside
                anchorEl={anchorElAvatar} handleClose={handleCloseAvatarMenu} onChangeView={onChangeView} />
            </Box>}
          </Box>

          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </Box>)
}
