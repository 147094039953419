import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserLayoutContext } from "../../../Contexts/Providers/UserLayoutMenuProvider";


export interface IMobileEntryProps {
}

export default function MobileEntry(props: IMobileEntryProps) {
    const params = useParams();
    const navigate = useNavigate();
    const { setHideTopBar } = useUserLayoutContext();
    useEffect(() => {
        if (params['module']) {
            setHideTopBar(true);
            if (params['module'] === 'diary') {
                navigate('/' + params['lang'] + '/' + params['domain'] + '/userlayout/diary/diaryvov/');
            }
            else if (params['module'] === 'projects') {
                navigate('/' + params['lang'] + '/' + params['domain'] + '/userlayout/projects/');
            }
        }
    }, [params, navigate, setHideTopBar]);
    return (
        <Box></Box>
    );

}
