import { Box, Tab, Tabs, IconButton, Checkbox, FormControlLabel, Autocomplete } from "@mui/material";
import { Check, Reply, Save } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import delay from "../../../Shared/Functions/Delay";
import SplitButton from "../../../Shared/SplitButton";
import { APIOldBuildingsClient } from "../../../../ApiOld/ApiOldBuildingsClient";
import { GetProjectFileDetailResponse, SaveProjectFileDetailRequest } from "../../../../ApiOld/ApiServerVov";
import AdminUserSelect from "../../../Shared/AdminUserSelect";
import ProjectDocumentFileScene from "./ProjectDocumentFileScene";
import { useMediaPredicate } from "react-media-hook";

interface ProjectDocumentDetailSceneProps {
    selectedDocumentId?: number;
    onSave: () => void;
    refresh: boolean;
    projectId: number;
    onRefresh: () => void;
}

export default function ProjectDocumentDetailScene(props: ProjectDocumentDetailSceneProps) {
    const { selectedDocumentId, onSave, refresh, projectId, onRefresh } = props;
    const [documentData, setDocumentData] = useState<Partial<GetProjectFileDetailResponse> | null>(null);

    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [refreshFiles, setRefreshFiles] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        refreshData();
    }, [selectedDocumentId]);

    const refreshData = () => {
        if (selectedDocumentId) {
            new APIOldBuildingsClient().GetProjectFileDetail(selectedDocumentId).then((d) => {
                setDocumentData(d);
            });
        }
        else setDocumentData(null);

        setRefreshFiles(true);
    }

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        if (refresh === true)
            refreshData();

    }, [refresh]);

    useEffect(() => {
        if (refreshFiles === true)
            setRefreshFiles(false);

    }, [refreshFiles]);

    function saveDocument() {
        if (documentData)
            new APIOldBuildingsClient().SaveProjectFileDetail(new SaveProjectFileDetailRequest({
                id: documentData.id,
                adminUserFromId: documentData.adminUserFromId,
                adminUserToId: documentData.adminUserToId,
                canOrderSolution: documentData.canOrderSolution,
                emailTemplateId: documentData.emailTemplateId,
                expirationDate: documentData.expirationDate,
                investorId: documentData.investorId,
                isCategory: documentData.isCategory,
                isDone: documentData.isDone,
                name: documentData.name,
                showToClient: documentData.showToClient,
                signatureImage: documentData.signatureImage
            })).then((d) => {
                if (d)
                    setSaveDone(true);
                onSave();
            });
    }

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/projects')
    }

    const categoriesOptions = [{ id: 0, value: t('files.folder') },
    { id: 1, value: t('files.files') }];

    const showInvestorOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const isDoneOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const orderSolutionOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    if (documentData)
        return (
            <Box sx={{
                height: '100%'
            }}>
                {/* <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '70px', zIndex: 30 }}>
                    {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                    <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={documentData === undefined || documentData === null} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveDocument();
                                }
                                else if (e === 1) {
                                    saveDocument();
                                    redirectToList();
                                }
                            }}></SplitButton></div>
                </div> */}
                <Box sx={{ border: '1px solid lightgray', borderRadius: '10px' }}>
                    <ProjectDocumentFileScene onRefresh={onRefresh} selectedDocumentId={documentData.id} onSave={() => { }} refresh={refreshFiles} projectId={projectId} />
                </Box>
                <div style={{ background: 'white', padding: '20px', border: '1px solid lightgray', borderRadius: '10px', marginTop: '24px' }}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <AdminUserSelect isSubdodavatel={false} onChange={async (e) => {
                                setDocumentData({ ...documentData, adminUserFromId: e === null ? undefined : e.id });
                            }} selectedUserId={documentData.adminUserFromId} label={t('files.fromUser')} />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <Validate name='name-file-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                <TextField label={t('Name')} variant="outlined" fullWidth size='small'
                                    name='name'
                                    value={documentData.name}
                                    onChange={(e) => { setDocumentData({ ...documentData, name: e.target.value }) }}
                                />
                            </Validate>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-done"
                                options={isDoneOptions}
                                size="small"
                                disableClearable
                                value={documentData.isDone ? isDoneOptions[0] : isDoneOptions[1]}
                                onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, isDone: v.id == 0 ? true : false }) } }}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label={t('files.isDone')} />}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <AdminUserSelect isSubdodavatel={false} onChange={async (e) => {
                                setDocumentData({ ...documentData, adminUserToId: e === null ? undefined : e.id });
                            }} selectedUserId={documentData.adminUserToId} label={t('files.notificationForUser')} />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-show-investors"
                                options={showInvestorOptions}
                                size="small"
                                disableClearable
                                value={documentData.showToClient ? showInvestorOptions[0] : showInvestorOptions[1]}
                                onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, showToClient: v.id == 0 ? true : false }) } }}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label={t('files.showInvestors')} />}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <Autocomplete
                                disablePortal
                                disabled
                                id="combo-box-employee"
                                options={[]}
                                size="small"
                                onChange={(e, v) => { }}
                                renderInput={(params) => <TextField {...params} label={t('files.forEmployees')} />}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <TextField label={t('files.expirationDate')} variant="outlined" fullWidth size='small'
                                name='expDate'
                                type="date"
                                value={
                                    documentData.expirationDate &&
                                        documentData.expirationDate.toISOString() !== new Date(1, 0, 1).toISOString() ?
                                        documentData.expirationDate.toISOString().split('T')[0] :
                                        undefined
                                }
                                onChange={(e) => { console.log(e); setDocumentData({ ...documentData, expirationDate: e.target.value ? new Date(e.target.value) : undefined }) }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Box>
        );
    return <div>{t('loading...')}</div>;
}