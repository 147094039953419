import { Check, Save } from '@mui/icons-material';
import { Button, TextField, Grid, Box, FormControl, Select, MenuItem, InputLabel, Stack } from '@mui/material';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { EmployeeGroupsDTO, GetWealthDetailsResponse } from '../../../ApiOld/ApiServerVov';
import BuildingSelect from '../../Shared/BuildingSelect';
import EmployeeSelect from '../../Shared/EmployeeSelect';
import delay from '../../Shared/Functions/Delay';
import WarehouseSelect from '../../Shared/WarehouseSelect';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import InitSfRTBLocale from '../../../Localization/SFRichEditLanguageIntializer';
import MuiDatagrid from '../../Shared/MuiDatagrid';
import { GridColDef, GridRowModes, GridRowModesModel, GridRowSelectionModel, GridRowsProp, GridSlotsComponent, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWealthsContext } from '../../../Contexts/WealthsContext';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

InitSfRTBLocale();

function WealthDetail() {
    const param = useParams();
    const [saved, setSaved] = useState<boolean>(false);
    const [newId, setNewId] = useState<number>(-1);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { wealthDetailData, setWealthDetailData } = useWealthsContext();

    useEffect(() => {
        if (wealthDetailData) {
            setRows(wealthDetailData.employeeGroups?.map((group) => new EmployeeGroupsDTO(group)) ?? [new EmployeeGroupsDTO()]);
        }
    }, [setRows, wealthDetailData]);


    useEffect(() => {
        if (saved) delay(1000).then(() => { setSaved(false) })
    }, [saved]);
    async function save() {
        if (wealthDetailData) {
            wealthDetailData.employeeGroups = rows.map(row => {
                return new EmployeeGroupsDTO(row)
            });
            let res = await new APIOldWealthClient().SaveWealthDetails(wealthDetailData);

            if (res?.ok === true) {
                res.id ? navigate(
                    {
                        pathname: `/${i18n.language}/${param['domain']!}/userlayout/wealths/${res.id}`,
                    },
                ) :
                    setSaved(true)
            }
        }
    }

    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            // 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }

    const processRowUpdate = (newRow) => {
        setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'selectedId',
            headerName: t('Group'),
            type: 'singleSelect',
            editable: true,
            flex: 1,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: wealthDetailData?.employeeGroups !== undefined ? wealthDetailData?.employeeGroups.map((employee) => ({ code: employee.id, name: employee.name })) : [],
        },
    ]

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const newRow = new EmployeeGroupsDTO({
                id: newId,
                isDeleted: false,
                name: "",
            });
            setRows((oldRows) => [...oldRows, newRow]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
            setNewId((prevId) => prevId--);
        };

        const handleDeleteClick = () => {
            const selectedIDs = new Set(selectionModel);
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id !== undefined && selectedIDs.has(row.id)
                        ? { ...row, isDeleted: true } as EmployeeGroupsDTO
                        : row
                )
            );
        }
        return (
            <GridToolbarContainer>
                <Stack sx={{ width: '100%', alignItems: 'center', padding: 1.5, borderBottom: '1px solid lightgray' }} spacing={2} direction={'row-reverse'}>
                    <Button
                        color="primary"
                        variant='contained'
                        size='small'
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={selectionModel.length === 0}
                    >
                        {t('Delete')}
                    </Button>
                    <Button size='small' variant='contained' color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                        {t('Add')}
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    if (wealthDetailData === null || wealthDetailData === undefined || setWealthDetailData === null || setWealthDetailData === undefined)
        return <Box>{t('loading...')}</Box>;
    return (

        <div>
            <ValidationGroup>
                <div>
                    <Box style={{ display: 'flex', padding: 4 }}>
                        <AutoDisabler>
                            <Button variant='contained' size='small' sx={{ margin: '8px' }} startIcon={<Save />} onClick={save}>
                                {t('save')}
                            </Button>
                        </AutoDisabler>
                        {saved && <Check sx={{ marginTop: '10px' }} color='success' />}
                    </Box>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid sx={{ padding: 4 }} container direction={'row'} spacing={1}>
                            <Grid item xs={4}>
                                <h4 style={{ borderBottom: '1px solid gray', margin: '8px' }}>{t('BasicParameters')}</h4>
                                <FormControl fullWidth>
                                    <InputLabel id="isCategory-label">{t('files.fileType')}</InputLabel>
                                    <Select
                                        value={wealthDetailData.isCategory ? 1 : 0}
                                        label={t('files.fileType')}
                                        sx={{ margin: '8px' }}
                                        size="small"
                                        onChange={(e) => {
                                            setWealthDetailData({ ...wealthDetailData, isCategory: e.target.value === 1 } as GetWealthDetailsResponse)
                                        }}>
                                        <MenuItem value={1}>{t('files.folder')}</MenuItem>
                                        <MenuItem value={0}>{t('Record')}</MenuItem>
                                    </Select>
                                </FormControl>
                                {!wealthDetailData.isCategory &&
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel id="isActive-label">{t('active')}</InputLabel>
                                            <Select
                                                labelId="isActive-label"
                                                label={t('active')}
                                                value={wealthDetailData.isActive ? 1 : 0}
                                                sx={{ margin: '8px' }}
                                                size="small"
                                                onChange={(e) => {
                                                    setWealthDetailData({ ...wealthDetailData, isActive: e.target.value === 1 } as GetWealthDetailsResponse)
                                                }}>
                                                <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                <MenuItem value={0}>{t('noL')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="showToEmps-label">{t('showToEmployees')}</InputLabel>
                                            <Select
                                                labelId="showToEmps-label"
                                                value={wealthDetailData.showToEmps ? 1 : 0}
                                                label={t('showToEmployees')}
                                                sx={{ margin: '8px' }}
                                                size="small"
                                                onChange={(e) => {
                                                    setWealthDetailData({ ...wealthDetailData, showToEmps: e.target.value === 1 } as GetWealthDetailsResponse)
                                                }}>
                                                <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                <MenuItem value={0}>{t('noL')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div style={{ margin: '8px' }}>
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('SerialNumber')}
                                                fullWidth
                                                value={wealthDetailData.serialNumber}
                                                onChange={(e) => { setWealthDetailData({ ...wealthDetailData, serialNumber: e.currentTarget.value } as GetWealthDetailsResponse) }}
                                            />
                                            <Validate name='code-wealth-validate' initialValidation='silent' required={[true, t('FieldRequired')]}>
                                                <TextField
                                                    sx={{ margin: '8px 0px' }}
                                                    size='small'
                                                    label={t('code')}
                                                    error={wealthDetailData.code === ""}
                                                    fullWidth
                                                    value={wealthDetailData.code}
                                                    onChange={(e) => { setWealthDetailData({ ...wealthDetailData, code: e.currentTarget.value } as GetWealthDetailsResponse) }}
                                                />
                                            </Validate>
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('Manufacturer')}
                                                fullWidth
                                                value={wealthDetailData.manufacturer}
                                                onChange={(e) => { setWealthDetailData({ ...wealthDetailData, manufacturer: e.currentTarget.value } as GetWealthDetailsResponse) }}
                                            />
                                        </div>
                                        <h4 style={{ margin: '20px 8px', borderBottom: '1px solid gray' }}>{t('Lending')}</h4>
                                        <div style={{ margin: '8px' }}>
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('1-3Days')}
                                                fullWidth
                                                value={wealthDetailData.priceA}
                                                onChange={(e) => {
                                                    let num = parseInt(e.currentTarget.value);
                                                    if (!isNaN(num)) {
                                                        setWealthDetailData({ ...wealthDetailData, priceA: num } as GetWealthDetailsResponse)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('4-10Days')}
                                                fullWidth
                                                value={wealthDetailData.priceB}
                                                onChange={(e) => {
                                                    let num = parseInt(e.currentTarget.value);
                                                    if (!isNaN(num)) {
                                                        setWealthDetailData({ ...wealthDetailData, priceB: num } as GetWealthDetailsResponse)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('10AndMoreDays')}
                                                fullWidth
                                                value={wealthDetailData.priceC}
                                                onChange={(e) => {
                                                    let num = parseInt(e.currentTarget.value);
                                                    if (!isNaN(num)) {
                                                        setWealthDetailData({ ...wealthDetailData, priceC: num } as GetWealthDetailsResponse)
                                                    }
                                                }}
                                            />
                                            <TextField
                                                sx={{ margin: '8px 0px' }}
                                                size='small'
                                                label={t('DepositOfLoan')}
                                                fullWidth
                                                value={wealthDetailData.priceForRent}
                                                onChange={(e) => {
                                                    let num = parseInt(e.currentTarget.value);
                                                    if (!isNaN(num)) {
                                                        setWealthDetailData({ ...wealthDetailData, priceForRent: num } as GetWealthDetailsResponse)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <h4 style={{ margin: '8px', borderBottom: '1px solid gray' }}>{t('Description')}</h4>
                                <Validate name='name-wealth-validate' initialValidation='noisy' required={[true, t('FieldRequired')]}>
                                    <TextField
                                        sx={{ margin: '8px 0px' }}
                                        fullWidth
                                        size='small'
                                        label={t('Name')}
                                        value={wealthDetailData.name}
                                        onChange={(e) => { setWealthDetailData({ ...wealthDetailData, name: e.currentTarget.value } as GetWealthDetailsResponse) }}
                                    />
                                </Validate>
                                {!wealthDetailData.isCategory &&
                                    <div style={{ height: '200px' }} >
                                        <RichTextEditorComponent
                                            toolbarSettings={toolbarSettings}
                                            locale={i18n.language}
                                            value={wealthDetailData.description}
                                            height={'100%'}
                                            change={(eve: ChangeEventArgs) => { setWealthDetailData({ ...wealthDetailData, description: eve.value } as GetWealthDetailsResponse) }}

                                        >
                                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                                        </RichTextEditorComponent>
                                    </div>
                                }
                            </Grid>
                            {!wealthDetailData.isCategory &&
                                <Grid item xs={2}>
                                    <h4 style={{ margin: '8px', borderBottom: '1px solid gray' }}>{t('Currently')}</h4>
                                    <div style={{ margin: '8px' }}>
                                        <EmployeeSelect
                                            selectedEmployeeId={wealthDetailData.employeeId} label={t('CurrentlyResponsibleEmployee')} onChange={(id) => { if (id !== null) setWealthDetailData({ ...wealthDetailData, employeeId: id.id } as GetWealthDetailsResponse) }} />
                                    </div>
                                    <div style={{ margin: '8px' }}>
                                        <BuildingSelect label={t('CurrentlyAtProject')} selectedProjectId={wealthDetailData.buildingId} onChange={(id) => { if (id !== null) setWealthDetailData({ ...wealthDetailData, buildingId: id.id } as GetWealthDetailsResponse) }} />
                                    </div>
                                    <div style={{ margin: '8px' }}>
                                        <WarehouseSelect label={t('CurrentlyAtWarehouse')} selectedWarehouseId={wealthDetailData.warehouseId} onChange={(id) => { if (id !== null) setWealthDetailData({ ...wealthDetailData, warehouseId: id.id } as GetWealthDetailsResponse) }} />
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        {!wealthDetailData.isCategory &&
                            <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '10px', overflow: 'hidden', padding: 4 }}>
                                <h4 style={{ margin: '0px 8px' }}>{t('ShowToGroups')}</h4>
                                <MuiDatagrid
                                    editMode='row'
                                    columns={columns}
                                    rows={rows.filter((row) => !row.isDeleted)}
                                    checkboxSelection
                                    onRowSelectionModelChange={(ids) => {
                                        setSelectionModel(ids);
                                    }}
                                    sx={{ borderRadius: '10px' }}
                                    rowModesModel={rowModesModel}
                                    onRowModesModelChange={(newModel) => { setRowModesModel(newModel) }}
                                    processRowUpdate={processRowUpdate}
                                    slots={{
                                        toolbar: EditToolbar as GridSlotsComponent['toolbar'],
                                    }}
                                    slotProps={{
                                        toolbar: {
                                            setRows,
                                            setRowModesModel,
                                        },
                                    }}
                                />
                            </Box>
                        }
                    </div>
                </div>

            </ValidationGroup>

        </div>
    );
};
export default WealthDetail;