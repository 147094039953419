import React, { Fragment, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, CircleF, Libraries } from "@react-google-maps/api";
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';
import { libraries } from './LibrariesPlaces';


interface GoogleMap2SceneProps {
    selected: any;
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setSelected: (set: any) => void;
    setProjectData: (set: any) => void;
}

export default function GoogleMap2Scene(props: GoogleMap2SceneProps) {
    const { selected, projectData, setSelected, setProjectData } = props;
    const { t, i18n } = useTranslation();

    const { isLoaded } = useLoadScript({
        id: `google-map2`,
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries
    });

    const mapContainerStyle = { width: '100%', height: '400px' };

    useEffect(() => {
        if (/* projectData?.address2 !== undefined && */ projectData?.gpsX2 !== undefined && projectData?.gpsY2 !== undefined) {
            setSelected({ lat: projectData.gpsX2, lng: projectData.gpsY2, radius: projectData.gpsDiff2 });
        }
    }, []);

    useEffect(() => {
        if (/* projectData?.address2 !== undefined && */ projectData?.gpsX2 !== undefined && projectData?.gpsY2 !== undefined) {
            setSelected({ lat: projectData.gpsX2, lng: projectData.gpsY2, radius: projectData.gpsDiff2 });
        }
    }, [projectData]);

    const theme = useTheme();
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selected}
            zoom={15}
        >
            <Fragment key={'mapFragment2'}>
                {selected && <MarkerF onDragEnd={(e) => {
                    const lat = e.latLng?.lat()
                    const lng = e.latLng?.lng()

                    // Update the coordinates state
                    if (lat && lng) {
                        setSelected({ lat: lat, lng: lng, radius: selected.radius ? selected.radius : 1 });
                        setProjectData((projectData) => ({ ...projectData, address2: '', gpsX2: lat, gpsY2: lng, gpsDiff2: selected.radius }));

                    }
                }} draggable position={selected} />}
                {selected && <CircleF
                    center={{
                        lat: selected.lat,
                        lng: selected.lng
                    }}
                    radius={selected.radius ? selected.radius : 1}
                    options={{ fillColor: "#FF000033", strokeColor: "#FF000066" }}
                />}
            </Fragment>
        </GoogleMap>

    ) : <div><CircularProgress /></div>
}