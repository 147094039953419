import { Alert, AlertTitle, Button, CircularProgress, Slide, Snackbar, TextField } from '@mui/material';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import logo from '../../../Assets/logo.png';
import delay from '../Functions/Delay';
import { APIVovSubcontractorHelper } from '../../../ApiOld/ApiSubcontractorOldClient';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';


interface ResetPasswordProps {
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}

export default function ResetPassword(props: ResetPasswordProps) {
    const { isInvestor, isSubdodavatel } = props;
    const loc = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [passwordReseting, setPasswordReseting] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (loc.state !== undefined && loc.state !== null && loc.state.email !== undefined)
            setEmail(loc.state.email);
        if (params['lang'] !== undefined && params['lang'] !== i18n.language)
            i18n.changeLanguage(params['lang']);
    }, []);
    function resetPassword() {
        setPasswordReseting(true);
        if (isInvestor === true)
            new APIVovInvestorHelper().ResetInvestorPassword(params['domain']!, email).then((e) => {
                if (e.ok)
                    delay(1000).then(() => {
                        navigate({
                            pathname: '/' + i18n.language + '/' + params['domain'] + '/investor',
                        },
                            { state: { email: email } }
                        );
                        setPasswordReseting(false);
                    });
                else {
                    setPasswordReseting(false);
                    setEmail('');
                    setErrorMessage(e.errorMessage!);
                }

            })
        else if (isSubdodavatel === true) {
            new APIVovSubcontractorHelper().ResetSubcontractorPassword(params['domain']!, email).then((e) => {
                if (e.ok)
                    delay(1000).then(() => {
                        navigate({
                            pathname: '/' + i18n.language + '/' + params['domain'] + '/subcontractor',
                        },
                            { state: { email: email } }
                        );
                        setPasswordReseting(false);
                    });
                else {
                    setPasswordReseting(false);
                    setEmail('');
                    setErrorMessage(e.errorMessage!);
                }

            })
        }
        else {
            new APIVovHelper().ResetPassword(params['domain']!, email).then((e) => {
                if (e.ok)
                    delay(1000).then(() => {
                        navigate({
                            pathname: '/' + i18n.language + '/' + params['domain'],
                        },
                            { state: { email: email } }
                        );
                        setPasswordReseting(false);
                    });
                else {
                    setPasswordReseting(false);
                    setEmail('');
                    setErrorMessage(e.errorMessage!);
                }

            })
        }

    }
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    return <div>
        <ValidationGroup>
            <div className="Auth-form-container">

                <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={errorMessage !== ''}
                    autoHideDuration={5000}
                    onClose={() => { setErrorMessage('') }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                    <Alert onClose={() => { setErrorMessage('') }} severity="warning">
                        <AlertTitle>{t('login.loginFailed')}</AlertTitle>
                        {t('login.loginFailedDetail')}
                    </Alert>
                </Snackbar>

                {!passwordReseting && <div className="Auth-form">
                    <div className="Auth-form-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <img src={logo} width="200px"
                            height="54px" style={{ margin: '40px' }} />
                        <div style={{ margin: '8px', fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }}>{isInvestor === true ? t('investor.investorPasswordChange') : 'Změna hesla pro subdodavatele'}</div>
                        <div style={{ margin: '8px', textAlign: 'center' }}>{t('investor.enterRegisteredMail')}</div>
                        <Validate name='company-signup-validate' regex={[/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('sales.fieldEmailReqiured')]}>
                            <TextField label={t('login.enterEmail')} sx={{ marginTop: '10px' }} value={email} onChange={(e) => { setEmail(e.currentTarget.value); }} />
                        </Validate>
                        <AutoDisabler>
                            <Button sx={{ marginTop: '40px', marginBottom: '20px' }} onClick={resetPassword} variant='contained'>{t('investor.resetPassword')}</Button>
                        </AutoDisabler>
                    </div>
                </div>}
                {passwordReseting && <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} width="200px"
                        height="54px" style={{ margin: '40px' }} />
                    <CircularProgress sx={{ marginBottom: '20px' }} />
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('investor.changingPassword')}</div>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('investor.finishAndRedirect')}</div>
                </div>}

            </div>
        </ValidationGroup>
    </div>
}