import { Check, Circle, Done, ListAlt, Money, Send } from '@mui/icons-material';
import { Button, TextField, Typography, useTheme, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatGroupDTO, InvestorsChatMessageDTO, SubcontractorsChatMessageDTO } from '../../../../ApiOld/ApiServerVov';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import Dropdown from 'react-multilevel-dropdown';
import DvrIcon from '@mui/icons-material/Dvr';

interface SubcontractorChatWidgetProps {
    isExpanded: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    recordIdInput: number;
    onExpandChange: (expanded: boolean) => void;
}

export default function SubcontractorChatWidget(props: SubcontractorChatWidgetProps) {
    const { isExpanded, isInvestor, recordIdInput, isSubdodavatel, onExpandChange } = props;

    const [recordId, setRecordId] = useState<number>();
    const [messages, setMessages] = useState<SubcontractorsChatMessageDTO[] | null>(null);
    const [newMessage, setNewMessage] = useState<string>('');
    const [localGroups, setLocalGroups] = useState<ChatGroupDTO[] | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<ChatGroupDTO | null>(null);
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setRecordId(recordIdInput);
    }, [recordIdInput]);
    useEffect(() => {
        reloadData();
    }, [recordId]);
    function reloadData() {
        if (recordId)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetChatSubcontractorsMessages(recordId).then((d) => {
                if (d) {
                    let selected = selectedGroup;
                    setLocalGroups(d);
                    let newSelected = d.find(p => p.groupId === selected?.groupId);
                    selectGroup(newSelected);
                }
            })

    }
    function selectGroup(group: ChatGroupDTO | undefined) {
        setSelectedGroup(group ? group : null);
        if (group && group.messages)
            setMessages(group.messages);
        else
            setMessages(null);

        if (group && group.shown != true && group.recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).SaveShownChatMessages(group.recordId);
        }
    }
    return (
        <Accordion style={{ margin: '0px' }} className='expander' expanded={isExpanded} onChange={(e, ex) => { onExpandChange(ex); }}>
            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel1d-content" id="panel1d-header">
                <Money color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('subcontractor')}</Typography>
                {((messages && messages.length > 0) || (localGroups && localGroups.filter(p => p.messages && p.messages.length > 0).length > 0)) && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {localGroups !== null && localGroups.length > 0 && <Dropdown
                        position='top-right'
                        title=
                        {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto' }}>
                            <DvrIcon />
                            <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedGroup !== null ? selectedGroup.groupName + '(' + selectedGroup.diaryName + ')' : 'Vyberte skupinu'}</div></div>}
                    >
                        {localGroups.map((group) => {
                            return <Dropdown.Item key={group.groupId} onClick={(e) => selectGroup(group)}>
                                <div style={{ fontFamily: 'Roboto' }} > {group.groupName} {'(' + group.diaryName + ')'} {group.messages && group.messages.length > 0 && <Done sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />} {group.shown !== true && <Circle sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                            </Dropdown.Item>
                        })}

                    </Dropdown>}
                    {localGroups === null || localGroups.length === 0 && <div>{t('diary.noSubDiariesToday')} </div>}
                    {selectedGroup && <TextField placeholder='Zde můžete komunikovat v rámci denního záznamu.' InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Button variant='contained' startIcon={<Send />} onClick={() => {
                                    if (selectedGroup && selectedGroup.recordId) {
                                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PostSubcontractorsMessage(selectedGroup.recordId, newMessage).then((d) => {
                                            if (d) {
                                                reloadData();
                                                setNewMessage('');
                                            }
                                        });
                                    }
                                }}>
                                    {t('send')}
                                </Button>
                            </InputAdornment>
                        ),
                    }} sx={{ margin: '8px', width: '99%' }} multiline value={newMessage} onChange={(e) => {
                        setNewMessage(e.target.value);
                    }}></TextField>
                    }
                </div>
                <div>
                    {messages && <div>{messages.map((message) => {
                        return <div key={message.id} style={{ padding: '3px', borderRadius: '6px', margin: '6px 2px', background: !message.isMe ? 'lightgray' : '#F5F5F5' }}>
                            <div style={{
                                fontSize: '10px', fontWeight: 'bold', display: 'inline-block',
                                background: !message.isMe ? theme.palette.success.main : theme.palette.primary.main, marginLeft: '8px',
                                borderRadius: '4px'
                            }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '2px', wordWrap: 'normal', marginLeft: '8px' }}>{message.userName}</div>
                                    <div style={{ margin: '2px', marginRight: '8px' }}>{!message.isMe ? ' (Subdodavatel)' : ' (Uživatel ERP)'}</div>
                                    <div style={{ margin: '2px' }}>{message.datetime!.toLocaleDateString(i18n.language)}</div>
                                    <div style={{ margin: '2px', marginRight: '8px' }}>{message.datetime!.toLocaleTimeString(i18n.language)}</div>
                                </div>
                            </div>
                            <div style={{ whiteSpace: 'pre-wrap', maxHeight: '200px', overflowY: 'auto', padding: '2px', margin: '10px', fontSize: '12px' }}>{message.text}</div>
                        </div>;
                    })}</div>}
                </div>

            </AccordionDetails>
        </Accordion>
    );
}