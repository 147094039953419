import React, { DependencyList, SyntheticEvent, useCallback, useContext, useEffect, useState } from "react"
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useRef } from 'react';
import { APIUsersHelper } from "../../Api/ApiUsersHelper";
import { ApiException } from "../../Api/UsersMicroserviceClient";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Button, createTheme, Slide, Snackbar, TextField, ThemeProvider } from "@mui/material";
import "./LoginScene.css";
import Passwordbox from "../Shared/Passwordbox";
import LanguagePicker from "../Shared/LanguagePicker";
import { useParams } from "react-router";
import { StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useLocation } from "react-router";
import { APIVovKrosAdminHelper } from "../../ApiOld/ApiKrosAdminOldClient";


//mohlo by být jako partial state object, ale kvůli dvoum props se mi to dělat nechce

export default function LoginKrosAdminScene(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const loc = useLocation();

    let [userName, setUserName] = useState("");
    let [password, setPassword] = useState("");
    let [previousNavigation, setPreviousNavigation] = useState<string | undefined>(undefined);
    const [openLoginError, setOpenLoginError] = React.useState(false);

    useEffect(() => {
        if (localStorage.getItem('krosadmin_token_old') !== null && localStorage.getItem('krosadmin_token_old_expires') !== null) {
            let expires = new Date(localStorage.getItem('krosadmin_token_old_expires')!);
            if (expires > new Date()) {
                navigate({
                    pathname: '/sk/kros/admin/users'
                });
            }
            else {
                localStorage.removeItem('krosadmin_token_old');
                localStorage.removeItem('krosadmin_token_old_expires');
            }
        }
    }, []);


    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext);
    const CallApiWithLogin = async (e: any) => {
        // try {

        console.log('start auth');
        if (e)
            e.preventDefault();
        await new APIVovKrosAdminHelper().LoginKrosAdmin(userName, password).then((e) => {
            if (e) {
                console.log(e);
                if (previousNavigation === undefined)
                    navigate({
                        pathname: '/sk/kros/admin/users',
                    });
                else navigate({ pathname: previousNavigation });
            }

        }).catch(() => {
            setOpenLoginError(true);
        })


        // }
        // catch (ex) {
        //     if (ex instanceof ApiException) {
        //         console.log('jsem v chybě');
        //         setOpenLoginError(true);
        //     }
        //     else {

        //     }
        // }
    }
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const onTextChange = (event: any) => {
        setUserName(event.currentTarget.value);
    };
    const onPassChange = (event: string) => {
        setPassword(event);
    };
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    // if (authMode === "signin") {
    return (
        <div>
            <div className="Auth-form-container">
                <div className="Auth-form">
                    <div className="logo-up">
                        <img
                            width="200px"
                            height="54px"
                            src="https://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                            alt="stavario"
                        ></img>
                    </div>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title" >{t('login.signIn')}</h3>
                        <div className="text-center">
                            {t('login.notRegistredYet')}{" "}
                            <span style={{ color: '#FFC600' }} >
                                {t('login.signUp')}
                            </span>
                        </div>
                        <div className="form-group mt-3">

                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label={t('login.enterEmail')}
                                    className="text-field"
                                    value={userName}
                                    onChange={onTextChange}
                                    variant="outlined" />
                            </div>

                        </div>
                        <div className="form-group mt-3">

                            <Passwordbox onEnter={() => { console.log('enter'); CallApiWithLogin(undefined); }} password={password}
                                onPasswordChange={onPassChange}></Passwordbox>
                        </div>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <Button fullWidth onClick={(e) => { CallApiWithLogin(e) }} variant="contained" type="submit">
                                {t('login.sign')}
                            </Button>
                        </div>
                        {/* <div style={{ display: 'block', textAlign: 'center', width: '100%' }}>
                            <p style={{ marginTop: '26px' }}>
                                {t('login.forgot')} <a onClick={(e) => {
                                    navigate(
                                        { pathname: '/' + i18n.language + '/' + params['domain']! + '/subcontractor/resetpassword', },
                                        { state: { email: userName } }
                                    );
                                }} style={{ color: '#ffc107', textAlign: 'center', cursor: 'pointer' }}>{t('login.password')}</a>

                            </p>
                            <div style={{ marginTop: '-10px' }}>
                                <LanguagePicker showText={false} onLanguageChange={changeLanguage} />
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className="Auth-form-popup">
                <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={openLoginError}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                    <Alert onClose={handleClose} severity="warning">
                        <AlertTitle>{t('login.loginFailed')}</AlertTitle>
                        {t('login.loginFailedDetail')}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
    // }

    // return (
    //     <SignUp changeAuthMode={changeAuthMode}></SignUp>
    // )
}