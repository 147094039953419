import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward, ArrowUpward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import noDiaryPic from '../../../Assets/No_data.jpg';

const GridImageGallery = ({ imageUrls }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ paddingX: 4, pb: 2, border: '2px solid lightgray', borderRadius: '15px', background: 'white', marginX: 4, marginTop: 2 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, mt: 2 }} variant="h6">
                {t('UploadedPhotos')}
            </Typography>

            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                <Typography sx={{ fontSize: 14, color: '#888' }}>

                </Typography>
                <Box>
                    <IconButton>
                        <ArrowBack />
                    </IconButton>
                    <IconButton>
                        <ArrowForward />
                    </IconButton>
                    <IconButton>
                        <ArrowUpward />
                    </IconButton>
                </Box>
            </Box> */}

            <Box
                sx={{
                    marginY: 2,
                    maxHeight: 600,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 2,
                    overflowY: 'auto',
                    px: 2
                }}
            >
                {imageUrls !== undefined && imageUrls.length > 0 ? (
                    imageUrls.map((url, index) => (
                        <Box
                            key={index}
                            component="img"
                            src={url}
                            alt={`Image ${index}`}
                            sx={{
                                width: '100%',
                                height: 150,
                                borderRadius: 2,
                                objectFit: 'cover',
                            }}
                        />
                    ))
                ) : (
                    <img
                        src={noDiaryPic}
                        width={150}
                        height={100}
                        style={{
                            border: '2px solid',
                            borderColor: '#C3C3C3',
                            borderRadius: '16px',
                        }}
                    />
                )}
            </Box>
            <Typography sx={{ textAlign: 'right', fontSize: 14, color: '#888', mt: 2 }}>
                {t('TotalItems') + ": " + (imageUrls.length)}
            </Typography>
        </Box>
    );
};

export default GridImageGallery;
