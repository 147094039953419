
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Search, Sort, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import { useTheme } from '@mui/material';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import { WealthDTO } from '../../../ApiOld/ApiServerVov';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { LocalShipping } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIVovTaskHelper } from '../../../ApiOld/ApiOldTaskClient';

interface TasksListProps {

}

function TasksList(props: TasksListProps) {
    InitSfGridLocale();

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [tasks, setTasks] = useState<WealthDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const grid = useRef<GridComponent>(null);


    useEffect(() => {
        refreshData();
    }, []);


    function refreshData() {
        new APIVovTaskHelper().GetTasksForAdminUser(false).then((d) => {
            if (d.tasks !== undefined && d.tasks !== null)
                setTasks(d.tasks);
        });
    }
    function gotoDetail(id: number) {
        navigate(id.toString());
    }
    function GridTemplate(props: WealthDTO) {
        const [hover, setHover] = useState(false);
        return (<div style={{ cursor: 'pointer' }} onClick={(e) => { if (props.id) gotoDetail(props.id); }} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a style={{
                color: theme.palette.text.primary, textDecoration: hover ? 'underline' : 'none',
                fontWeight: props.wealthState !== undefined && props.wealthState === "rented" ? 'bold' : 'normal',

            }}

            >{props.name}</a>
        </div >);
    }


    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    function refresh() {
        new APIVovTaskHelper().GetTasksForAdminUser(false).then((r) => { if (r.tasks) setTasks(r.tasks); if (grid.current) grid.current.refresh(); });
    }

    if (tasks !== null)
        return (
            <div>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
                    <UpperMenu moduleName={t('investor.tasksList')}
                        gridType={GridType.Todos} gridId='grid-component-tasks-new'
                        canAdd
                        canTrash
                        onThrashClicked={() => { setTrashOpen(true); }}
                        canDelete
                        // onDelete={(e) => { new APIVovTaskHelper().deleteTasks(e as number[]).then((e) => { if (e) refresh(); }) }}
                        canChangeEyeView={false}
                        grid={grid} canChangeGroup canToolbarGrid
                        menuName={t('View')}
                        icon={<LocalShipping />}
                        canExcelExport
                    />
                    <GridComponent ref={grid} id="grid-component-tasks-new" allowSorting={true}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language} enablePersistence={true}
                        recordDoubleClick={(e) => { gotoDetail(e.data.id) }}
                        filterSettings={filterOptions}
                        allowPaging={true}
                        style={{ backgroundColor: '#F5F5F5' }}
                        pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                        toolbarClick={toolbarClickExcel}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={true} groupSettings={groupOptions}
                        dataSource={tasks}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width='20' toolTip='checkbox' />
                            <ColumnDirective field='id' width='30' headerText="ID" textAlign="Right" visible={false} toolTip='' />
                            <ColumnDirective field='status' headerText='xxx Stav' width='40' valueAccessor={(field: any, wealth: any) => {
                                return wealth[field] === 0 ? 'novy' : (wealth[field] === 1 ? 'resi se' : 'vyreseno');
                            }} />
                            <ColumnDirective template={GridTemplate} field='name' headerText={t('Name')} toolTip={t('Name')} />
                            <ColumnDirective field='buildingName' headerText='xxx Projekt' toolTip='' />
                            <ColumnDirective field='wealthName' headerText='xxx evidence majetku' toolTip='' />
                            <ColumnDirective field='authorName' headerText='xxx zadal' toolTip='' />
                            <ColumnDirective field='processingAdminUserName' headerText='xxx vyrizuje' toolTip='' />
                            <ColumnDirective field='employeeNames' headerText='xxx resitele' toolTip='' />

                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                    </GridComponent>
                    <TrashGridDialog trashType={TrashType.Wealths} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
                </div>

            </div>
        );
    return <div>{t('noRecords')}</div>;
};
export default TasksList;