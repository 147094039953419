import { BookOnlineOutlined, ConnectWithoutContact, ConstructionOutlined, Dashboard, FolderOpenOutlined, FolderOutlined, Group, HelpOutline, Home, MailOutline, Outbound, PrecisionManufacturingOutlined, QueryStatsOutlined, Report, Settings, SettingsOutlined, Task } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Drawer, DrawerHeader } from './SideMenuStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation, useNavigate, useParams } from 'react-router';
import { StavarioModule } from './StavarioModule';
import ModuleMenuItem from './ModuleMenuItem';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useTranslation } from 'react-i18next';
import LayoutUserMenu from './LayoutUserMenu';
import UserAvatar from './UserAvatar';
interface DrawerHeaderProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  onModuleChange?: (module: StavarioModule) => void;
  selectedModule: StavarioModule;
  onChangeView: () => void;
}

export default function UserModuleLayoutSideMenu(props: Readonly<DrawerHeaderProps>) {
  const { isDrawerOpen, setDrawerOpen, onChangeView, selectedModule } = props;
  // const [selectedModule, setSelectedModule] = useState<StavarioModule>(Stava);

  // useEffect(() => {
  //   onModuleChange?.(selectedModule);
  // }, [selectedModule, onModuleChange]);
  // const [open, setOpen] = React.useState(false);

  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };
  const navigate = useNavigate();
  const params = useParams();
  const { initials } = useUserLayoutContext();
  const { t } = useTranslation();
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const DrawerList = (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='calc(100% - 64px)'>
      <Box>
        <List>
          <ModuleMenuItem label={t('Projekty')} route='projects' moduleType={StavarioModule.ProjectList} selectedModule={selectedModule} selectableModules={[StavarioModule.ProjectDetail, StavarioModule.ProjectList]} />

          <ListItem key={'dashboard'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/dashboard/employees'} onClick={() => {

              navigate('projects');
              // else window.location.href = 'https://stavariokluc.vimovsem.cz/admin/wealth/';
            }}>
              <ListItemIcon>
                {<Dashboard />}
              </ListItemIcon>
              <ListItemText primary={t('Dashboard')} />
            </ListItemButton>
          </ListItem>
          <ModuleMenuItem label={t('todo.log.building_diary_id')} route='diary/diaryvov' moduleType={StavarioModule.DiaryRecordsList} selectedModule={selectedModule} selectableModules={[StavarioModule.DiaryRecordsList, StavarioModule.DiaryRecordDetail, StavarioModule.DiariesList, StavarioModule.DiaryDetail]} />
          <ListItem key={'emps'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/employees'}>
              <ListItemIcon>
                {<Group />}
              </ListItemIcon>
              <ListItemText primary={t('employees')} />
            </ListItemButton>
          </ListItem>
          {/*  <ModuleMenuItem label={t('Majetek')} route='wealths' moduleType={StavarioModule.WealthList} selectedModule={selectedModule} selectableModules={[StavarioModule.WealthList, StavarioModule.WealthDetail]} />
 */}
          <ListItem key={'wealths'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/wealth'} >
              <ListItemIcon>
                {<PrecisionManufacturingOutlined />}
              </ListItemIcon>
              <ListItemText primary={t('Majetek')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'tasks'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/requests'} >
              <ListItemIcon>
                {<Task />}
              </ListItemIcon>
              <ListItemText primary={t('todo.tasks')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'warehouses'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/stock-warehouses'} >
              <ListItemIcon>
                {<Home />}
              </ListItemIcon>
              <ListItemText primary={t('dashboard.warehouse')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'reports'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/building-hours/'} >
              <ListItemIcon>
                {<QueryStatsOutlined />}
              </ListItemIcon>
              <ListItemText primary={t('menu.reports')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'documents'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/document-templates/'} >
              <ListItemIcon>
                {<FolderOutlined />}
              </ListItemIcon>
              <ListItemText primary={t('Documents')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'settings'} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/settings/'} >
              <ListItemIcon>
                {<SettingsOutlined />}
              </ListItemIcon>
              <ListItemText primary={t('Settings')} />
            </ListItemButton>
          </ListItem>
          {/* ))} */}
        </List>
      </Box>
      <Box>
        <UserAvatar handleAvatarClick={handleAvatarClick} />
        <LayoutUserMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          onChangeView={onChangeView}

        />
      </Box>
    </Box>
  );


  return (

    <Drawer variant='permanent' PaperProps={{ sx: { marginTop: '64px' } }} open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>

      {DrawerList}
    </Drawer>
  );

}
