import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Grid, Snackbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { APIOldBuildingsClient } from '../../../../ApiOld/ApiOldBuildingsClient';
import { FileDirectoryDTO } from '../../../../ApiOld/ApiServerVov';
import ProjectDocumentTreeScene from './ProjectDocumentTreeScene';
import ProjectDocumentDetailScene from './ProjectDocumentDetailScene';
import { Split } from 'ts-react-splitter';
import SelectFolderHolder from '../../../Shared/Layouts/SelectFolderHolder';
import { useMediaPredicate } from 'react-media-hook';
import { t } from 'i18next';
import { CopyPasteProvider } from '../Contexts/CopyPasteContext';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';

interface ProjectDocumentsSceneProps {
    projectId: number;
}

export default function ProjectDocumentsScene(props: ProjectDocumentsSceneProps) {
    const { projectId } = props;
    const [files, setFiles] = React.useState<FileDirectoryDTO[] | null>(null);
    const [selected, setSelected] = React.useState<FileDirectoryDTO>();
    const [refresh, setRefresh] = React.useState(false);

    const [error, setError] = useState('');
    const [splitPercent, setSplitPercent] = useState<number>(30);

    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    useEffect(() => { refreshTree(); }, []);

    useEffect(() => { if (refresh) setRefresh(false); }, [refresh]);

    useEffect(() => {
        if (files && selected && files.some(p => p.id === selected.id)) {
            setSelected(files.find(p => p.id === selected.id));
        }
    }, [files]);

    const refreshTree = async () => {
        if (projectId) {
            new APIOldBuildingsClient().GetProjectFilesTree(projectId).then(p => {
                if (!selected)
                    setSelected(p[0]);
                setFiles(p);
            });
        } else {
            setFiles([]);
        }

        setRefresh(true);
    };

    if (files) {
        return (
            <div style={{ margin: '10px' }}>
                <CopyPasteProvider>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={12}>
                            <div style={{ zIndex: '30', textAlign: 'left', border: '1px solid lightgray', borderRadius: '10px', padding: '4px' }}>
                                <ProjectDocumentTreeScene setFiles={setFiles} onRefresh={refreshTree} selected={selected} files={files} onSelect={(selected) => {
                                    setSelected(selected);
                                }} projectId={projectId} />
                            </div>
                        </Grid>
                        <Grid item lg={8} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid item lg={12} xs={12} style={{ flexGrow: 1 }}>
                                {selected ? (
                                    <div style={{ height: '100%', zIndex: '30', textAlign: 'left', overflow: 'hidden' }}>
                                        <ProjectDocumentDetailScene onRefresh={refreshTree} onSave={() => refreshTree()} selectedDocumentId={selected.id} refresh={refresh} projectId={projectId} />
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '100%' }}>
                                        <SelectFolderHolder title={t('files.chooseFolder')} />
                                    </div>
                                )}
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Snackbar open={error !== ""} autoHideDuration={3000} onClose={() => setError("")}>
                                    <Alert onClose={() => setError("")} severity="error" sx={{ width: '100%' }}>
                                        {error}
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        </Grid>
                    </Grid>
                </CopyPasteProvider>
            </div>
        );
    } else {
        return <CircularProgress color="secondary" size={16} />;
    }
}
