import { useEffect, useRef, useState } from "react";
import { GroupAdminDTO, SubDiaryDTO } from "../../../../../ApiOld/ApiServerVov";
import InitSfRTBLocale from "../../../../../Localization/SFRichEditLanguageIntializer";
import { ChangeEventArgs, HtmlEditor, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from "@syncfusion/ej2-react-richtexteditor";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { useMediaPredicate } from "react-media-hook";
import { useLoginAdminStore } from "../../../../../Contexts/LoginAdminZContext";
import { useDiaryCompletedWorksCopyStore } from "../../../../../Contexts/DiaryCompletedWorksCopyZContext";
import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, Grid, Slide, Snackbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, ContentCopy, Edit, ListAlt, People } from "@mui/icons-material";
import Dropdown from "react-multilevel-dropdown";
import HtmlConcurrencyViewerDialog from "../HtmlConcurrencyViewerDialog";
import GptChatSceneInTab from "../../../GptChat/GptChatSceneInTab";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface CompletedWorksWidgetSceneProps {
    buildingDiaryRecordId: number;
    completedWorksInput?: string;
    onHtmlChanged?: (html: string, privContent?: GroupAdminDTO | null) => void;
    subdiaries?: SubDiaryDTO[];
    privateContents?: GroupAdminDTO[];
    showSubdiaries?: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    concurrency?: string;
    copyCompletedWork: string;
}

InitSfRTBLocale();
export default function CompletedWorksWidgetScene(props: CompletedWorksWidgetSceneProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, completedWorksInput, onHtmlChanged, subdiaries, showSubdiaries, isCopying, isInvestor, isSubdodavatel, concurrency, privateContents, copyCompletedWork } = props;
    const [completedWorks, setCompletedWorks] = useState<string>('');
    const [completedWorksConcurrency, setCompletedWorksConcurrency] = useState<string | undefined>();
    const [completedWorksCommonBackup, setCompletedWorksCommonBackup] = useState<string>('');
    const [selectedSubdiary, setSelectedSubdiary] = useState<SubDiaryDTO | null>(null);
    const [localSubdiaries, setLocalSubdiaries] = useState<SubDiaryDTO[] | null>(null);

    const [selectedPrivateContent, setSelectedPrivateContent] = useState<GroupAdminDTO | null>(null);
    const [localPrivateContents, setLocalPrivateContents] = useState<GroupAdminDTO[] | null>(null);

    const [sharedRecordSelected, setSharedRecordSelected] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [concurrencyOpen, setConcurrencyOpen] = useState<boolean>(false);
    const [aiHelperOpen, setAiHelperOpen] = useState<boolean>(false);
    const [concurrencyOpenErrorInfo, setConcurrencyOpenErrorInfo] = useState<boolean>(false);
    const theme = useTheme();
    const { permissions } = useDiaryTabsContext();
    const { completedWorkStore } = useDiaryCompletedWorksCopyStore();
    const { userId, load } = useLoginAdminStore();

    useEffect(() => {
        //reloadData();
    }, [buildingDiaryRecordId]);

    useEffect(() => {
        if (onHtmlChanged && copyCompletedWork !== '') {
            onHtmlChanged(completedWorksCommonBackup + "<p>" + copyCompletedWork + "</p>");
        }
    }, [copyCompletedWork]);




    useEffect(() => {
        if (!userId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetUser().then(v => {
                if (v)
                    load(v.userName!, v.userId!);
            })
        }

    }, []);

    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    function reloadData() {
        if (isCopying) {
            setCompletedWorks(completedWorkStore!);
        }
        else {
            if (buildingDiaryRecordId)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetCompletedWorksRecord(buildingDiaryRecordId).then((d) => {
                    if (d !== undefined)
                        setCompletedWorks(d);
                    else
                        setCompletedWorks('');
                });
        }
    }
    useEffect(() => {
        // reloadData();
        if (/* buildingDiaryRecordId &&  */completedWorksInput !== undefined) {
            setCompletedWorks(completedWorksInput);
            setCompletedWorksCommonBackup(completedWorksInput);
        }
        else {
            setCompletedWorks('');
            setCompletedWorksCommonBackup('');
        }

    }, [completedWorksInput]);
    useEffect(() => {
        setSelectedSubdiary(null);
        setSelectedPrivateContent(null);
        //setSelectedPrivateContent(null);
        setSharedRecordSelected(true);

    }, [completedWorksInput]);
    useEffect(() => {
        setCompletedWorksConcurrency(concurrency);
        if (concurrency && concurrency.length > 0) {
            setConcurrencyOpenErrorInfo(true);
        }
    }, [concurrency]);
    useEffect(() => {
        if (subdiaries)
            setLocalSubdiaries(subdiaries);
    }, [subdiaries]);
    useEffect(() => {
        if (privateContents)
            setLocalPrivateContents(privateContents);

        if (permissions?.subcontractorsViewOnlyTheirPage && privateContents && privateContents.some(p => p.userId === userId)) {
            selectPrivateContent(privateContents.find(p => p.userId === userId)!);
        }
    }, [privateContents]);

    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }
    function selectSubdiary(subdiary: SubDiaryDTO) {
        if (subdiary.diaryId !== 0) {
            setSharedRecordSelected(false);
            setSelectedPrivateContent(null);
            setSelectedSubdiary(subdiary);
            if (subdiary.content)
                setCompletedWorks(subdiary.content);
            else
                setCompletedWorks('');
        }
        else {
            setSharedRecordSelected(true);
            setSelectedSubdiary(null);
            setCompletedWorks('');
        }
    }
    function selectPrivateContent(content: GroupAdminDTO) {
        if (content.contentId !== 0) {
            setSharedRecordSelected(false);
            setSelectedPrivateContent(content);
            setSelectedSubdiary(null);
            if (content.content)
                setCompletedWorks(content.content);
            else
                setCompletedWorks('');
        }
        else {
            setSharedRecordSelected(true);
            setSelectedPrivateContent(null);
            setCompletedWorks('');
        }
    }

    function isReadOnlyEditor(): boolean {
        if ((selectedPrivateContent !== null && selectedPrivateContent.userId === userId && permissions && permissions.subcontractorsViewOnlyTheirPage === true) || (sharedRecordSelected && permissions && permissions.writeCommonCompletedWorks)) {
            return false;
        }
        return true;
    }

    function isVisibleEditor(): boolean {
        if (!sharedRecordSelected || (sharedRecordSelected && permissions && (permissions.readCommonCompletedWorks || permissions.writeCommonCompletedWorks)) || isInvestor === true) {
            return true;
        }
        return false;
    }

    function countLines(text: string): number {
        const lines = text !== null ? text.split('<p>') : [];
        return lines.length;
    }

    const rtb = useRef<RichTextEditorComponent>(null);
    return (
        <Box>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                        value="end"
                        sx={{ marginBottom: '14px' }}
                        control={<Checkbox checked={sharedRecordSelected} sx={{ marginLeft: '10px', marginRight: '-6px', }} onChange={(e) => {
                            if (!sharedRecordSelected) {
                                setSharedRecordSelected(true);
                                setCompletedWorks(completedWorksCommonBackup);
                                setSelectedSubdiary(null);
                                setSelectedPrivateContent(null);
                            }

                        }} icon={<People color='action' />} checkedIcon={<People color='primary' />} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: sharedRecordSelected ? theme.palette.primary.main : theme.palette.secondary.main }}>{t('diary.sharedEntry')}</Typography>}
                        labelPlacement="end"
                    />

                    {showSubdiaries && localSubdiaries && (isInvestor === undefined || isInvestor === false) && isSubdodavatel !== true && <Dropdown
                        position='top-right'
                        title=
                        {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto' }}>
                            <ListAlt />
                            <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedSubdiary !== null ? selectedSubdiary.diaryName : t('diary.PickSubRecord')}</div></div>}
                    >
                        {localSubdiaries.map((subd) => {
                            return <Dropdown.Item key={subd.diaryId} onClick={(e) => selectSubdiary(subd)}>
                                <div style={{ fontFamily: 'Roboto' }} >{subd.diaryName} {subd.content !== undefined && subd.content !== '' && <Check sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                            </Dropdown.Item>
                        })}

                    </Dropdown>}
                    <div style={{ width: '10px' }}></div>
                    {showSubdiaries && localPrivateContents && (isInvestor === undefined || isInvestor === false) && isSubdodavatel !== true && <Dropdown
                        position='top-right'
                        title=
                        {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto' }}>
                            <ListAlt />
                            <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedPrivateContent !== null ? selectedPrivateContent.name : t('diary.PickPrivateRecord')}</div></div>}
                    >
                        {localPrivateContents.map((cont) => {
                            return <Dropdown.Item key={cont.contentId} onClick={(e) => selectPrivateContent(cont)}>
                                <div style={{ fontFamily: 'Roboto' }} >{cont.name} {cont.content !== undefined && cont.content !== '' && <Check sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                            </Dropdown.Item>
                        })}

                    </Dropdown>}
                    {(selectedSubdiary !== null || selectedPrivateContent !== null) && <Button onClick={(e) => {
                        if (onHtmlChanged)
                            onHtmlChanged(completedWorksCommonBackup + completedWorks);
                        // backu(completedWorks + rtb.current!.getHtml());
                    }} sx={{ marginLeft: '8px', marginBottom: '18px' }} startIcon={<ContentCopy />} disabled={!(permissions && permissions.writeCommonCompletedWorks)}>{t('diary.copyToShared')}</Button>}
                </div>
                {completedWorksConcurrency !== undefined && <Button sx={{ marginLeft: '10px', marginBottom: '19px' }} variant='contained' startIcon={<Edit />} onClick={(e) => { setConcurrencyOpen(true) }}>{biggerThan700 ? 'Zobrazit změnené zápisy' : ''}</Button>}

            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {isVisibleEditor() && !isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions && permissions.writeCommonCompletedWorks)) && <RichTextEditorComponent
                        style={{ minHeight: '400px', maxHeight: '400px', borderRadius: '10px', overflow: 'hidden' }}
                        saveInterval={50}
                        readOnly={true}
                        ref={rtb} actionBegin={(e) => {
                            if (e.type === 'drop' || e.type === 'dragstart') {
                                e.cancel = true;
                            }
                        }}
                        toolbarSettings={toolbarSettings} height={(completedWorks !== null && completedWorks.length > 500) || countLines(completedWorks) > 10 ? 600 : 200} locale={i18n.language} value={completedWorks} change={(eve: ChangeEventArgs) => {
                            setCompletedWorks(eve.value);
                            if (selectedSubdiary === null && selectedPrivateContent === null) {
                                setCompletedWorksCommonBackup(eve.value);
                                if (onHtmlChanged)
                                    onHtmlChanged(eve.value);
                            }
                            else {
                                if (onHtmlChanged)
                                    onHtmlChanged(eve.value, selectedPrivateContent);
                            }

                        }} >
                        <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
                    </RichTextEditorComponent>}
                    {isVisibleEditor() && isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions && permissions.writeCommonCompletedWorks)) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                    {!isVisibleEditor() && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} >{t('noViewingPermissions')}</div>}
                    {isVisibleEditor() && sharedRecordSelected && (permissions === undefined || permissions!.writeCommonCompletedWorks === undefined || permissions!.writeCommonCompletedWorks === false) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ width: '100%' }}>
                        <GptChatSceneInTab onCopy={(text) => {

                            if (onHtmlChanged)
                                onHtmlChanged(completedWorksCommonBackup !== null ? completedWorksCommonBackup + '<p>' + text + '</p>' : '' + '<p>' + text + '</p>');
                        }} /></div>
                </Grid>
            </Grid>

            <Snackbar
                TransitionComponent={(propsy) => { return <Slide {...propsy} direction='left' /> }}
                open={concurrencyOpenErrorInfo}
                autoHideDuration={5000}
                onClose={(e) => { setConcurrencyOpenErrorInfo(false) }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                <Alert onClose={(e) => { setConcurrencyOpenErrorInfo(false) }} severity="warning">
                    <AlertTitle>Data has changed</AlertTitle>
                    Data před Vámi změnil jiný uživatel, můžete se podívat na změny a eventuelně opětovně uložit
                </Alert>
            </Snackbar>
            <HtmlConcurrencyViewerDialog open={concurrencyOpen} onClose={() => { setConcurrencyOpen(false) }} myHtml={completedWorks} concurrencyHtml={completedWorksConcurrency} />
        </Box>
    )
}