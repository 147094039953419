import { AddAPhoto, Download, Photo, PhotoCamera } from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { ImageDiaryDTO } from '../../../ApiOld/ApiServerVov';
import withLoading, { WithLoadingProps } from '../../Shared/Loader';
import ImageWindowScroller from './ImageWindowScrollerScene';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, Grid, InputLabel, Typography, useTheme } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';
import InvestorUploadPhotoDialog from './InvestorUploadPhotoDialog';
import saveBlob from '../../Shared/Files/SaveBlob';

interface InvestorRenderBuildingsPhotosProps extends WithLoadingProps {
    buildingId?: number,
    buildingName?: string,
    onNewRequest: (value: ImageDiaryDTO) => void;
}
function InvestorRenderBuildingsPhotos(props: InvestorRenderBuildingsPhotosProps) {
    const { buildingId, buildingName, onNewRequest, fireLoading, stopLoading } = props;
    const [images, setImages] = React.useState<ImageDiaryDTO[]>([]);
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = React.useState(true);
    const [uploadOpen, setUploadOpen] = React.useState(false);

    const [downloading, setDownloading] = React.useState(false);

    useEffect(() => {
        refreshImages()
    }, []);

    useEffect(() => {
        refreshImages()
    }, [buildingId]);

    function setDefaultState() {
        if (buildingId !== undefined)
            setRecordId(buildingId);
        setImages([]);
    }
    function refreshImages() {
        setDefaultState();
        //fireLoading!();
        new APIVovInvestorHelper().GetImagesInvestor(false, buildingId === undefined ? 0 : buildingId).then((data) => {
            console.log(data);
            if (data)
                setImages(data);
            stopLoading!();

        }).catch(() => { stopLoading!(); console.log('err'); });
    }

    return (<div style={{ maxWidth: biggerThan700 ? '100%' : '600px', margin: '12px', marginTop: '32px' }}>
        <Accordion className='expander' expanded={expanded} onChange={() => { setExpanded(!expanded) }}>
            <AccordionSummary className='expander-header' aria-controls="panel8d-content" id="panel8d-header">
                <PhotoCamera color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold'>{buildingName}</Typography>
                <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + images.length + ')'}</Typography>
                <Button disabled={!buildingId} sx={{ marginLeft: '20px', marginTop: '-2px', height: '24px' }}
                    onClick={(e) => {

                        e.preventDefault();
                        e.stopPropagation();
                        setUploadOpen(true);
                    }}
                    variant='contained' size='small' startIcon={biggerThan700 ? <AddAPhoto /> : undefined}>{biggerThan700 ? t('files.addPhotos') : <AddAPhoto sx={{ height: '20px' }} />}</Button>
                <Button disabled={downloading} sx={{ marginLeft: '10px', marginTop: '-2px', height: '24px' }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (buildingId !== undefined) {
                        setDownloading(true);
                        new APIVovInvestorHelper().GetBuildingImageZip(buildingId).then((d) => {
                            if (d) {
                                saveBlob(d.data, buildingName + "_images.zip");
                            }
                            setDownloading(false);
                        });

                    }
                }} variant='contained' size='small' startIcon={biggerThan700 ? <Download /> : undefined}>{biggerThan700 ? t('downloadAll') : <Download sx={{ height: '20px' }} />}</Button>
                {downloading && <CircularProgress size={'24px'} sx={{ marginLeft: '10px', marginTop: '-2px' }} />}
            </AccordionSummary>
            <AccordionDetails>
                {images !== undefined && <ImageWindowScroller onNewRequest={(e) => { onNewRequest(e) }}
                    buildingId={buildingId!} onDelete={(file) => {
                        setImages(images.filter(x => x.fileName !== file.fileName));
                    }} canDelete={true} images={images}></ImageWindowScroller>}
            </AccordionDetails>
            {buildingId && <InvestorUploadPhotoDialog open={uploadOpen} onClose={function (refresh: boolean): void {
                setUploadOpen(false);
                if (refresh)
                    refreshImages();
            }} buildingId={buildingId} />}
        </Accordion>
    </div>);
}
export default withLoading(InvestorRenderBuildingsPhotos);