import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AddCircleOutline, AttachFile, Cloud, Construction, DriveFileRenameOutline, Engineering, Forum, History, LocalShipping, Money, PhotoCamera, RateReview, Search, Widgets } from '@mui/icons-material';
import './Styles/DiaryMainScene.css'
import { useLocation, useNavigate, useParams } from 'react-router';
import { GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDiaryRecordDataNotTablesResponse, GroupAdminDTO, GroupDTO, RequestsDTO, SubDiaryDTO } from '../../ApiOld/ApiServerVov';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../Scenes/DiaryComponents/Styles/DiaryMainScene.css';
import { useMediaPredicate } from 'react-media-hook';
import { unstable_usePrompt } from 'react-router-dom';
import { Timeline } from './DiaryComponents/Widgets/Timeline';
import { DiaryTimelineModel } from './DiaryComponents/Models/DiaryTimelineModel';
import moment from 'moment';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import NoDataImageHolder from '../Shared/Layouts/NoDataImageHolder';
import DiarySearchDialog from './DiaryComponents/DiarySearchDialog';
import DiaryRecordCopyDialog from './DiaryComponents/DiaryRecordCopyDialog';
import ReallyLockUnlockDialog from './DiaryComponents/Widgets/ReallyLockUnlockDiaryRecord';
import DiaryPrintDialog from './DiaryComponents/DiaryPrints/DiaryPrintDialog';
import SelectGroupDialog from './DiaryComponents/Widgets/SelectGroupDialog';
import { ApiDiaryBaseHelper } from '../../Api/ApiDiaryBaseHelper';
import CreateUserDialog from './DiaryComponents/Widgets/CreateUserDialog';
import saveBlob from '../Shared/Files/SaveBlob';
import InvestorTaskCreateDialog from '../Investor/Components/InvestorTaskCreateDialog';
import MessageDialog from '../Shared/MessageDialog';
import SignaturesUserInfoDialog from './DiaryComponents/Signatures/SignaturesUserInfoDialog';
import DiaryUsersPrintDialog from './DiaryComponents/DiaryPrints/DiaryUsersPrintDialog';
import { useUserLayoutContext } from '../../Contexts/Providers/UserLayoutMenuProvider';
import DailyRecordMenuButtons from './DiaryComponents/Menu/DailyRecordMenuButtons';
import StyledTabs, { TabItem } from '../Shared/Tabs/StyledTabs';
import { TabPanel } from '../Shared/Tabs/TabPanel';
import SignaturesWidgetScene from './DiaryComponents/Widgets/Scenes/SignaturesWidgetScene';
import PhotoWidgetScene from './DiaryComponents/Widgets/Scenes/PhotoWidgetScene';
import AttachmentsWidgetScene from './DiaryComponents/Widgets/Scenes/AttachmentsWidgetScene';
import WeatherWidgetScene from './DiaryComponents/Widgets/Scenes/WeatherWidgetScene';
import WorkersWidgetScene from './DiaryComponents/Widgets/Scenes/WorkersWidgetScene';
import MaterialsWidgetScene from './DiaryComponents/Widgets/Scenes/MaterialsWidgetScene';
import MachinesWidgetScene from './DiaryComponents/Widgets/Scenes/MachinesWidgetScene';
import CompletedWorksWidgetSceneOld from './DiaryComponents/Widgets/Scenes/CompletedWorksWidgetSceneOld';
import CompletedWorksWidgetScene from './DiaryComponents/Widgets/Scenes/CompletedWorksWidgetScene';
import TasksInDiaryWidgetScene from './DiaryComponents/Widgets/Scenes/TasksInDiaryWidgetScene';
import InvestorChatWidgetScene from './DiaryComponents/Widgets/Scenes/InvestorChatWidgetScene';
import LogWidgetScene from './DiaryComponents/Widgets/Scenes/LogWidgetScene';
import AnotherNotesWidgetScene from './DiaryComponents/Widgets/Scenes/AnotherNotesWidgetScene';
import ReusableTabs from './InvoiceComponents/ReusableTabs';
import MainContractorChatWidgetScene from './DiaryComponents/Widgets/Scenes/MainContractorChatWidgetScene';
import HorizontalScrollImageGallery from './DiaryComponents/HorizontalScrollImageGallery';
import { useApi } from '../../ApiOld/Api/useApi';
import { DiaryCommonVovClient, SubcontractorVovClient } from '../../ApiOld/Api/ApiServerVov';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';


interface DiaryMainSceneProps extends WithLoadingProps {
    buildingDiaryRecordIdProps?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal?: boolean;
    displayAsTabs?: boolean;
    setDisplayAsTabs?: (e: boolean) => void;
}

function DiaryMainScene(props: DiaryMainSceneProps) {
    const [photosUrl, setPhotosUrl] = useState<string[]>([]);
    const completedWorks = React.useRef<string | undefined>();
    const [completedWorksConcurrency, setCompletedWorksConcurrency] = React.useState<string | undefined>();
    const [materials, setMaterials] = React.useState<string | undefined>();
    const [machines, setMachines] = React.useState<string | undefined>();
    const [weatherFallback, setWeatherFallback] = React.useState<string | undefined>();
    const [workersFallback, setWorkersFallback] = React.useState<string | undefined>();
    const [notes, setNotes] = React.useState<string | undefined>();
    const [buildingName, setBuildingName] = React.useState<string | undefined>();
    const [buildingId, setBuildingId] = React.useState<number | undefined>();
    const [diaryName, setDiaryName] = React.useState<string | undefined>();
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [diaryId, setDiaryId] = React.useState<number | undefined>(undefined);
    const [subcontractorPrintId, setSubcontractorPrintId] = React.useState<number | undefined>(undefined);
    const [saveGrids, setSaveGrids] = React.useState<boolean>(false);
    const [printOpen, setPrintOpen] = React.useState<boolean>(false);
    const [refreshLog, setRefreshLog] = React.useState<boolean>(false);
    const [saveButonDisabled, setSaveButonDisabled] = React.useState<boolean>(false);
    const [refreshSignatures, setRefreshSignatures] = React.useState<boolean>(false);
    const [reloadTasks, setReloadTasks] = React.useState<boolean>(false);
    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [dataToSaveChanged, setDataToSaveChanged] = React.useState<boolean>(false);
    const [workersChanged, setWorkersChanged] = React.useState<boolean>(false);
    const [weathersChanged, setWeathersChanged] = React.useState<boolean>(false);
    const [materialsChanged, setMaterialsChanged] = React.useState<boolean>(false);
    const [machinesChanged, setMachinesChanged] = React.useState<boolean>(false);
    const [signDigitally, setSignDigitally] = React.useState<boolean>(false);
    const [printOnlyMine, setPrintOnlyMine] = React.useState<boolean>(false);
    const [subcontractorDialogOpen, setSubcontractorDialogOpen] = React.useState<boolean>(false);
    const [isDataValid, setIsDataValid] = React.useState<boolean>(true);
    const [showDashboard, setShowDashboard] = React.useState<boolean>(false);
    const [subdiaries, setSubdiaries] = React.useState<SubDiaryDTO[]>([]);
    const groups = React.useRef<GroupDTO[]>([]);
    const privateContents = React.useRef<GroupAdminDTO[]>([]);
    const [showGroups, setShowGroups] = React.useState<boolean>(true);
    const [showWorkHours, setShowWorkHours] = React.useState<boolean>(true);
    const [saveTimeStamp, setSaveTimeStamp] = React.useState<number | undefined>(undefined);
    const [searchText, setSearchText] = React.useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [investorNewTaskOpen, setInvestorNewTaskOpen] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [locked, setLocked] = useState(false);
    const [readOnlySubcontractor, setReadOnlySubcontractor] = useState(false);
    const [records, setRecords] = useState<DiaryTimelineModel[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>();
    const [refresh, setRefresh] = useState(false);
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [printOthersDiaryOpen, setPrintOthersDiaryOpen] = useState<boolean>(false);
    const [copyCompletedWork, setCopyCompletedWork] = useState<string>('');
    const [printing, setPrinting] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isSigniDialogOpen, setIsSigniDialogOpen] = useState(false);
    const { buildingDiaryRecordIdProps, fireLoading, stopLoading, isInvestor, fromExternal, isSubdodavatel, displayAsTabs, setDisplayAsTabs } = props;
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");

    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    const { authorizedApi } = useApi(DiaryCommonVovClient);
    const { authorizedSubdodavatelApi } = useApi(SubcontractorVovClient);
    const beforeUnLoad = (e: any) => {
        if (dataChanged) {
            return e.returnValue = 'Really want to leave?';
        }
    }

    useEffect(() => {
        if (dataToSaveChanged || workersChanged || weathersChanged || materialsChanged || machinesChanged)
            setDataChanged(true);
        else setDataChanged(false);
    }, [dataToSaveChanged, workersChanged, weathersChanged, machinesChanged, materialsChanged]);

    useEffect(() => {
        if (!dataChanged || locked || readOnlySubcontractor) {
            setWorkersChanged(false);
            setWeathersChanged(false);
            setMaterialsChanged(false);
            setMachinesChanged(false);
            setDataToSaveChanged(false);
        }
        else {
            window.addEventListener('beforeunload', beforeUnLoad);
            return () => {
                window.removeEventListener('beforeunload', beforeUnLoad);
            };
        }

    }, [dataChanged]);

    unstable_usePrompt({ message: 'Opravdu chcete opustit stránku bez uložení?', when: dataChanged });

    function handleStateFullLoad(): DiaryTimelineModel[] | undefined {
        setRecordId(loc.state["id"]);
        setBuildingName(loc.state["buildingName"]);
        setDiaryName(loc.state["diaryName"]);
        setDiaryId(loc.state["diaryId"]);
        if (loc.state["inputRecords"] !== undefined && loc.state["inputRecords"] !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            loc.state["inputRecords"].reverse().forEach((element: any) => {
                if (element.diaryId === loc.state["diaryId"]) {
                    newRecords.push({
                        date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                        hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                        hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                    });
                }
            });
            return newRecords;
        }
    }
    const loadDataStatelessTimeline = (id: number, d: GetDailyRecordsForUserAndDiaryByRecordIdResponse) => {
        setRecordId(id);
        setBuildingName(d.buildingName);
        setDiaryId(d.diaryId);
        setDiaryName(d.diaryName);
        setCurrentDate(d.diaryDate);
        if (d.viewerRecords !== undefined && d.viewerRecords !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            d.viewerRecords.reverse().forEach((element: any) => {
                newRecords.push({
                    date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                    hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                    hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                });
            });
            setRecords(newRecords);
        }
    }
    const handleStateLessLoad = useCallback((id: number) => {
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetRecordsForViewingStateless(id).then((d) => {
            loadDataStatelessTimeline(id, d);
        });
    }, [isInvestor, isSubdodavatel]);

    useEffect(() => {
        if (saveGrids)
            setSaveGrids(false);
    }, [saveGrids]);

    useEffect(() => {
        if (subcontractorPrintId) {
            setSignDigitally(false);
            setPrintOnlyMine(false);
            setPrintOpen(true);
        }
    }, [subcontractorPrintId]);

    const navigateBack = () => {
        if (isSubdodavatel === true) {
            navigate('/' + i18n.language + '/' + params['domain']! + '/subcontractor/main/diaries');
        } else if (isInvestor === undefined || isInvestor === false) {
            navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/diary/diaryvov');
        } else {
            navigate('/' + i18n.language + '/' + params['domain']! + '/investor/tabs/diary');
        }
    }
    const { refreshDailyRecords, permissions, refreshPermissions, investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();
    useEffect(() => {
        setBackMenuFunction(() => () => { navigateBack(); })
        fireLoading!();

        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);

        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }

        if (permissions === null && (isInvestor === undefined || isInvestor === false))
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
        else if (!investorPermissions && (isInvestor === true))
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (buildingDiaryRecordIdProps !== undefined)
            setRecordId(buildingDiaryRecordIdProps);
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));
        }
    }, [buildingDiaryRecordIdProps, loc, params]);


    function reloadData() {
        fireLoading!();
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }
        if (permissions === undefined && (isInvestor === undefined || isInvestor === false))
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
        else if (!investorPermissions && (isInvestor === true))
            refreshInvestorPermissions();
    }

    function setDefaultState() {
        setSaveTimeStamp(0);
        completedWorks.current = undefined;
        setMaterials('');
        setMachines('');
        setWeatherFallback('');
        setWorkersFallback('');
        setNotes('');
        setSubdiaries([]);
        groups.current = [];
        privateContents.current = [];
    }
    const getContentsToSave = useCallback((): SubDiaryDTO[] | GroupAdminDTO[] | undefined => {
        if (isSubdodavatel !== true && (subdiaries === null || subdiaries.length === 0)) {
            let admins = groups.current.filter(a => a !== undefined).flatMap(a => a.members!);
            return admins.filter(x => x !== undefined && x.changed);
        } else {
            let admins = privateContents.current.filter(a => a !== undefined).map(a => a);
            return admins.filter(x => x !== undefined && x.changed);
        }
    }, [isSubdodavatel, subdiaries, groups, privateContents]);

    function setDataAfterReload(data: GetDiaryRecordDataNotTablesResponse) {
        setSelectedDate(data.date);
        setLocked(data.isLocked!);
        setReadOnlySubcontractor(data.isReadOnly ? data.isReadOnly : false);
        setSaveTimeStamp(data.timeStamp);
        setBuildingId(data.buildingId);
        completedWorks.current = data.completedWork;
        setMaterials(data.materials);
        setMachines(data.machines);
        setWeatherFallback(data.weather);
        setWorkersFallback(data.workers);
        setNotes(data.additionalNotes);
        if (data.subdiaries)
            setSubdiaries(data.subdiaries);
        if (data.groups)
            groups.current = data.groups;
        if (data.privateContents)
            privateContents.current = data.privateContents;
        if (data.showWorkHours !== undefined)
            setShowWorkHours(data.showWorkHours);
        else setShowWorkHours(false);
        if (data.showEmployeesGroup !== undefined)
            setShowGroups(data.showEmployeesGroup);
        else setShowGroups(false);

        setRefreshSignatures(true);
    }

    const refreshDiary = useCallback(() => {
        setIsDataValid(true);
        setCopyCompletedWork('');
        fireLoading!();
        setDefaultState();
        if (recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetFullDailyRecordNotTables(recordId, false).then((data) => {
                stopLoading!();
                setDataAfterReload(data);
            }).catch(() => { stopLoading!(); setIsDataValid(false); });
        }
        else stopLoading!();
    }, [fireLoading, isInvestor, isSubdodavatel, recordId, stopLoading]);

    const loadTodayPhotos = useCallback(() => {
        if (recordId) {
            isSubdodavatel !== true ?
                authorizedApi.getPhotosBuildingToChoose(recordId, true).then((imgs) => {
                    if (imgs.images) {
                        const filteredPhotos: string[] = imgs.images
                            .map((e) => e.url) as string[];

                        setPhotosUrl(filteredPhotos);
                    }
                })
                    .catch((e) => {
                        setPhotosUrl([]);
                    })
                :
                authorizedSubdodavatelApi.getPhotosBuildingToChooseSubcontractor(recordId, true).then((imgs) => {
                    if (imgs.images) {
                        const filteredPhotos: string[] = imgs.images
                            .map((e) => e.url) as string[];

                        setPhotosUrl(filteredPhotos);
                    }
                })
                    .catch((e) => {
                        setPhotosUrl([]);
                    });
        }
    }, [recordId, isSubdodavatel, authorizedApi, authorizedSubdodavatelApi]);

    useEffect(() => {
        refreshDiary();
    }, [recordId]);

    useEffect(() => {
        if (recordId)
            loadTodayPhotos();
    }, [recordId, loadTodayPhotos]);

    const saveDiary = useCallback((navigateAfter: boolean) => {
        setDataChanged(false);
        setDataToSaveChanged(false);
        setSaveGrids(true);
        if (recordId && saveTimeStamp !== undefined) {
            setLoading(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveDiaryContents(recordId, saveTimeStamp, completedWorks.current, machines, materials, notes, getContentsToSave()).then((e) => {
                if (isSubdodavatel === true) {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter)
                            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries');
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                    refreshDailyRecords();
                }
                else {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter) {
                            if (fromExternal === undefined || fromExternal === false)
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvov');
                            else
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnew');
                        }
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                    refreshDailyRecords();
                }
                setRefreshLog(refreshLog == true ? false : true);
                setSaveButonDisabled(false);
                setLoading(false);
            }).catch(() => { setSaveButonDisabled(false); setLoading(false); });
        }
    }, [fromExternal, getContentsToSave, handleStateLessLoad, isInvestor, isSubdodavatel, machines, materials, navigate, notes, params, recordId, refreshDiary, refreshLog, saveTimeStamp, refreshDailyRecords]);

    const theme = useTheme();


    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            setSearchOpen(true);
        }
    }

    const handleMenuItemClick = useCallback((event: number) => {
        if (event === 0) {
            setLockDialogOpen(true);
        }
        else if (event === 1) {
            setCopyOpen(true);
        }
        else if (event === 4) {
            if (isSubdodavatel === true) {
                setCreateUserOpen(true);
            }
            else if (recordId) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).RingOthersDiary(recordId);
            }
        }
        else if (event === 5) {
            setCreateUserOpen(true);
        }
    }, [recordId, isSubdodavatel, isInvestor]);

    function searchClosed() { setSearchOpen(false); }
    function createUserClosed() { setCreateUserOpen(false); }
    function copyClosed(refresh: boolean) { setCopyOpen(false); if (refresh) { reloadData(); } setRefresh(true); }
    useEffect(() => {
        if (refresh)
            setRefresh(false);
    }, [refresh]);

    const onTiskInvestorClick = useCallback((e: number) => {
        if (!recordId)
            return;
        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }
        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK CELÉHO DENÍKU
        else if (e === 2) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU PRO DIG PODPIS
        else if (e === 3) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, true, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, true, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, true, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU S PŘÍLOHAMI
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, true).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, true, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, true, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
    }, [diaryId, diaryName, isInvestor, isSubdodavatel, recordId, selectedDate]);

    const onTiskUserClick = useCallback((e: number) => {
        if (!recordId)
            return;

        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }

        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 2) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 3) {
            setPrintOthersDiaryOpen(true);
        }
        // TISK CELEHO DENIKU
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }

        }
        else if (e === 5) {
            setIsSigniDialogOpen(true);
        }
    }, [recordId, diaryId, diaryName, isInvestor, isSubdodavatel, selectedDate]);

    const onTiskClick = useCallback((e: number) => {
        if (isInvestor === true)
            onTiskInvestorClick(e);
        else onTiskUserClick(e);
    }, [isInvestor, onTiskInvestorClick, onTiskUserClick]);

    useEffect(() => {
        setMenuButtons(<DailyRecordMenuButtons
            permissions={permissions}
            investorPerm={investorPermissions}
            handleMenuItemClick={handleMenuItemClick}
            isInvestor={isInvestor ?? false}
            saveDiary={saveDiary}
            locked={locked}
            readOnlySubcontractor={readOnlySubcontractor}
            loading={loading}
            saveButtonDisabled={saveButonDisabled}
            isSubdodavatel={isSubdodavatel ?? false}
            onTiskClick={onTiskClick}
            printing={printing}
            buildingName={buildingName}
            diaryName={diaryName}
            setSearchOpen={setSearchOpen}
            setSearchText={setSearchText}
            handleKeyDown={handleKeyDown}
            displayAsTabs={displayAsTabs ?? false}
            setDisplayAsTabs={setDisplayAsTabs ?? (() => { })} />);
    }, [permissions, buildingName, diaryName, isInvestor, isSubdodavatel, loading, locked, printing, displayAsTabs, readOnlySubcontractor, saveButonDisabled, setDisplayAsTabs, setMenuButtons, handleMenuItemClick, onTiskClick, saveDiary]);

    const tabs = useMemo(() => [
        {
            icon: <Construction style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.completedWorks'),
            panel: <Box sx={{ marginX: '40px', background: 'white', padding: '30px', borderRadius: '15px' }}>
                {isSubdodavatel === false && (subdiaries === null || subdiaries.length === 0) ?
                    recordId === undefined ? <></> :
                        <CompletedWorksWidgetSceneOld
                            copyCompletedWork={copyCompletedWork}
                            concurrency={completedWorksConcurrency}
                            isInvestor={isInvestor}
                            isCopying={false}
                            showGroups={true}
                            buildingDiaryRecordId={recordId} groups={groups.current}
                            onHtmlChanged={(text, group, member) => {

                                if (group === null || group === undefined || member === null || member === undefined) {
                                    if (text !== completedWorks.current)
                                        setDataToSaveChanged(true);
                                    completedWorks.current = text;
                                }
                                else {
                                    let groupNew = groups.current.find(x => x.groupId === group.groupId);
                                    if (groupNew && groupNew.members) {
                                        let memberRemove = groupNew.members.find(x => x.userId === member.userId);

                                        if (memberRemove) {
                                            if (text !== memberRemove.content)
                                                setDataToSaveChanged(true);
                                            let gruopToUse = new GroupDTO({
                                                groupId: groupNew.groupId, groupName: groupNew.groupName,
                                                members: [...groupNew.members!.filter(x => x.userId !== member.userId), new GroupAdminDTO({ content: text, name: memberRemove!.name, userId: memberRemove!.userId, contentId: memberRemove!.contentId, changed: true })]
                                            })
                                            groups.current = [...groups.current.filter(x => x.groupId !== group.groupId), gruopToUse];
                                        }
                                    }

                                }
                            }}
                            completedWorksInput={completedWorks.current} />
                    :
                    recordId === undefined ? <></> :
                        <CompletedWorksWidgetScene
                            copyCompletedWork={copyCompletedWork}
                            concurrency={completedWorksConcurrency}
                            isInvestor={isInvestor}
                            isSubdodavatel={isSubdodavatel}
                            isCopying={false}
                            showSubdiaries={true}
                            buildingDiaryRecordId={recordId} subdiaries={subdiaries} privateContents={privateContents.current}
                            onHtmlChanged={(text, privContent) => {
                                if (privContent === null || privContent === undefined) {
                                    if (text !== completedWorks.current)
                                        setDataToSaveChanged(true);
                                    completedWorks.current = text;
                                }
                                else if (privContent !== null && privContent !== undefined) {
                                    let contNew = privateContents.current.find(x => x.contentId === privContent.contentId);
                                    if (contNew) {
                                        if (text !== contNew.content)
                                            setDataToSaveChanged(true);

                                        let contToUse = new GroupAdminDTO({
                                            contentId: contNew.contentId, name: contNew.name,
                                            content: text, userId: contNew.userId,
                                            changed: true
                                        })
                                        privateContents.current = [...privateContents.current.filter(x => x.contentId !== privContent.contentId), contToUse]
                                    }
                                }
                            }}
                            completedWorksInput={completedWorks.current} />
                }
                <HorizontalScrollImageGallery imageUrls={photosUrl} />
            </Box>
        },
        {
            icon: <DriveFileRenameOutline style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.signatures'),
            panel: <>
                <SignaturesWidgetScene isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} recordId={recordId} />
            </>
        },
        {
            icon: <PhotoCamera style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('dashboard.photos'),
            panel: <>
                <PhotoWidgetScene isInvestor={isInvestor}
                    isSubdodavatel={isSubdodavatel}
                    isLocked={locked || readOnlySubcontractor}
                    recordId={recordId}
                    domain={params['domain']}
                    recordDate={currentDate}
                    onCopy={(text) => { setCopyCompletedWork(text); }}
                />
            </>
        },
        {
            icon: <AttachFile style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.attachments'),
            panel: <>
                <AttachmentsWidgetScene isInvestor={isInvestor}
                    isSubdodavatel={isSubdodavatel}
                    isLocked={locked || readOnlySubcontractor}
                    recordId={recordId}
                />
            </>
        },
        ...recordId !== undefined ? [{
            icon: <Cloud style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.weather'),
            panel: <>
                <WeatherWidgetScene
                    date={selectedDate}
                    isInvestor={isInvestor}
                    isSubdodavatel={isSubdodavatel}
                    onSaved={() => { setWeathersChanged(false); setRefreshLog(refreshLog === true ? false : true); }}
                    onSaveChanged={() => { setWeathersChanged(true) }}
                    isLocked={locked || readOnlySubcontractor}
                    saveChanged={saveGrids}
                    buildingDiaryRecordId={recordId}
                    weatherNote={weatherFallback}
                    noteChanged={(e) => {
                        setDataToSaveChanged(true);
                        setWeatherFallback(e);
                    }}
                />
            </>
        }] : [],
        {
            icon: <Engineering style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.workers'),
            panel: <>
                <WorkersWidgetScene
                    isInvestor={isInvestor}
                    isSubdodavatel={isSubdodavatel}
                    buildingName={buildingName}
                    date={selectedDate}
                    isLocked={locked || readOnlySubcontractor}
                    isCopying={false}
                    workersNote={workersFallback}
                    noteChanged={(e) => {
                        setDataToSaveChanged(true);
                        setWorkersFallback(e);
                    }}
                    onChanged={() => { setWorkersChanged(false); }}
                    onChangeToSave={() => { setWorkersChanged(true); }}
                    onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                    saveChanged={saveGrids}
                    buildingDiaryRecordId={recordId}
                    showGroups={showGroups}
                    showWorkHours={showWorkHours}
                    refresh={refresh}></WorkersWidgetScene>
            </>
        },
        {
            icon: <Widgets style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.materials'),
            panel: <>
                <MaterialsWidgetScene
                    currentDate={currentDate ? currentDate : new Date()}
                    buildingDiaryId={diaryId}
                    isInvestor={isInvestor}
                    isSubdodavatel={isSubdodavatel}
                    isLocked={locked || readOnlySubcontractor}
                    saveChanged={saveGrids}
                    isCopying={false}
                    materialsNote={materials}
                    onChanged={() => { setMaterialsChanged(false); }}
                    onChangeToSave={() => { setMaterialsChanged(true) }}
                    onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                    noteChanged={(e) => {
                        setDataToSaveChanged(true);
                        setMaterials(e);
                    }}
                    buildingDiaryRecordId={recordId}
                    refresh={refresh}></MaterialsWidgetScene>
            </>
        },
        {
            icon: <LocalShipping style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.machines'),
            panel: <MachinesWidgetScene isInvestor={isInvestor}
                isSubdodavatel={isSubdodavatel}
                isLocked={locked || readOnlySubcontractor}
                noteChanged={(e) => {
                    setDataToSaveChanged(true);
                    setMachines(e);
                }}
                onChanged={() => { setMachinesChanged(false); }}
                onChangeToSave={() => { setMachinesChanged(true) }}
                onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                saveChanged={saveGrids}
                isCopying={false}
                machinesNote={machines}
                buildingDiaryRecordId={recordId}
                refresh={refresh}></MachinesWidgetScene>
        },
        {
            icon: <RateReview style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.anotherNotes'),
            panel: <AnotherNotesWidgetScene isInvestor={isInvestor}
                investorPerm={investorPermissions}
                notes={notes}
                setDataToSaveChanged={(e) => { setDataToSaveChanged(e) }}
                setNotes={(e) => { setNotes(e) }}
            ></AnotherNotesWidgetScene>
        },
        ...isSubdodavatel !== true && recordId ? [{
            icon: <Forum style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('dashboard.tasks'),
            panel: <TasksInDiaryWidgetScene isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} domain={params['domain']} recordId={recordId} />
        }] : [],
        ...((((permissions && permissions.investorsComments) || (isInvestor)) && isSubdodavatel !== true) && recordId
            ? [{
                icon: <Money style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
                label: t('Investor'),
                panel: <InvestorChatWidgetScene recordIdInput={recordId} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />
            }]
            : []),
        ...(isSubdodavatel === true) && recordId ? [{
            icon: <Money style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('MainContractor'),
            panel: <MainContractorChatWidgetScene recordIdInput={recordId} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />
        }] : [],
        ...(isInvestor !== true && isSubdodavatel !== true && permissions && permissions.canSeeChangelog) ? [{
            icon: <History style={{ color: 'secondary', width: biggerThan700 ? '16px' : '24px' }} />,
            label: 'Log',
            panel: <LogWidgetScene refresh={refreshLog} buildingDiaryRecordId={recordId} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />
        }] : [],
    ], [biggerThan700, buildingName, completedWorksConcurrency, copyCompletedWork, currentDate, diaryId, investorPermissions, isInvestor, isSubdodavatel, locked, machines, materials, notes, params, permissions, photosUrl, readOnlySubcontractor, recordId, refresh, refreshLog, saveGrids, selectedDate, showGroups, showWorkHours, subdiaries, t, weatherFallback, workersFallback]);

    return (
        <div>
            {recordId && <SignaturesUserInfoDialog isInvestor={isInvestor === undefined ? false : isInvestor} diaryRecordId={recordId} isOpen={isSigniDialogOpen} onClose={() => { setIsSigniDialogOpen(false) }} />}
            {/* <AlertDialog isBlocking={dataChanged} /> */}
            <div style={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light }}>

                <Box sx={{
                    minHeight: '40px', my: 2, zIndex: 5000, marginX: 6,
                }}>
                    <Timeline disabled={dataChanged} inputRecordId={recordId}
                        isInvestor={isInvestor !== undefined && isInvestor === true}
                        isSubdodavatel={isSubdodavatel === undefined ? false : isSubdodavatel}
                        onSelected={(e, d) => {
                            fireLoading!();
                            if (e)
                                if (isSubdodavatel)
                                    navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries/' + e);
                                else
                                    navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvovdetail/' + e);
                            else {
                                setRecordId(e);
                                setSelectedDate(d);
                                stopLoading!();
                            }
                        }}
                        inputRecords={records}
                        fromExternal={fromExternal} />

                </Box>
                {recordId !== undefined &&
                    <div style={{ padding: '8px' }}>
                        <ReusableTabs
                            tabsMarginX={4}
                            variant='scrollable' tabs={tabs.filter(Boolean) as TabItem[]} />
                    </div >}

                {
                    recordId === undefined &&
                    <div style={{
                        display: 'flex', justifyContent: 'center', marginTop: '120px',
                        flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                    }}>
                        <NoDataImageHolder title={t('diary.noRecordForChosenDate')} />
                        <div style={{ display: 'flex' }}><Typography>{t('diary.site')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {buildingName}</Typography></div>
                        <div style={{ display: 'flex' }}><Typography>{t('diary.diary')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {diaryName}</Typography></div>

                        {permissions && permissions.canCreateDiaryRecord && <Button sx={{ margin: '20px' }} startIcon={<AddCircleOutline />} onClick={(e) => {
                            if (diaryId && selectedDate)
                                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).createDiaryRecord(diaryId, selectedDate).then((d) => {
                                    if (typeof d === "string") {
                                    }
                                    else if (typeof d === "number") {
                                        if (isSubdodavatel === true)
                                            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries/' + d);
                                        else
                                            navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvovdetail/' + d);
                                        /* setRecordId(d);
                                        handleStateLessLoad(d); */
                                        //refresh timeline
                                        // setRecords([...records.filter(x => x.dateOrigin === selectedDate), new ])
                                    }
                                })
                        }} variant='contained'>{t('Create')}</Button>}
                    </div>
                }
                {
                    !isDataValid && <div style={{
                        display: 'flex', justifyContent: 'center', marginTop: '120px',
                        flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                    }}><NoDataImageHolder title='Data jsou chybná' /></div>
                }
                {recordId && <DiaryPrintDialog isInvestor={isInvestor !== undefined && isInvestor === true} isSubdodavatel={isSubdodavatel} subcontractorPrintId={subcontractorPrintId}
                    signDigitally={signDigitally} onlyMine={printOnlyMine}
                    recordId={recordId} open={printOpen} onClose={() => { setPrintOpen(false); setSubcontractorPrintId(undefined); }} />}
                <DiarySearchDialog isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onRefresh={() => { }} searchText={searchText} open={searchOpen} onClose={searchClosed} diaryId={diaryId} diaryDate={undefined} fromExternal={fromExternal === true ? true : false} />
                <SelectGroupDialog groups={groups.current} open={subcontractorDialogOpen} onClose={(e) => {
                    setSubcontractorDialogOpen(false);
                    if (e)
                        setSubcontractorPrintId(e);
                }} />
                <DiaryRecordCopyDialog isSubdodavatel={isSubdodavatel} open={copyOpen} diaryId={diaryId} onClose={copyClosed} onRefresh={() => { }} diaryRecordId={recordId} />
                <CreateUserDialog showGroups={true} diaryId={diaryId} isSubdodavatel={isSubdodavatel} open={createUserOpen} onClose={createUserClosed} />
                <ReallyLockUnlockDialog open={lockDialogOpen} locked={locked} onClose={(e) => {
                    setLockDialogOpen(false);
                    if (recordId && e === true) {
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).lockUnlockDiaryRecord(recordId, !locked).then((d) => { setLockDialogOpen(false); refreshDiary(); });
                    }
                }} />
                <InvestorTaskCreateDialog topMargin={200} onRefresh={() => { /* setRefresh(true)  */ }} todo={new RequestsDTO({ buildingId: buildingId, buildingDiaryId: diaryId })} open={investorNewTaskOpen} selectedValue={t('singleTodo.newTask')} onClose={() => { setInvestorNewTaskOpen(false); refreshDiary(); setReloadTasks(true); }} />
                <MessageDialog onClose={() => setMessageOpen(false)} text={t('diary.diaryPrintLargeText')} title={t('diary.diaryPrintLargeTitle')} open={messageOpen} />
                {diaryId && <DiaryUsersPrintDialog diaryId={diaryId} onClose={(e) => {
                    setPrintOthersDiaryOpen(false);
                    if (e !== undefined && e !== null && recordId) {
                        setPrinting(true);
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, e.id!).then((d) => {
                            if (d) {
                                let formattedDate = "";
                                if (selectedDate) {
                                    formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
                                }
                                saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                            }
                            setPrinting(false);
                        });

                    }
                }
                } open={printOthersDiaryOpen} />}
            </div >
        </div >
    );
}
export default withLoading(DiaryMainScene);
