import { useTranslation } from "react-i18next";
import { EmployeeGroupDTO, EmployeeInGroupDTO, GetWorkerForRecordDTO, SaveWorkerReqDTO } from "../../../../../ApiOld/ApiServerVov";
import { useEffect, useRef, useState } from "react";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { ColumnChooser, ColumnDirective, ColumnsDirective, Edit, EditSettingsModel, ExcelExport, Filter, GridComponent, Group, IEditCell, Inject, Reorder, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import { useMediaPredicate } from "react-media-hook";
import { useDiaryWorkersCopyStore } from "../../../../../Contexts/DiaryWorkersCopyZContext";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import tabProps from "../../../../Shared/Tabs/TabProps";
import { TabPanel } from "../../../../Shared/Tabs/TabPanel";
import standardFilterOptions from "../../../../Shared/Grids/StandardFilterOptions";
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import EmployeesWithGroupsDialog from "../../../Employees/EmployeesWithGroupsChooseDialog";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface WorkersWidgetSceneProps {
    buildingDiaryRecordId: number | undefined;
    workersInput?: GetWorkerForRecordDTO[];
    workersNote?: string;
    noteChanged?: (t: string) => void;
    onChanged?: (d: GetWorkerForRecordDTO[]) => void;
    onChangeToSave?: () => void;
    onSaved?: () => void;
    saveChanged?: boolean;
    isCopying: boolean;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    buildingName?: string;
    date?: Date;
    showWorkHours: boolean;
    showGroups: boolean;
    refresh?: boolean;
}

export default function WorkersWidgetScene(props: WorkersWidgetSceneProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, workersInput, workersNote, noteChanged, onChanged, saveChanged,
        isCopying, isLocked, isInvestor, isSubdodavatel, buildingName, date, onChangeToSave, showGroups, showWorkHours, onSaved, refresh } = props;
    const [workers, setWorkers] = useState<GetWorkerForRecordDTO[]>();
    const [copying, setCopying] = useState<boolean>(false);
    const [openEmployeesDialog, setOpenEmployeesDialog] = useState<boolean>(false);
    const theme = useTheme();
    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };
    const toolbarOptions: object[] = [{ text: t('Add'), id: 'grid-component-workers_add', prefixIcon: 'e-add' },
    { text: t('Delete'), id: 'grid-component-workers_delete', prefixIcon: 'e-delete' },
    { text: t('save'), id: 'grid-component-workers_update', prefixIcon: 'e-update' },
    { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-workers_cancel' },
    { text: t('diary.AddWorkers'), tooltipText: 'Načíst osoby se systému', prefixIcon: 'e-expand', id: 'loadWorkersFromSystem' }];
    const toolbarOptionsNoDelete: object[] = [{ text: t('Add'), id: 'grid-component-workers_add', prefixIcon: 'e-add' },
    { text: t('save'), id: 'grid-component-workers_update', prefixIcon: 'e-update' },
    { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-workers_cancel' },
    { text: t('diary.AddWorkers'), tooltipText: 'Načíst osoby se systému', prefixIcon: 'e-expand', id: 'loadWorkersFromSystem' }];

    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { permissions } = useDiaryTabsContext();
    const { workersStore } = useDiaryWorkersCopyStore();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [workersNoteLocal, setWorkersNoteLocal] = useState<string | undefined>();

    const saving = useRef<boolean>(false);

    function reloadData() {
        if (isCopying) {
            setWorkers(workersStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined) {
                setWorkers([]);
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetWorkersForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (grid.current)
                        grid.current!.dataSource = d;
                    setWorkers(d);
                    saving.current = false;
                });
            }
            else
                saving.current = false;
        }
    }

    useEffect(() => {
        if (isLocked) {
            if (document.getElementById('grid-component-workers_add'))
                document.getElementById('grid-component-workers_add')!.className += ' e-disabled';
            if (document.getElementById('grid-component-workers_delete'))
                document.getElementById('grid-component-workers_delete')!.className += ' e-disabled';
            if (document.getElementById('grid-component-workers_update'))
                document.getElementById('grid-component-workers_update')!.className += ' e-disabled';
            if (document.getElementById('grid-component-workers_cancel'))
                document.getElementById('grid-component-workers_cancel')!.className += ' e-disabled';
            if (document.getElementById('loadWorkersFromSystem'))
                document.getElementById('loadWorkersFromSystem')!.className += ' e-disabled';
        }
    }, [isLocked]);

    function toolBarClick(e: any) {
        if (isLocked) {
            e.cancel = true;
            return;
        }
        let id = e.item.properties.id;
        if (id === 'loadWorkersFromSystem')
            setOpenEmployeesDialog(true);
    }
    function clickHandler(e: any) {
        let gr = 'grid-component-workers';

        let instance = (document.getElementById(gr) as HTMLElement);
        if (instance && (e.target as HTMLElement).classList.contains("e-rowcell")) {

            //@ts-ignore
            let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));
            console.log((e.target as HTMLElement));
            //@ts-ignore
            let colindex: number = parseInt((e.target as HTMLElement).getAttribute("data-colindex"));
            //@ts-ignore
            let field: string = instance.ej2_instances[0].getColumns()[colindex].field;
            grid.current!.editCell(index - 1, field);
        };
    }
    const loaded = useRef(false);
    const singleClickLoad = (id: string): void => {
        //@ts-ignore
        let instance = (document.getElementById(id) as HTMLElement);
        if (instance) {
            if (!loaded.current) {
                instance.removeEventListener('mouseup', clickHandler);
                instance.addEventListener('mouseup', clickHandler);
                loaded.current = true;
            }
        }
    }

    useEffect(() => {
        setWorkers(workersStore);

    }, [workersStore]);


    useEffect(() => {
        if (refresh === true)
            reloadData();
    }, [refresh]);

    useEffect(() => {
        if (grid.current !== null) {
            showGroups ? grid.current.showColumns(t('diary.GroupName')) : grid.current.hideColumns(t('diary.GroupName'));
        }
    }, [showGroups]);

    useEffect(() => {
        if (grid.current !== null) {
            showWorkHours ? grid.current.showColumns(t('diary.hoursHeadings')) : grid.current.hideColumns(t('diary.hoursHeadings'));
        }
    }, [showWorkHours]);

    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);

    useEffect(() => {
        if (workersInput) {
            setWorkers(workersInput);
        }
    }, [workersInput]);

    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        setWorkersNoteLocal(workersNote);
    }, [workersNote]);
    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);
    const grid = useRef<GridComponent>(null);

    function beforeDeleteAsk(e: any) {
        // e.cancel = true;
    }
    function save(e: any) {
        e.cancel = true;
        if (saving.current) return;
        saving.current = true;
        if (!copying && buildingDiaryRecordId) {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new SaveWorkerReqDTO({ id: 0, name: add.name, code: add.code, hours: add.workHours, isShared: add.isShared, groupName: add.groupName })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new SaveWorkerReqDTO({ id: chang.id, name: chang.name, code: chang.code, hours: chang.workHours, isShared: chang.isShared, groupName: chang.groupName })
            });
            let deleted = permissions?.canDeleteInsideRecords === true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new SaveWorkerReqDTO({ id: -del.id, name: del.name, code: del.code, hours: del.workHours })
            }) : [];
            let b: SaveWorkerReqDTO[] = [...added, ...changed, ...deleted];
            if (buildingDiaryRecordId)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveWorkers(buildingDiaryRecordId, b).then((r) => { if (r) reloadData(); else saving.current = false; if (onSaved) onSaved(); })
            if (onChanged)
                onChanged(b);
        }
        else {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new GetWorkerForRecordDTO({ id: 0, name: add.name, code: add.code, workHours: add.workHours, isShared: add.isShared })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new GetWorkerForRecordDTO({ id: chang.id, name: chang.name, code: chang.code, workHours: chang.workHours, isShared: chang.isShared })
            });
            let deleted = permissions?.canDeleteInsideRecords == true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new GetWorkerForRecordDTO({ id: -del.id, name: del.name, code: del.code, workHours: del.workHours })
            }) : [];
            let b: GetWorkerForRecordDTO[] = [...added, ...changed, ...deleted];
            if (onChanged)
                onChanged(b);
            saving.current = false;
        }
    }
    const getDefaultData = (e: any) => {
        e.defaultData.id = 0;
        e.defaultData.name = '';
        e.defaultData.workHours = 8;
        e.defaultData.authorName = '';
        e.defaultData.groupName = '';
        e.defaultData.isShared = true;
        e.defaultData.code = '';
    }
    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    const setDefaultData = (e: any) => {
        e.defaultData.id = 0;
        e.defaultData.name = '';
        e.defaultData.workHours = 8;
        e.defaultData.groupName = '';
        e.defaultData.authorName = '';
        e.defaultData.isShared = true;
        e.defaultData.code = '';
    }
    const codeRules: object = { required: false, maxLength: 6 };

    return (
        <Box sx={{ marginX: '40px', background: 'white', borderRadius: '15px', overflow: 'hidden', minHeight: 500 }}>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => {
                        setSelectedTabIndex(n);
                    }} aria-label="basic tabs example">
                        <Tab label={t('table')} {...tabProps(0)} />
                        {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <GridComponent
                        style={{ borderColor: 'transparent' }} id="grid-component-workers"
                        allowSorting={true}
                        beforeBatchDelete={(e) => { beforeDeleteAsk(e) }}
                        beforeBatchAdd={(e) => { setDefaultData(e) }}
                        beforeBatchSave={(e) => { save(e) }}
                        ref={grid}
                        load={(e) => { singleClickLoad("grid-component-workers") }}
                        editSettings={editSettings}
                        toolbar={(permissions && permissions.canEditDiaryRecord && !isLocked) ? (permissions.canDeleteInsideRecords ? toolbarOptions : toolbarOptionsNoDelete) : undefined}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        toolbarClick={toolBarClick}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        resizeSettings={{ mode: 'Normal' }}
                        selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                        allowFiltering={false}
                        cellEdit={(e) => { if (onChangeToSave) onChangeToSave() }}
                        dataSource={workers}

                    >
                        <ColumnsDirective>
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                            <ColumnDirective field='code' width='100' headerText={t('log.code')} />
                            <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='groupName' headerText={t('diary.GroupName')} maxWidth='400' width='200' allowEditing={false} />
                            <ColumnDirective field='workHours' editType='numericedit' maxWidth='200' width='100' textAlign='Right'
                                edit={numericParams}
                                headerText={t('diary.hoursHeadings')} format='N2' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='200' />
                            <ColumnDirective field='authorName' headerText={t('CreatedBy')} maxWidth='800' allowEditing={false} width='300' minWidth='200' />
                            <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' />
                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        <RichTextEditorComponent actionBegin={(e) => { }} height={400} locale={i18n.language} value={workersNoteLocal} change={(eve: ChangeEventArgs) => {
                            if (isSubdodavatel !== true) {
                                setWorkersNoteLocal(eve.value);
                                if (noteChanged)
                                    noteChanged(eve.value);
                            }
                        }} >
                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                </TabPanel>
            </Box>
            <EmployeesWithGroupsDialog
                isSubdodavatel={isSubdodavatel}
                buildingName={buildingName}
                date={date !== undefined ? date.toLocaleDateString(i18n.language) : ''}
                open={openEmployeesDialog}
                recordId={buildingDiaryRecordId}
                onClose={(emps) => {
                    console.log(emps);
                    if (emps && buildingDiaryRecordId) {
                        let array = (emps as EmployeeGroupDTO[]);
                        let newWorkers = array.map((e) => { if (e.employees) return e.employees.filter(x => x.isSelected !== undefined && x.isSelected !== false).map((emp) => { return emp }) });
                        let newWorkers2 = newWorkers.flat().filter(x => x !== undefined).map((e) => { return e as EmployeeInGroupDTO });
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).AddEmpsToDiary(buildingDiaryRecordId, newWorkers2).then((r) => {
                            if (r) reloadData();
                            setOpenEmployeesDialog(false);
                        });
                    }
                    else setOpenEmployeesDialog(false);
                }} />
        </Box>
    );
}