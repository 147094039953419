


import React, { ReactNode, RefObject, useEffect, useState } from 'react';
import { AddCircleOutline, Delete, Download, Edit, PivotTableChartOutlined, RemoveCircle, RemoveRedEye, Search, SearchOutlined, Settings, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography, inputLabelClasses } from "@mui/material";
import { Column, GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-react-grids';

import DeleteDialog from './DeleteDialog';
import { useTranslation } from 'react-i18next';
import delay from '../Functions/Delay';
import { GridSettingsDTO, GridType } from '../../../Api/UsersMicroserviceClient';
import { APIUsersHelper } from '../../../Api/ApiUsersHelper';
import EditGridViewsDialog from './EditGridViewsDialog';
import { useMediaPredicate } from 'react-media-hook';
import DiarySearchDialog from '../../Scenes/DiaryComponents/DiarySearchDialog';
import { ExcelExportIcon } from '../ExcelExportIcon';

export interface UpperMenuProps {
    moduleName: string;
    grid: RefObject<GridComponent>;
    menuName?: string;
    items?: selecteMenuItemData[];
    gridType: GridType;
    gridId?: string;
    onCreate?: (event: any) => void;
    onDelete?: (event: any) => void;
    onThrashClicked?: (event: any) => void;
    onSettings?: (event: any) => void;
    onViewChange?: (event: number) => void;
    canChangeEyeView?: boolean;
    canChangeGroup?: boolean;
    canToolbarGrid?: boolean;
    canDelete?: boolean;
    canTrash?: boolean;
    canAdd?: boolean;
    icon?: ReactNode;
    canAddSecond?: boolean;
    addLabel?: string;
    addSecondLabel?: string;
    canDiarySearch?: boolean;
    onCreateSecond?: (event: any) => void;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal?: boolean;
    backgroundColor?: string;
    searchLabel?: string;
    canExcelExport?: boolean;
}
export interface selecteMenuItemData {
    caption: string;
    data: string | number | undefined;
}
export default function UpperMenu(props: UpperMenuProps, ref: any) {
    const [showClearFilter, setShowClearFilter] = useState<boolean>(false);
    const { moduleName, onCreate, onDelete, items, onViewChange, onSettings, grid, menuName, gridType, canChangeEyeView,
        canChangeGroup, canToolbarGrid, icon, canDelete, canAdd, onThrashClicked, canTrash, canAddSecond, onCreateSecond, addSecondLabel, addLabel, gridId, canDiarySearch, isInvestor, isSubdodavatel, fromExternal, backgroundColor, searchLabel, canExcelExport } = props;
    const [view, setView] = React.useState<number>(1);
    const [gridConfig, setGridConfig] = React.useState<number>(1);
    const [cantDelete, setCantDelete] = React.useState(true);
    const [viewGridEditing, setViewGridEditing] = React.useState(false);
    const [gridStateActual, setGridStateActual] = React.useState<GridSettingsDTO | null>(null);
    const [gridState, setGridState] = React.useState<GridSettingsDTO | null>(null);
    const [gridStateOptions, setGridStateOptions] = React.useState<GridSettingsDTO[] | null>(null);
    const [pivotEnabled, setPivotEnabled] = React.useState(false);
    const [toolbarEnabled, setToolbarEnabled] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [gridSettingDialogOpen, SetGridSettingDialogOpen] = React.useState(false);

    const biggerThan700 = useMediaPredicate("(min-width: 800px)");

    useEffect(() => {
        if (grid.current) {
            if (canChangeEyeView)
                GridLoadViews();
            grid.current.rowSelected = rowSelected;
            grid.current.rowDeselected = rowDeselected;
        }
    }, [grid, grid.current]);

    function rowSelected(e: RowSelectEventArgs) {
        setCantDelete(false);
    }
    function rowDeselected(e: RowSelectEventArgs) {
        if (grid.current) {
            let rows = grid.current!.getSelectedRecords();
            if (!rows || rows.length === 0)
                setCantDelete(true);
        }
    }

    function DeleteRecord(r: any) {

        var rowsSelected = grid.current!.getSelectedRecords();
        let result = rowsSelected.map(a => {
            type ObjectKey = keyof typeof a;
            const myVar = 'id' as ObjectKey;
            return a[myVar];
        });
        if (onDelete !== undefined)
            onDelete(result);
        setDeleteDialogOpen(false);
    }
    async function GridSaveActualPersist() {
        if (grid.current !== undefined) {
            let data: GridSettingsDTO = new GridSettingsDTO({
                id: gridState !== null ? gridState.id! : 0,
                gridData: grid.current!.getPersistData(),
                isGroupingEnabled: grid.current!.allowGrouping,
                isToolbarEnabled: (grid.current!.toolbar !== undefined),
                name: new Date().toLocaleString()
            });
            await new APIUsersHelper().InsertOrUpdateGridSettings(data, gridType);

        }

    }
    function GetGridActualPersistence(): GridSettingsDTO | null {
        if (grid.current !== undefined && grid.current !== null) {
            return new GridSettingsDTO({
                id: gridState !== null ? gridState.id! : 0,
                gridData: grid.current!.getPersistData(),
                isGroupingEnabled: grid.current!.allowGrouping,
                isToolbarEnabled: (grid.current!.toolbar !== undefined),
                name: new Date().toLocaleString()
            });


        }
        else return null;

    }
    function GridLoadViews() {
        new APIUsersHelper().GetGridSettings(gridType).then((settings) => {

            if (settings !== undefined) {
                setGridStateOptions(settings);
            }
        });
    }
    function GridLoadPersistDataById(selectedId: number) {
        if (gridStateOptions !== null && gridStateOptions.length > 0) {
            let option = gridStateOptions.find(x => x.id === selectedId);
            if (option !== undefined) {
                setGridState(option);
                let groupVisible = option.isGroupingEnabled!;
                let ls = option.gridData!;
                let persist = JSON.parse(ls);
                let toolbarVisible = option.isToolbarEnabled!;
                if (groupVisible !== undefined && groupVisible !== null) {
                    if ((Boolean(groupVisible) && !grid.current!.allowGrouping) || (!Boolean(groupVisible) && grid.current!.allowGrouping)) {
                        pivotSwitch(null);
                    }
                }
                delay(50).then(() => {
                    if (ls !== undefined && ls !== null) {
                        grid.current!.sortSettings = persist.sortSettings;
                        grid.current!.searchSettings = persist.searchSettings;
                        grid.current!.groupSettings = persist.groupSettings;
                        // let i: number = 0;
                        grid.current!.columns.forEach((col: any, i) => {
                            if ((col as Column)) {
                                let clNew = persist.columns.find((t: any) => t.field === (col as Column).field);
                                if (clNew !== undefined) {
                                    (col as Column).width = clNew!.width;
                                    (col as Column).uid = clNew!.uid;
                                    (col as Column).visible = clNew!.visible;
                                    if ((col as Column).index !== i) {
                                        //byl posunut
                                        console.log("column was moved by view", (col as Column).field);
                                    }

                                }

                            }
                            // i++;
                        });
                        grid.current!.refresh();
                        if (toolbarVisible !== undefined && toolbarVisible !== null) {
                            if ((Boolean(toolbarVisible) && grid.current!.toolbar === undefined) || (!Boolean(toolbarVisible) && grid.current!.toolbar !== undefined))
                                toolbarSwitch(null);
                        }
                        grid.current!.filterSettings = persist.filterSettings;
                    }

                });
            }
        }
    }

    const handleChange = (event: SelectChangeEvent<number>) => {
        setView(event.target.value as number);
        if (onViewChange)
            onViewChange(event.target.value as number);
        // setAge(event.target.value as string);
    };
    function deleteDialogClose() {
        setDeleteDialogOpen(false);
    }
    function gridSettingsDialogClose() {
        // console.log('zavírám');
        GridLoadViews();
        SetGridSettingDialogOpen(false);
    }


    function pivotSwitch(event: any) {
        if (grid !== undefined && grid.current !== undefined) {
            grid.current!.allowGrouping = !grid.current!.allowGrouping;
            setPivotEnabled(!pivotEnabled);
        }
        // var rowsSelected = grid.current!.getSelectedRecords();
        // console.log(rowsSelected);
    }
    function toolbarSwitch(event: any) {
        if (grid && grid.current) {
            /*  if (grid.current!.toolbar !== undefined)
                 // @ts-ignore: Unreachable code error
                 grid.current!.toolbar = undefined;
             else grid.current!.toolbar = ['Search', 'ColumnChooser']; */
            grid.current.columnChooserModule.openColumnChooser();
            setToolbarEnabled(!toolbarEnabled);
        }
        // var rowsSelected = grid.current!.getSelectedRecords();
        // console.log(rowsSelected);
    }
    function getFavouritePropsId(): number | undefined {
        if (gridStateOptions !== null && gridStateOptions !== undefined) {
            let t = gridStateOptions.find(t => t.isFavouriteInGroup!);
            if (t !== undefined)
                return t.id;
            else return undefined;
        }
    }
    const getIsCancelFilteringVisible = (args: any) => {

        if (args.requestType && args.requestType === 'filtering') {
            setShowClearFilter(args.columns.length > 0);
        }
    }

    useEffect(() => {
        if (grid && grid.current) {
            grid.current.actionComplete = getIsCancelFilteringVisible;
        }
    }, [grid]);

    useEffect(() => {
        if (gridId) {
            let storage = localStorage.getItem('grid' + gridId);
            if (storage) {
                let json = JSON.parse(storage);
                if (json && json.filterSettings) {
                    if (json.filterSettings.columns && json.filterSettings.columns.length > 0) {
                        setShowClearFilter(true);
                    }
                    else setShowClearFilter(false);
                }
                else setShowClearFilter(false);
            }
            else setShowClearFilter(false);
        }
    }, []);
    const [searchText, setSearchText] = React.useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            setSearchOpen(true);
        }
    }
    function searchClosed() { setSearchOpen(false); }

    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: backgroundColor ? backgroundColor : '#FFFFFF' }}>
                <div style={{ backgroundColor: backgroundColor ? backgroundColor : '#FFFFFF', display: 'flex', justifyContent: 'space-between' }}>
                    <div >
                        <div style={{ height: '48px', display: 'flex', alignItems: 'center' }}>
                            {icon && <div style={{ marginLeft: '20px' }}>
                                {icon}
                            </div>}
                            {/* {biggerThan700 && <Typography sx={{ fontWeight: 'bold', marginLeft: '12px' }}>{moduleName}</Typography>} */}
                            {biggerThan700 &&
                                <TextField label={searchLabel}
                                    InputLabelProps={{
                                        sx: {
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                // set the color of the label when shrinked (usually when the TextField is focused)
                                                color: "gray",
                                                marginTop: '2px',
                                                fontSize: '13px'
                                            }
                                        }
                                    }}

                                    sx={{
                                        border: '0px solid white',
                                        ml: '16px',
                                        minWidth: biggerThan700 ? '400px' : '200px',
                                        backgroundColor: '#F9F9F9',
                                        borderRadius: '16px',
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <SearchOutlined sx={{ fontSize: '12px' }} />
                                        </InputAdornment>
                                    }}
                                    size='small'

                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            grid.current?.search(searchText);
                                        }
                                    }}
                                    onChange={(e) => { setSearchText(e.target.value) }} />}
                            <div style={{ display: viewGridEditing ? 'flex' : 'none' }}>
                                {gridStateOptions && <FormControl fullWidth sx={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <InputLabel id="demo-simple-select-label">{t('View')}</InputLabel>
                                    <Select sx={{ width: '140px' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Pohled"
                                        value={gridConfig}
                                        onChange={(e) => { setGridConfig(Number(e.target.value)); GridLoadPersistDataById(Number(e.target.value)); }}

                                        size='small'
                                    >
                                        {gridStateOptions && gridStateOptions.map((item, i) => {
                                            return (<MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)

                                        })}
                                    </Select>
                                </FormControl>}

                                <Tooltip title={t('SaveView')}>
                                    {/* <IconButton aria-label="create" onClick={event => { GridSaveActualPersist(); GridLoadViews(); }} disabled={false}> */}
                                    <IconButton aria-label="create" onClick={event => { setGridStateActual(GetGridActualPersistence()); SetGridSettingDialogOpen(true); }} disabled={false}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {canChangeEyeView && <Checkbox checked={viewGridEditing} onChange={(e) => setViewGridEditing(!viewGridEditing)} icon={<RemoveRedEye />} checkedIcon={<VisibilityOff />}></Checkbox>}
                        </div>
                    </div>
                    <div >
                        <div style={{ height: '48px', display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                            {items && <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{menuName}</InputLabel>
                                <Select sx={{ width: '140px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={menuName}
                                    value={view}
                                    onChange={handleChange}
                                    size='small'
                                >
                                    {items && items.map((item, i) => {
                                        return (<MenuItem value={item.data} key={item.data}>{item.caption}</MenuItem>)

                                    })}
                                </Select>
                            </FormControl>}

                            {/* {biggerThan700 && canDiarySearch && <TextField onKeyDown={handleKeyDown} onChange={(e) => { setSearchText(e.target.value) }}
                                placeholder={t('SearchDots')} variant='standard' sx={{ height: '20px', marginRight: '16px' }} InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => { setSearchOpen(true) }}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} size='small' />} */}
                            {!biggerThan700 && canDiarySearch && <IconButton onClick={() => { setSearchOpen(true) }}>
                                <Search />
                            </IconButton>}

                            {biggerThan700 && canAdd && onCreate !== undefined &&
                                <Tooltip title={t('Add')}>
                                    <Button sx={{ width: addLabel ? '170px' : 'auto' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                        if (onCreate !== undefined)
                                            onCreate(event);
                                    }} disabled={false} color="primary">
                                        {addLabel ? addLabel : t('Add')}
                                    </Button>
                                </Tooltip>}
                            {!biggerThan700 && canAdd && onCreate !== undefined &&
                                <Tooltip title={t('Add')}>
                                    <Button aria-label="create" variant='contained' size='small' onClick={(event) => {
                                        if (onCreate !== undefined)
                                            onCreate(event);
                                    }} disabled={false} color="primary">
                                        <AddCircleOutline />
                                    </Button>
                                </Tooltip>}

                            {/* {biggerThan700 && canAddSecond &&
                                <Tooltip title={addSecondLabel}>
                                    <Button sx={{ marginLeft: '10px', width: '170px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                        if (onCreateSecond !== undefined)
                                            onCreateSecond(event);
                                    }} disabled={false} color="primary">
                                        {addSecondLabel}
                                    </Button>
                                </Tooltip>} */}
                            {canAddSecond &&
                                <Tooltip title={addSecondLabel}>
                                    <Button sx={{ marginLeft: '10px' }} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                        if (onCreateSecond !== undefined)
                                            onCreateSecond(event);
                                    }} disabled={false} color="primary">
                                        <AddCircleOutline sx={{ marginX: '5px' }} />
                                        {addSecondLabel ? addSecondLabel : t('Add')}
                                    </Button>
                                </Tooltip>}

                            {/* {canToolbarGrid && biggerThan700 && <Tooltip title={t('Settings')}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={toolbarEnabled} onChange={event => { toolbarSwitch(event) }} icon={<Settings color='secondary' />} checkedIcon={<Settings />} />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{t('Options')}</Typography>}
                                    labelPlacement="end"
                                />

                            </Tooltip>} */}
                            {canExcelExport &&
                                <Tooltip title={t('ExportToExcel')}>
                                    <Button style={{ marginRight: '-20px' }} onClick={() => {
                                        const selectedRecords = grid.current?.getSelectedRecords();
                                        const exportProperties = {
                                            dataSource: selectedRecords
                                        };
                                        grid.current?.excelExport(exportProperties);
                                    }}><ExcelExportIcon color='#404040'
                                        width={22} height={22} /></Button>
                                </Tooltip>
                            }
                            {canToolbarGrid && <Tooltip title={gridType === GridType.Projects ? t('columns') : t('Settings')}>
                                <Checkbox checked={gridType === GridType.Projects ? false : toolbarEnabled} onChange={event => { toolbarSwitch(event) }} icon={<Settings color='secondary' />} checkedIcon={<Settings />} />


                            </Tooltip>}
                            {canChangeGroup && biggerThan700 && <Tooltip title={t('toGroup')}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={pivotEnabled} onChange={event => { pivotSwitch(event) }} icon={<PivotTableChartOutlined color='secondary' />} checkedIcon={<PivotTableChartOutlined />} />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{t('toGroup')}</Typography>}
                                    labelPlacement="end"
                                />

                            </Tooltip>}
                            {canChangeGroup && !biggerThan700 && <Tooltip title={t('Settings')}>
                                <Checkbox checked={pivotEnabled} onChange={event => { pivotSwitch(event) }} icon={<PivotTableChartOutlined color='secondary' />} checkedIcon={<PivotTableChartOutlined />} />


                            </Tooltip>}

                            {/* <Checkbox onChange={event => onSettings ? onSettings(event) : {}} icon={<Settings />} checkedIcon={<Settings />} /> */}
                            {biggerThan700 && canDelete &&
                                <Tooltip title={t('Delete')}>
                                    <Button style={{ textTransform: 'none', fontWeight: 'bold' }} startIcon={<RemoveCircle />} aria-label="create" size='small' onClick={(event) => { setDeleteDialogOpen(true) }} disabled={cantDelete} color="secondary">
                                        {t('Delete')}
                                    </Button>
                                </Tooltip>}
                            {!biggerThan700 && canDelete &&
                                <Tooltip title={t('Delete')}>
                                    <IconButton aria-label="delete" color='secondary' onClick={event => setDeleteDialogOpen(true)} disabled={cantDelete} >
                                        <RemoveCircle />
                                    </IconButton>
                                </Tooltip>}
                            {biggerThan700 && canTrash &&
                                <Tooltip title={t('Trash')}>
                                    <Button style={{ textTransform: 'none', fontWeight: 'bold' }} startIcon={<Delete />}
                                        aria-label="create" size='small' onClick={(event) => {
                                            if (onThrashClicked) onThrashClicked(true);
                                        }} color="secondary">
                                        {t('Trash')}
                                    </Button>
                                </Tooltip>}


                        </div>
                    </div>
                </div>
                <EditGridViewsDialog onClose={gridSettingsDialogClose} gridType={gridType} open={gridSettingDialogOpen}
                    favouritePropsId={getFavouritePropsId()} settingActual={gridStateActual}
                    settings={gridStateOptions}></EditGridViewsDialog>
                <DeleteDialog onClose={deleteDialogClose} open={deleteDialogOpen} onDeleteAgree={event => DeleteRecord(event)} />
                <DiarySearchDialog isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onRefresh={() => { }} searchText={searchText} open={searchOpen} onClose={searchClosed} diaryId={undefined} diaryDate={undefined} fromExternal={fromExternal === true ? true : false} />
            </div >
            {grid && grid.current && <div style={{ alignSelf: 'end' }}>
                {showClearFilter && <Link sx={{ cursor: 'pointer' }} onClick={() => {
                    try { grid.current?.clearFiltering(); setShowClearFilter(false); } catch { };
                }}>{t('clearFiltering')}</Link>}
            </div>}
        </div>
    );
}
