import { AddCircleOutline, AddOutlined, Book, Done } from '@mui/icons-material';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, FilterSettingsModel, GridComponent, Inject, RecordDoubleClickEventArgs, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page } from '@syncfusion/ej2-react-grids';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { GetDiariesForUserDTO } from '../../../ApiOld/ApiServerVov';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useDiaryTabsContext } from '../../../Contexts/Providers/DiaryTabsProvider';
import { useMediaPredicate } from 'react-media-hook';



interface DiariesListProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiariesList(props: DiariesListProps) {
    InitSfGridLocale();
    const { fromExternal, isSubdodavatel } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const { diaries, refreshDiaries, loadingDiaries } = useDiaryTabsContext();
    // const [diaries, setDiaries] = useState<GetDiariesForUserDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState<boolean>(false);
    const [showClearFilter, setShowClearFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const { permissions, refreshPermissions } = useDiaryTabsContext();
    const theme = useTheme();
    const params = useParams();
    const { t, i18n } = useTranslation();
    function toDiaryDetail(props: GetDiariesForUserDTO) {
        // grid.current?.filterModule.
        if (isSubdodavatel) {
            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaryDetail/' + props.id);
            window.parent.postMessage("diarydetailchanged:" + props.id, '*');
        }
        else {
            if (fromExternal === undefined || fromExternal === false)
                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryDetail/' + props.id);
            else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/' + props.id);
            window.parent.postMessage("diarydetailchanged:" + props.id, '*');
        }
    }
    function gridTemplate(props: GetDiariesForUserDTO) {
        if (permissions && permissions.canViewDiaryDetail)
            return (<div>
                <a style={{ fontWeight: 'bold' }}
                    onClick={() => {
                        toDiaryDetail(props);


                    }}>{props.name}</a>
            </div >);
        else return <div>{props.name}</div>
    }
    useEffect(() => {
        if (permissions === null)
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
    }, [permissions, refreshPermissions, isSubdodavatel]);
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    const createDiary = useCallback(() => {
        if (isSubdodavatel) {
            //navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/diaries/new');
        }
        else {
            if (fromExternal === undefined || fromExternal === false)
                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryDetail/new');
            else navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryolderpnewdetail/new');
        }
    }, [fromExternal, isSubdodavatel, params, navigate]);
    const createSubdDiary = useCallback(() => {
        if (fromExternal === undefined || fromExternal === false)
            navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryDetail/newSubd');
        else navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryolderpnewdetail/newSubd');
    }, [navigate, fromExternal, params]);
    const grid = useRef<GridComponent>(null);
    // function reloadData() {
    //     new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).GetDiaries(false, props.isSubdodavatel ? true : false).then((r) => { setDiaries(r); if (grid.current) grid.current.refresh(); });
    // }
    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    // useEffect(() => {
    //     if (diaries === null)
    //         reloadData();
    // }, []);
    useEffect(() => {
        setBackMenuFunction(undefined);
        setMenuButtons(<Stack direction='row' mr={2} alignItems='center' spacing={2}>
            {!isSubdodavatel && permissions && permissions.canCreateDiary && <Button size='small' startIcon={<AddCircleOutline />} variant='contained' onClick={() => {

                createDiary();
            }}>{t('Main')}</Button>}
            {!isSubdodavatel && permissions && permissions.canCreateDiary && <Button size='small' startIcon={<AddOutlined />} variant='contained' onClick={() => {

                createSubdDiary();
            }}>{t('AddSubd')}</Button>}
        </Stack>);
    }, [setBackMenuFunction, setMenuButtons, createDiary, permissions, t, createSubdDiary, isSubdodavatel]);


    function cellTemplateSubcontractor(props: GetDiariesForUserDTO) {
        if (props.isSubcontractorDiary !== undefined && props.isSubcontractorDiary === true)
            return (<Done color='action' />);
        else return (<div></div>);

    }

    if (diaries !== null)
        return <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '10px', padding: '20px', maxWidth: biggerThan700 ? '100vw' : '88vw', boxShadow: 3 }}>
            <UpperMenu moduleName={t('diary.diaryList')}
                gridType={GridType.Users}
                gridId='grid-component-diaries'
                // canAdd={isSubdodavatel !== true && permissions ? permissions.canCreateDiary : false}
                // canAddSecond={isSubdodavatel !== true && permissions ? permissions.canCreateDiary : false}
                addLabel={t('Main')}
                addSecondLabel={t('AddSubd')}
                canDelete={isSubdodavatel !== true && permissions ? permissions.canDeleteDiary : false}
                // onCreate={createDiary}
                // onCreateSecond={createSubdDiary}
                onDelete={(e) => {
                    new ApiDiaryBaseHelper(props.isSubdodavatel === undefined ? false : props.isSubdodavatel, false).DeleteDiaries(e as number[]).then((e) => {
                        if (e)
                            refreshDiaries();
                    })
                }}
                canChangeEyeView={false}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
                canTrash={isSubdodavatel !== true}
                onThrashClicked={() => { setTrashOpen(true) }}
                isInvestor={false}
                isSubdodavatel={isSubdodavatel}
                fromExternal={fromExternal}
                searchLabel={t('SearchInDiaries')}
                canExcelExport
            />
            <div>
                <GridComponent ref={grid}
                    id="grid-component-diaries" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        if (permissions && permissions.canViewDiaryDetail)
                            toDiaryDetail(e.rowData as GetDiariesForUserDTO)
                    }}
                    //actionComplete={(e) => { console.log(e) }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    onChange={() => { console.log('grid changed'); }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    //  columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    rowHeight={30}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={diaries}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='50' minWidth='50' />
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                        <ColumnDirective field='name' template={gridTemplate} headerText={t('Name')} width='120' minWidth='120' />
                        <ColumnDirective field='buildingName' headerText={t('Projekt')} width='120' minWidth='120' />
                        {isSubdodavatel !== true && <ColumnDirective field='isSubcontractorDiary' type='boolean' template={cellTemplateSubcontractor} headerText={t('diary.subdiary')} width='80' minWidth='80' />}
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent></div>
            <TrashGridDialog open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refreshDiaries(); }} trashType={TrashType.Diaries} />
        </Box >
    return <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '10px', padding: '20px', display: "flex", flexDirection: 'column', justifyContent: 'start' }}>{loadingDiaries ? t('loading...') : t('noRecords')}</Box>;
}