
// @ts-nocheck
import { Button, Checkbox, IconButton, Tooltip } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { ImageDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';

import { Grid, InfiniteLoader, InfiniteLoaderChildProps, List, WindowScroller } from 'react-virtualized';
import { useMediaPredicate } from 'react-media-hook';
import { AddAPhoto, AutoFixHighOutlined, Delete, Update } from '@mui/icons-material';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import ImageGallery from '../../../Shared/FileViewers/ImageGallery';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

interface ChooseProjectPhotosProps {
    width?: number;
    biggerHeight?: number;
    columnsCountInput?: number;
    canUpdate?: boolean;
    canDelete?: boolean;
    canSelect?: boolean;
    images: ImageDiaryDTO[];
    imagesSelectedChanged?: (images: ImageDiaryDTO[]) => void;
    imageSingleSelectedChanged?: (image: ImageDiaryDTO) => void;
    recordId?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    isLocked?: boolean;
    onRefresh?: (fileName: string, wasDelete: boolean) => void;
    needRefresh?: () => void;
    fullWidth: boolean;
    isProjectChoosing: boolean;
}

export default function ChooseProjectPhotos(props: ChooseProjectPhotosProps) {
    const { images, canDelete, canUpdate, recordId, onRefresh, columnsCountInput, biggerHeight, canSelect,
        imageSingleSelectedChanged, imagesSelectedChanged, isInvestor, isSubdodavatel, needRefresh, isLocked, fullWidth, isProjectChoosing } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const biggerThan1600 = useMediaPredicate("(min-width: 1600px)");
    const [selected, setSelected] = useState<number | undefined>();
    const [deleteData, setDeleteData] = useState<ImageDiaryDTO | undefined>();
    const [imagesLocally, setImagesLocally] = useState<ImageDiaryDTO[] | null>(null);
    const { permissions } = useDiaryTabsContext();
    const { t } = useTranslation();

    function getColumnsCount() {
        const width = divRef.current?.offsetWidth || 0;
        const columns = width > 0 ? Math.max(1, parseInt(width / 300, 10)) : 1;
        console.log('getColumnsCount: ', { width, columns });
        return columns;
    }
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setImagesLocally(images);
    }, [images]);
    function cellRenderer({ columnIndex, key, rowIndex, style }) {
        if (imagesLocally)
            if (imagesLocally !== undefined && imagesLocally.length > 0) {
                let imageData = imagesLocally[getColumnsCount() * rowIndex + columnIndex];
                if (imageData !== undefined) {
                    let image = imagesLocally[getColumnsCount() * rowIndex + columnIndex];
                    if (image !== undefined) {
                        return (
                            <div key={key} style={style}>
                                <DeleteDialog open={deleteData !== undefined}
                                    onDeleteAgree={(e) => {
                                        setDeleteData(undefined);
                                        if (e) {
                                            if (recordId) {
                                                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).deleteDiaryImageRel(recordId, e.fileName);
                                                if (onRefresh)
                                                    onRefresh(e.fileName, true);


                                            }
                                        }
                                    }}
                                    data={deleteData}
                                    title={t('diary.removeImageFromDailyRecord')} text={t('diary.removeImageFromDailyRecordText')}
                                    onClose={() => { setDeleteData(undefined) }} />
                                <div className='parent' id='parent' style={{ margin: '8px', boxShadow: '2px 2px 4px gray', borderRadius: '6px' }}>
                                    {canDelete && permissions && permissions.deleteAttachments && permissions.canDeleteInsideRecords && <Tooltip title={t('diary.removeImageFromDailyRecord')}>
                                        <Button
                                            variant='contained'
                                            onClick={(e) => {
                                                setDeleteData(image);
                                            }}
                                            className='hidden-child' color='secondary' sx={{ position: 'absolute', top: '8px', right: '5px', left: 'auto', float: 'right' }}>
                                            <Delete />
                                        </Button>
                                    </Tooltip>}
                                    {canSelect && <Tooltip title={t('diary.editImage')}>
                                        <Checkbox checked={image.isSelected} onChange={(e, val) => {
                                            if (!(val === false && (permissions === undefined || permissions.deleteAttachments === undefined || permissions.deleteAttachments === false))) {
                                                if (imagesLocally) {

                                                    let imgsNew = [...imagesLocally];
                                                    let changingIndex = imagesLocally.findIndex(x => x.fileName === image.fileName);
                                                    let changingItem = new ImageDiaryDTO({ ...imgsNew[changingIndex] });
                                                    changingItem.isSelected = val;
                                                    imgsNew[changingIndex] = changingItem;
                                                    if (imagesSelectedChanged)
                                                        imagesSelectedChanged(isProjectChoosing ? imgsNew : imgsNew.filter(p => p.isSelected));
                                                    if (imageSingleSelectedChanged)
                                                        imageSingleSelectedChanged(changingItem);
                                                }
                                            }

                                        }} />
                                    </Tooltip>}
                                    <img onClick={(e) => {
                                        setSelected(imagesLocally.findIndex(x => x.url === image.url));
                                    }} src={image.thumbnailUrl} height={260} width={280} loading='lazy' style={{ marginLeft: '2px', gridArea: '1/1', maxWidth: '100%', maxHeight: '100%' }}></img>
                                </div>
                            </div>
                        );
                    }
                }
            }
            else <div></div>
    }

    return (
        <div ref={divRef}>
            <ImageGallery isSubdodavatel={isSubdodavatel} needRefresh={() => { if (needRefresh) needRefresh(); }}
                buildingDiaryRecordId={recordId} canSaveChanged={(isInvestor === undefined || isInvestor === false) && permissions && permissions.canEditDiaryRecord && (isLocked === undefined || isLocked === false)} canCreateTask={false} diaryImages={imagesLocally} selected={selected} open={selected !== undefined} onClose={() => { setSelected(undefined) }} newRequestButton={false} />
            {imagesLocally !== null && <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                    <div>
                        <Grid
                            cellRenderer={cellRenderer}
                            columnCount={Math.max(1, getColumnsCount())}
                            columnWidth={300}
                            height={Math.max(320, biggerHeight || 560)}
                            rowCount={Math.ceil(imagesLocally.length / Math.max(1, getColumnsCount()))}
                            rowHeight={300}
                            width={Math.max(300, fullWidth ? width - 145 : getColumnsCount() * 308)}
                            onSectionRendered={({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) => {
                                console.log('Grid rendered with row and column data:', {
                                    rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex
                                });
                            }}
                        />

                    </div>
                )}
            </WindowScroller>}
        </div>
    );
}