import { Annotation, BookmarkView, FormDesigner, FormFields, Inject, LinkAnnotation, Magnification, Navigation, PdfViewerComponent, Print, TextSearch, TextSelection, ThumbnailView, Toolbar } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { registerLicense } from '@syncfusion/ej2-base';
import { TodoAttachmentDTO } from '../../../Api/TodoMicroserviceClient';
import { ApiBaseHelper } from '../../../Api/ApiBaseHelper';
export interface PdfViewerProps {
    address?: string;
    newApi?: boolean;
}
export default function PDFViewer(props: PdfViewerProps) {
    const { address, newApi } = props;
    const { state } = useLocation();
    const [docPathOrUri, setDocPathOrUri] = useState<string | undefined>();
    const [apiPdfAddress, setApiPdfAddress] = useState<string | undefined>();
    useEffect(() => {
        if (newApi !== undefined && newApi === true) {
            setDocPathOrUri(((state as any).attachment as number).toString());
            setApiPdfAddress(new ApiBaseHelper().GetApiAddressToPDFViewer());
            //("špatně", apiPdfAddress);
        }
        else {
            setApiPdfAddress(new ApiBaseHelper().GetApiAddressToPDFViewerOld());
            //console.log("tady", apiPdfAddress);
        }
    }, []);
    useEffect(() => {
        console.log(address);
        setDocPathOrUri(address);
    }, [address]);


    // console.log(state);
    //registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0djXn9adXNQQmJUV00=');

    const pdfRef = React.useRef<PdfViewerComponent>(null);
    // var ajaxRequestSettings = {
    //     ajaxHeaders: [
    //         {
    //             headerName: 'Authorization',
    //             headerValue: ('Bearer ' + (newApi !== undefined && newApi === true) ? localStorage.getItem('bearer') : localStorage.getItem('old_bearer'))!,
    //         }
    //     ],
    //     withCredentials: false
    // };
    var ajaxRequestSettings = {
        ajaxHeaders: [
            {
                headerName: 'Authorization',
                headerValue: 'Bearer ' + localStorage.getItem('old_bearer'),
            }
        ],
        withCredentials: false
    };
    // function loadPDF(){
    //     pdfRef.current!.documentPath = props;
    // }
    if (newApi !== undefined && newApi === true)
        if (apiPdfAddress !== "")
            return (<div ><PdfViewerComponent id='container' style={{ height: '100vh' }}
                ajaxRequestSettings={ajaxRequestSettings} ref={pdfRef} serviceUrl={apiPdfAddress}
                documentPath={docPathOrUri}>
                <Inject services={[Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, FormFields, FormDesigner]} />
            </PdfViewerComponent></div>);
        else return <>not adrress</>;
    else {
        if (apiPdfAddress !== "")
            return (<div ><PdfViewerComponent id='container' style={{ height: '100vh' }}
                ajaxRequestSettings={ajaxRequestSettings} ref={pdfRef} serviceUrl={apiPdfAddress}
                documentPath={docPathOrUri}>
                <Inject services={[FormFields, FormDesigner]} />
            </PdfViewerComponent></div>);
        else return <>not adrress</>;
    }

}