
import { FormControl, InputLabel, ListSubheader, MenuItem, Select, Checkbox, SelectChangeEvent, FormControlLabel, Typography, useTheme, Button, Snackbar, AlertTitle, Alert, Slide, IconButton, Box, Grid } from '@mui/material';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetWorkerForRecordDTO, GroupAdminDTO, GroupDTO, SubDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import InitSfRTBLocale from '../../../../Localization/SFRichEditLanguageIntializer';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import Dropdown from 'react-multilevel-dropdown';
import { setCommentRange } from 'typescript';
import { Check, Construction, ContentCopy, Edit, ListAlt, People, Psychology, QuestionMark } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import { useDiaryCompletedWorksCopyStore } from '../../../../Contexts/DiaryCompletedWorksCopyZContext';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import HtmlConcurrencyViewerDialog from './HtmlConcurrencyViewerDialog';
import { useMediaPredicate } from 'react-media-hook';
import GptChatScene from '../../GptChat/GptChatScene';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useLoginSubcontractorStore } from '../../../../Contexts/LoginSubcontractorZContext';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';


interface CompletedWorksWidgetProps {
    buildingDiaryRecordId: number;
    completedWorksInput?: string;
    onHtmlChanged?: (html: string, privContent?: GroupAdminDTO | null) => void;
    subdiaries?: SubDiaryDTO[];
    privateContents?: GroupAdminDTO[];
    isExpanded?: boolean;
    showSubdiaries?: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    concurrency?: string;
    copyCompletedWork: string;
    onExpandChange: (expanded: boolean) => void;
}
InitSfRTBLocale();
export default function CompletedWorksWidget(props: CompletedWorksWidgetProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, completedWorksInput, onHtmlChanged, subdiaries, isExpanded, showSubdiaries, isCopying, isInvestor, isSubdodavatel, concurrency, privateContents, copyCompletedWork, onExpandChange } = props;
    const [completedWorks, setCompletedWorks] = useState<string>('');
    const [completedWorksConcurrency, setCompletedWorksConcurrency] = useState<string | undefined>();
    const [completedWorksCommonBackup, setCompletedWorksCommonBackup] = useState<string>('');
    const [selectedSubdiary, setSelectedSubdiary] = useState<SubDiaryDTO | null>(null);
    const [localSubdiaries, setLocalSubdiaries] = useState<SubDiaryDTO[] | null>(null);

    const [selectedPrivateContent, setSelectedPrivateContent] = useState<GroupAdminDTO | null>(null);
    const [localPrivateContents, setLocalPrivateContents] = useState<GroupAdminDTO[] | null>(null);

    const [sharedRecordSelected, setSharedRecordSelected] = useState<boolean>(true);
    const [concurrencyOpen, setConcurrencyOpen] = useState<boolean>(false);
    const [aiHelperOpen, setAiHelperOpen] = useState<boolean>(false);
    const [concurrencyOpenErrorInfo, setConcurrencyOpenErrorInfo] = useState<boolean>(false);
    const theme = useTheme();
    const { permissions, refreshPermissions, investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();
    const { userId, load } = useLoginAdminStore();
    const { completedWorkStore } = useDiaryCompletedWorksCopyStore();
    const { subcontractorId, loadSub } = useLoginSubcontractorStore();

    useEffect(() => {
        //reloadData();
    }, [buildingDiaryRecordId]);

    useEffect(() => {
        if (onHtmlChanged && copyCompletedWork !== '') {
            onHtmlChanged(completedWorksCommonBackup + "<p>" + copyCompletedWork + "</p>");
        }
    }, [copyCompletedWork]);




    useEffect(() => {
        if (isSubdodavatel === true) {
            if (!subcontractorId) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetUser().then(v => {
                    if (v)
                        loadSub(v.userName!, v.userId!);
                })
            }
        }
        else {
            if (!userId) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetUser().then(v => {
                    if (v)
                        load(v.userName!, v.userId!);
                })
            }
        }
        if (!investorPermissions && isInvestor === true)
            refreshInvestorPermissions();
        else if (permissions === null && (isInvestor === undefined || isInvestor === false))
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
    }, []);

    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    function reloadData() {
        if (isCopying) {
            setCompletedWorks(completedWorkStore!);
        }
        else {
            if (buildingDiaryRecordId)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetCompletedWorksRecord(buildingDiaryRecordId).then((d) => {
                    if (d !== undefined)
                        setCompletedWorks(d);
                    else
                        setCompletedWorks('');
                });
        }
    }
    useEffect(() => {
        // reloadData();
        if (/* buildingDiaryRecordId &&  */completedWorksInput !== undefined) {
            setCompletedWorks(completedWorksInput);
            setCompletedWorksCommonBackup(completedWorksInput);
        }
        else {
            setCompletedWorks('');
            setCompletedWorksCommonBackup('');
        }

        setSelectedSubdiary(null);
        setSelectedPrivateContent(null);
        //setSelectedPrivateContent(null);
        setSharedRecordSelected(true);

    }, [completedWorksInput]);

    useEffect(() => {
        setCompletedWorksConcurrency(concurrency);
        if (concurrency && concurrency.length > 0) {
            setConcurrencyOpenErrorInfo(true);
        }
    }, [concurrency]);
    useEffect(() => {
        // reloadData();
        // console.log(groups);
        if (subdiaries)
            setLocalSubdiaries(subdiaries);


        /* if (subdiaries && subdiaries.length > 0)
            setSelectedSubdiary(subdiaries[0]);
        else setSelectedSubdiary(null); */


    }, [subdiaries]);
    useEffect(() => {
        if (privateContents)
            setLocalPrivateContents(privateContents);

        if (isSubdodavatel === true) {
            if (permissions?.subcontractorsViewOnlyTheirPage && privateContents && privateContents.some(p => p.userId === subcontractorId)) {
                selectPrivateContent(privateContents.find(p => p.userId === subcontractorId)!);
            }
        }
        else
            if (permissions?.subcontractorsViewOnlyTheirPage && privateContents && privateContents.some(p => p.userId === userId)) {
                selectPrivateContent(privateContents.find(p => p.userId === userId)!);
            }
    }, [privateContents]);

    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }
    // const handleChange = (event: SelectChangeEvent<number | undefined>) => {

    //     if (sharedRecordSelected)
    //         setSharedRecordSelected(false);
    //     console.log(event.target.value);

    // };
    /* function selectPrivateAdmin(member: GroupAdminDTO, subdiary: SubDiaryDTO) {
        setSelectedPrivateContent(member);
        setSharedRecordSelected(false);
        setSelectedSubdiary(subdiary);
        if (member.content)
            setCompletedWorks(member.content);
        else setCompletedWorks('');
    } */
    function selectSubdiary(subdiary: SubDiaryDTO) {
        if (subdiary.diaryId !== 0) {
            setSharedRecordSelected(false);
            setSelectedPrivateContent(null);
            setSelectedSubdiary(subdiary);
            if (subdiary.content)
                setCompletedWorks(subdiary.content);
            else
                setCompletedWorks('');
        }
        else {
            setSharedRecordSelected(true);
            setSelectedSubdiary(null);
            setCompletedWorks('');
        }
    }
    function selectPrivateContent(content: GroupAdminDTO) {
        if (content.contentId !== 0) {
            setSharedRecordSelected(false);
            setSelectedPrivateContent(content);
            setSelectedSubdiary(null);
            if (content.content)
                setCompletedWorks(content.content);
            else
                setCompletedWorks('');
        }
        else {
            setSharedRecordSelected(true);
            setSelectedPrivateContent(null);
            setCompletedWorks('');
        }
    }

    function isReadOnlyEditor(): boolean {
        if (isSubdodavatel === true) {
            if ((selectedPrivateContent !== null && selectedPrivateContent.userId === subcontractorId && permissions && permissions.subcontractorsViewOnlyTheirPage === true) || (sharedRecordSelected && permissions && permissions.writeCommonCompletedWorks)) {
                return false;
            }
        }
        else
            if ((selectedPrivateContent !== null && selectedPrivateContent.userId === userId && permissions && permissions.subcontractorsViewOnlyTheirPage === true) || (sharedRecordSelected && permissions && permissions.writeCommonCompletedWorks)) {
                return false;
            }
        return true;
    }

    function isVisibleEditor(): boolean {
        if (!sharedRecordSelected || (sharedRecordSelected && permissions && (permissions.readCommonCompletedWorks || permissions.writeCommonCompletedWorks)) || isInvestor === true) {
            return true;
        }
        return false;
    }

    function countLines(text: string): number {
        const lines = text !== null ? text.split('<p>') : [];
        return lines.length;
    }

    const rtb = React.useRef<RichTextEditorComponent>(null);
    return (
        <Accordion sx={{ margin: 0 }} className='expander' expanded={isExpanded} onChange={(e, ex) => { onExpandChange(ex); }}>
            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel2d-content" id="panel2d-header">
                <Construction color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.completedWorks')}</Typography>
                {(completedWorks === undefined || completedWorks === '') && <QuestionMark color='disabled' sx={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} />}
                {(completedWorks !== undefined && completedWorks !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Grid container spacing={1} sx={{ mb: 1 }}>
                        <Grid item xs={12} lg='auto'>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox checked={sharedRecordSelected} sx={{ marginLeft: '10px' }} onChange={(e) => {
                                    if (!sharedRecordSelected) {
                                        setSharedRecordSelected(true);
                                        setCompletedWorks(completedWorksCommonBackup);
                                        setSelectedSubdiary(null);
                                        setSelectedPrivateContent(null);
                                    }

                                }} icon={<People color='action' />} checkedIcon={<People color='primary' />} />}
                                label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: sharedRecordSelected ? theme.palette.primary.main : theme.palette.secondary.main }}>{t('diary.sharedEntry')}</Typography>}
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} lg='auto'>
                            {showSubdiaries && localSubdiaries && (isInvestor === undefined || isInvestor === false) && isSubdodavatel !== true &&
                                <Box>

                                    <Dropdown
                                        position='top-right'
                                        title=
                                        {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto', minWidth: '230px' }}>
                                            <ListAlt />
                                            <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedSubdiary !== null ? selectedSubdiary.diaryName : t('diary.PickSubRecord')}</div></div>}
                                    >
                                        {localSubdiaries.map((subd) => {
                                            return <Dropdown.Item key={subd.diaryId} onClick={(e) => selectSubdiary(subd)}>
                                                <div style={{ fontFamily: 'Roboto' }} >{subd.diaryName} {subd.content !== undefined && subd.content !== '' && <Check sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                                            </Dropdown.Item>
                                        })}

                                    </Dropdown>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} lg='auto'>
                            {showSubdiaries && localPrivateContents && (isInvestor === undefined || isInvestor === false) && isSubdodavatel !== true &&
                                <Box>
                                    <Dropdown
                                        position='top-right'
                                        title=
                                        {<div style={{ color: '#404040', display: 'flex', fontFamily: 'Roboto', minWidth: '230px' }}>
                                            <ListAlt />
                                            <div style={{ marginTop: '6px', marginLeft: '4px' }}>{selectedPrivateContent !== null ? selectedPrivateContent.name : t('diary.PickPrivateRecord')}</div></div>}
                                    >
                                        {localPrivateContents.map((cont) => {
                                            return <Dropdown.Item key={cont.contentId} onClick={(e) => selectPrivateContent(cont)}>
                                                <div style={{ fontFamily: 'Roboto' }} >{cont.name} {cont.content !== undefined && cont.content !== '' && <Check sx={{ width: '18px', height: '16px', marginTop: '-2px' }} color='success' />}</div>
                                            </Dropdown.Item>
                                        })}

                                    </Dropdown>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} lg='auto'>
                            {(selectedSubdiary !== null || selectedPrivateContent !== null) &&
                                <Button onClick={(e) => {
                                    if (onHtmlChanged)
                                        onHtmlChanged(completedWorksCommonBackup + completedWorks);
                                }}
                                    sx={{ marginBottom: '18px', minWidth: '230px' }} startIcon={<ContentCopy />} variant='contained' disabled={!(permissions && permissions?.writeCommonCompletedWorks)}>{t('diary.copyToShared')}</Button>}
                        </Grid>
                        <Grid item xs={12} lg='auto'>
                            {completedWorksConcurrency !== undefined && <Button size='small' variant='contained' startIcon={<Edit />} onClick={(e) => { setConcurrencyOpen(true) }}>{t('ShowChanges')}</Button>}
                        </Grid>
                    </Grid>
                    {isInvestor !== true && <div style={{ position: 'relative', right: '0px', top: '0px' }}><Button color={aiHelperOpen ? "success" : "info"} startIcon={<Psychology />} onClick={e => setAiHelperOpen(!aiHelperOpen)}>{biggerThan700 ? t('aiHelper') : ''}</Button></div>}
                </div>
                {aiHelperOpen &&
                    <div style={{ width: '100%' }}>
                        <GptChatScene onCopy={(text) => {

                            if (onHtmlChanged)
                                onHtmlChanged(completedWorksCommonBackup !== null ? completedWorksCommonBackup + '<p>' + text + '</p>' : '' + '<p>' + text + '</p>');
                        }} /></div>}
                {isVisibleEditor() && !isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions && permissions?.writeCommonCompletedWorks)) && <RichTextEditorComponent
                    saveInterval={50}
                    readOnly={true}
                    ref={rtb} actionBegin={(e) => {
                        if (e.type === 'drop' || e.type === 'dragstart') {
                            e.cancel = true;
                        }
                    }}
                    toolbarSettings={toolbarSettings} height={(completedWorks !== null && completedWorks.length > 500) || countLines(completedWorks) > 10 ? 600 : 200} locale={i18n.language} value={completedWorks} change={(eve: ChangeEventArgs) => {
                        setCompletedWorks(eve.value);
                        if (selectedSubdiary === null && selectedPrivateContent === null) {
                            setCompletedWorksCommonBackup(eve.value ? eve.value : '');
                            if (onHtmlChanged)
                                onHtmlChanged(eve.value ? eve.value : '');
                        }
                        else {
                            if (onHtmlChanged)
                                onHtmlChanged(eve.value ? eve.value : '', selectedPrivateContent);
                        }

                    }} >
                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                </RichTextEditorComponent>}
                {isVisibleEditor() && isReadOnlyEditor() && (!(sharedRecordSelected) || (permissions?.writeCommonCompletedWorks)) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                {!isVisibleEditor() && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} >{t('noViewingPermissions')}</div>}
                {isVisibleEditor() && sharedRecordSelected && (permissions === undefined || permissions?.writeCommonCompletedWorks === undefined || permissions?.writeCommonCompletedWorks === false) && <div style={{ width: '100%', height: '180px', backgroundColor: 'white', maxHeight: '400px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: completedWorks }}></div>}
                <Snackbar
                    TransitionComponent={(propsy) => { return <Slide {...propsy} direction='left' /> }}
                    open={concurrencyOpenErrorInfo}
                    autoHideDuration={5000}
                    onClose={(e) => { setConcurrencyOpenErrorInfo(false) }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                    <Alert onClose={(e) => { setConcurrencyOpenErrorInfo(false) }} severity="warning">
                        <AlertTitle>{t('dataChanged')}</AlertTitle>
                        {t('dataChangedReloadAndFillAgain')}
                    </Alert>
                </Snackbar>
                <HtmlConcurrencyViewerDialog open={concurrencyOpen} onClose={() => { setConcurrencyOpen(false) }} myHtml={completedWorks} concurrencyHtml={completedWorksConcurrency} />
            </AccordionDetails>
        </Accordion>
    );

}