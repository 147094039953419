
import { AttachFile, Delete, Diversity1, Download, InsertDriveFile } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { FileDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import downloadFile from '../../../Shared/Functions/DownloadFile';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { AccordionDetails, AccordionSummary, Accordion } from '../Styles/AccordionStyles';
import ChooseFileDialog from './ChooseFileDialog';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';

interface AttachmentsWidgetProps {
    recordId?: number;
    isExpanded: boolean;
    onDocumentChooseOpen?: () => void;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    onExpandChange: (expanded: boolean) => void;
}

export default function AttachmentsWidget(props: AttachmentsWidgetProps) {
    const { recordId, isExpanded, onDocumentChooseOpen, isLocked, isInvestor, isSubdodavatel, onExpandChange } = props;
    const [chooseFileOpen, setChooseFileOpen] = React.useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [isItemsSelected, setIsItemsSelected] = React.useState<boolean>(false);
    const [fileToDelete, setFileToDelete] = React.useState<FileDiaryDTO | null>(null);
    const [files, setFiles] = useState<FileDiaryDTO[]>([]);
    const { t, i18n } = useTranslation();
    const grid = useRef<GridComponent>(null);
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const { permissions } = useDiaryTabsContext();
    function reloadData() {
        if (recordId !== undefined)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetAttachmentsForRecord(recordId).then((data) => {
                if (data !== undefined && data !== null) {
                    if (grid.current)
                        grid.current.dataSource = data;
                    setFiles(data);
                }

            });
    }

    useEffect(() => {
        reloadData();
    }, [recordId]);
    useEffect(() => {
        if (fileToDelete !== null)
            setDeleteDialogOpen(true);
    }, [fileToDelete]);

    function gridTemplateDownload(props: FileDiaryDTO) {
        return (<div>

            <IconButton onClick={(event) => { download(props) }}>
                <Download />
            </IconButton>
            {/* {permissions && permissions.deleteAttachments && (isInvestor === undefined || isInvestor === false) && <IconButton onClick={(event) => { setFileToDelete(props) }}>
                    <Delete />
                </IconButton>} */}

        </div>);
    }
    function gridTemplateDelete(props: FileDiaryDTO) {
        return (<div>


            <IconButton onClick={(event) => { setFileToDelete(props) }}>
                <Delete />
            </IconButton>

        </div>);
    }
    async function download(item: FileDiaryDTO) {

        if (item.url && item.fileName) {
            const link = document.createElement('a');
            // link.download = "prdel.xlsx";
            link.href = item.url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            try {
                document.body.removeChild(link);
            } catch (e) {
                console.log(e);
            }
        }
        // downloadFile(item.url, item.fileName);
    }
    async function deleteFile(item: FileDiaryDTO) {

        if (item.fileId && recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).deleteRecordFileRel(recordId, item.fileId).then((data) => {
                reloadData();
                setFileToDelete(null);
                setDeleteDialogOpen(false);
            });
        }
        // downloadFile(item.url, item.fileName);
    }
    return (<div>
        <div>
            <ChooseFileDialog recordId={recordId} open={chooseFileOpen} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onClose={(e) => {
                if (e)
                    reloadData();
                setChooseFileOpen(false);
            }} />
            <DeleteDialog
                data={fileToDelete} open={deleteDialogOpen} onClose={(e) => { setDeleteDialogOpen(false) }} onDeleteAgree={(e) => {
                    deleteFile(e);
                }} />
        </div>
        <Accordion sx={{ margin: 0, marginBottom: '-1px' }} className='expander' expanded={isExpanded} onChange={(e, ex) => { onExpandChange(ex); }}>
            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel8d-content" id="panel8d-header">
                <AttachFile color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('diary.attachments')}</Typography>
                {files && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + files.length + ')'}</Typography>}

            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                <Box sx={{ p: 2, pb: 0.5 }}>
                    {((permissions && permissions.canEditDiaryRecord) || isInvestor) && !isLocked && <Button sx={{ marginRight: '20px', height: '24px' }}
                        onClick={(e) => {

                            e.preventDefault();
                            e.stopPropagation();
                            setChooseFileOpen(true);
                            // setChoosePhotoOpen(true);
                        }}
                        variant='contained' size='small' startIcon={biggerThan700 ? <InsertDriveFile /> : undefined}>{biggerThan700 ? t('files.addFiles') : <InsertDriveFile sx={{ height: '20px' }} />}</Button>}
                    <Button size='small' variant='contained' sx={{ height: '24px' }} startIcon={<Download />} disabled={!isItemsSelected}
                        onClick={() => {
                            if (grid.current && grid.current.getSelectedRecords() !== null && grid.current.getSelectedRecords().length > 0)
                                download(grid.current.getSelectedRecords()[0] as FileDiaryDTO);
                        }}
                    >{biggerThan700 && t('Download')}</Button>
                    {permissions && permissions.deleteAttachments && (isInvestor === undefined || isInvestor === false) && <Button
                        onClick={() => {
                            if (grid.current && grid.current.getSelectedRecords() !== null && grid.current.getSelectedRecords().length > 0)
                                setFileToDelete(grid.current.getSelectedRecords()[0] as FileDiaryDTO);
                        }}
                        size='small' sx={{ marginLeft: '20px', height: '24px' }} variant='contained' startIcon={<Delete />} disabled={!isItemsSelected}>{biggerThan700 && t('Delete')}</Button>}
                </Box>
                <GridComponent style={{ borderColor: 'transparent' }}
                    id="grid-component-docs"
                    ref={grid}

                    allowReordering={true}
                    allowResizing={true}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick', type: 'Single' }}
                    locale={i18n.language}
                    enablePersistence={false}
                    filterSettings={standardFilterOptions}
                    rowSelected={(e) => { setIsItemsSelected(true) }}
                    rowDeselected={(e) => { setIsItemsSelected(false) }}
                    allowFiltering={true}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                        {/* <ColumnDirective allowFiltering={false} allowResizing={false} allowGrouping={false} allowReordering={false}
                            allowSearching={false} allowSorting={false} template={gridTemplateDownload} maxWidth='800' width='80' minWidth='20' /> */}
                        {/* <ColumnDirective allowFiltering={false} allowResizing={false} allowGrouping={false} allowReordering={false}
                            allowSearching={false} allowSorting={false} template={gridTemplateDelete} maxWidth='800' width='80' minWidth='20' /> */}
                        <ColumnDirective field='fileName' headerText={t('Name')} maxWidth='800' width='180' minWidth='100' />
                        <ColumnDirective field='createdAt' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Created')} maxWidth='800' width='180' minWidth='100' />
                        <ColumnDirective field='extension' headerText={t('Extension')} maxWidth='800' width='80' minWidth='20' />


                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
                </GridComponent>

            </AccordionDetails>
        </Accordion>
    </div >);
}