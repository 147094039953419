import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AddCircleOutline, ArrowBack, Check, Forum, RateReview, Troubleshoot } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router';
import { GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDiaryRecordDataNotTablesResponse, GroupAdminDTO, GroupDTO, RequestsDTO, SubDiaryDTO } from '../../ApiOld/ApiServerVov';
import { Box, Button, Grid, IconButton, } from '@mui/material';

import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import '../Scenes/DiaryComponents/Styles/DiaryMainScene.css'
import '../Scenes/Styles/DiaryMainScene.css';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import moment from 'moment';
import { unstable_usePrompt } from 'react-router-dom';
import { ApiDiaryBaseHelper } from '../../Api/ApiDiaryBaseHelper';
import { DiaryCommonVovClient, InvestorVovClient, SubcontractorVovClient } from '../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import DiaryPrintDialog from '../Scenes/DiaryComponents/DiaryPrints/DiaryPrintDialog';
import DiaryRecordCopyDialog from '../Scenes/DiaryComponents/DiaryRecordCopyDialog';
import DiarySearchDialog from '../Scenes/DiaryComponents/DiarySearchDialog';
import DailyRecordMenuButtons from '../Scenes/DiaryComponents/Menu/DailyRecordMenuButtons';
import { DiaryTimelineModel } from '../Scenes/DiaryComponents/Models/DiaryTimelineModel';
import SignaturesUserInfoDialog from '../Scenes/DiaryComponents/Signatures/SignaturesUserInfoDialog';
import AttachmentsWidget from '../Scenes/DiaryComponents/Widgets/AttachmentsWidget';
import CompletedWorksWidget from '../Scenes/DiaryComponents/Widgets/CompletedWorksWidget';
import CompletedWorksWidgetOld from '../Scenes/DiaryComponents/Widgets/CompletedWorksWidgetOld';
import CreateUserDialog from '../Scenes/DiaryComponents/Widgets/CreateUserDialog';
import InvestorChatWidget from '../Scenes/DiaryComponents/Widgets/InvestorChatWidget';
import MachinesWidget from '../Scenes/DiaryComponents/Widgets/MachinesWidget';
import MaterialsWidget from '../Scenes/DiaryComponents/Widgets/MaterialsWidget';
import PhotoWidget from '../Scenes/DiaryComponents/Widgets/PhotoWidget';
import ReallyLockUnlockDialog from '../Scenes/DiaryComponents/Widgets/ReallyLockUnlockDiaryRecord';
import SelectGroupDialog from '../Scenes/DiaryComponents/Widgets/SelectGroupDialog';
import SignaturesWidget from '../Scenes/DiaryComponents/Widgets/SignaturesWidget';
import TasksInDiaryWidget from '../Scenes/DiaryComponents/Widgets/TasksInDiaryWidget';
import WeatherWidget from '../Scenes/DiaryComponents/Widgets/WeatherWidget';
import WorkersWidget from '../Scenes/DiaryComponents/Widgets/WorkersWidget';
import saveBlob from '../Shared/Files/SaveBlob';
import NoDataImageHolder from '../Shared/Layouts/NoDataImageHolder';
import MessageDialog from '../Shared/MessageDialog';
import InvestorTaskCreateDialog from './Components/InvestorTaskCreateDialog';
import { Timeline } from '../Scenes/DiaryComponents/Widgets/Timeline';
import { Accordion, AccordionDetails, AccordionSummary } from '../Scenes/DiaryComponents/Styles/AccordionStyles';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';

interface InvestorDiaryMainSceneProps extends WithLoadingProps {
    buildingDiaryRecordIdProps?: number;
    fromExternal?: boolean;
    displayAsTabs?: boolean;
    setDisplayAsTabs?: (e: boolean) => void;
}

function InvestorDiaryMainScene(props: InvestorDiaryMainSceneProps) {
    const [expanded, setExpanded] = React.useState<number[]>([]);
    const completedWorks = React.useRef<string | undefined>();
    const [materials, setMaterials] = React.useState<string | undefined>();
    const [machines, setMachines] = React.useState<string | undefined>();
    const [weatherFallback, setWeatherFallback] = React.useState<string | undefined>();
    const [workersFallback, setWorkersFallback] = React.useState<string | undefined>();
    const [notes, setNotes] = React.useState<string | undefined>();
    const [buildingName, setBuildingName] = React.useState<string | undefined>();
    const [buildingId, setBuildingId] = React.useState<number | undefined>();
    const [diaryName, setDiaryName] = React.useState<string | undefined>();
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [diaryId, setDiaryId] = React.useState<number | undefined>(undefined);
    const [subcontractorPrintId, setSubcontractorPrintId] = React.useState<number | undefined>(undefined);
    const [saveGrids, setSaveGrids] = React.useState<boolean>(false);
    const [printOpen, setPrintOpen] = React.useState<boolean>(false);
    const [refreshLog, setRefreshLog] = React.useState<boolean>(false);
    const [refreshSignatures, setRefreshSignatures] = React.useState<boolean>(false);
    const [reloadTasks, setReloadTasks] = React.useState<boolean>(false);
    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [dataToSaveChanged, setDataToSaveChanged] = React.useState<boolean>(false);
    const [workersChanged, setWorkersChanged] = React.useState<boolean>(false);
    const [weathersChanged, setWeathersChanged] = React.useState<boolean>(false);
    const [materialsChanged, setMaterialsChanged] = React.useState<boolean>(false);
    const [machinesChanged, setMachinesChanged] = React.useState<boolean>(false);
    const [signDigitally, setSignDigitally] = React.useState<boolean>(false);
    const [printOnlyMine, setPrintOnlyMine] = React.useState<boolean>(false);
    const [isDataValid, setIsDataValid] = React.useState<boolean>(true);
    const [showDashboard, setShowDashboard] = React.useState<boolean>(false);
    const [subdiaries, setSubdiaries] = React.useState<SubDiaryDTO[]>([]);
    const groups = React.useRef<GroupDTO[]>([]);
    const privateContents = React.useRef<GroupAdminDTO[]>([]);
    const [showGroups, setShowGroups] = React.useState<boolean>(true);
    const [showWorkHours, setShowWorkHours] = React.useState<boolean>(true);
    const [saveTimeStamp, setSaveTimeStamp] = React.useState<number | undefined>(undefined);
    const [searchText, setSearchText] = React.useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [investorNewTaskOpen, setInvestorNewTaskOpen] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [locked, setLocked] = useState(false);
    const [records, setRecords] = useState<DiaryTimelineModel[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>();
    const [refresh, setRefresh] = useState(false);

    const { investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [copyCompletedWork, setCopyCompletedWork] = useState<string>('');
    const [printing, setPrinting] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isSigniDialogOpen, setIsSigniDialogOpen] = useState(false);
    const { buildingDiaryRecordIdProps, fireLoading, stopLoading, fromExternal, displayAsTabs, setDisplayAsTabs } = props;
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const handleChange = useCallback(
        (panel: number, newExpanded: boolean) => {
            if (newExpanded)
                setExpanded([...expanded, panel]);
            else {
                setExpanded(expanded.filter(t => t !== panel));
            }
        }, [expanded, setExpanded]);

    const beforeUnLoad = (e: any) => {
        if (dataChanged) {
            return e.returnValue = 'Really want to leave?';
        }
    }

    useEffect(() => {
        if (dataToSaveChanged || workersChanged || weathersChanged || materialsChanged || machinesChanged)
            setDataChanged(true);
        else setDataChanged(false);
    }, [dataToSaveChanged, workersChanged, weathersChanged, machinesChanged, materialsChanged]);

    useEffect(() => {
        if (!dataChanged || locked) {
            setWorkersChanged(false);
            setWeathersChanged(false);
            setMaterialsChanged(false);
            setMachinesChanged(false);
            setDataToSaveChanged(false);
        }
        else {
            window.addEventListener('beforeunload', beforeUnLoad);
            return () => {
                window.removeEventListener('beforeunload', beforeUnLoad);
            };
        }

    }, [dataChanged]);

    unstable_usePrompt({ message: 'Opravdu chcete opustit stránku bez uložení?', when: dataChanged });

    function handleStateFullLoad(): DiaryTimelineModel[] | undefined {
        setRecordId(loc.state["id"]);
        setBuildingName(loc.state["buildingName"]);
        setDiaryName(loc.state["diaryName"]);
        setDiaryId(loc.state["diaryId"]);
        if (loc.state["inputRecords"] !== undefined && loc.state["inputRecords"] !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            loc.state["inputRecords"].reverse().forEach((element: any) => {
                if (element.diaryId === loc.state["diaryId"]) {
                    newRecords.push({
                        date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                        hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                        hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                    });
                }
            });
            return newRecords;
        }
    }
    const loadDataStatelessTimeline = (id: number, d: GetDailyRecordsForUserAndDiaryByRecordIdResponse) => {
        setRecordId(id);
        setBuildingName(d.buildingName);
        setDiaryId(d.diaryId);
        setDiaryName(d.diaryName);
        setCurrentDate(d.diaryDate);
        if (d.viewerRecords !== undefined && d.viewerRecords !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            d.viewerRecords.reverse().forEach((element: any) => {
                newRecords.push({
                    date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                    hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                    hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                });
            });
            setRecords(newRecords);
        }
    }
    const handleStateLessLoad = (id: number) => {
        new ApiDiaryBaseHelper(false, true).GetRecordsForViewingStateless(id).then((d) => {
            loadDataStatelessTimeline(id, d);
        });
    };

    useEffect(() => {
        if (saveGrids)
            setSaveGrids(false);
    }, [saveGrids]);

    useEffect(() => {
        if (subcontractorPrintId) {
            setSignDigitally(false);
            setPrintOnlyMine(false);
            setPrintOpen(true);
        }
    }, [subcontractorPrintId]);

    const navigateBack = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/investor/main/tabs/diary');
    }

    useEffect(() => {
        fireLoading!();

        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);

        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }
        if (!investorPermissions)
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (buildingDiaryRecordIdProps !== undefined)
            setRecordId(buildingDiaryRecordIdProps);
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));
        }
    }, [buildingDiaryRecordIdProps, loc, params]);


    function reloadData() {
        fireLoading!();
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }
        if (!investorPermissions)
            refreshInvestorPermissions();
    }

    function setDefaultState() {
        setSaveTimeStamp(0);
        completedWorks.current = undefined;
        setMaterials('');
        setMachines('');
        setWeatherFallback('');
        setWorkersFallback('');
        setNotes('');
        //setSubdiaries([]);
        groups.current = [];
        privateContents.current = [];
    }

    function setDataAfterReload(data: GetDiaryRecordDataNotTablesResponse) {
        setLocked(data.isLocked!);
        setSaveTimeStamp(data.timeStamp);
        setBuildingId(data.buildingId);
        completedWorks.current = data.completedWork;
        setMaterials(data.materials);
        setMachines(data.machines);
        setWeatherFallback(data.weather);
        setWorkersFallback(data.workers);
        setNotes(data.additionalNotes);
        if (data.subdiaries)
            setSubdiaries(data.subdiaries);
        if (data.groups)
            groups.current = data.groups;
        if (data.privateContents)
            privateContents.current = data.privateContents;
        if (data.showWorkHours !== undefined)
            setShowWorkHours(data.showWorkHours);
        else setShowWorkHours(false);
        if (data.showEmployeesGroup !== undefined)
            setShowGroups(data.showEmployeesGroup);
        else setShowGroups(false);

        setRefreshSignatures(true);
    }

    const refreshDiary = useCallback(() => {
        setIsDataValid(true);
        setCopyCompletedWork('');
        fireLoading!();
        setDefaultState();
        if (recordId) {
            new ApiDiaryBaseHelper(false, true).GetFullDailyRecordNotTables(recordId, false).then((data) => {
                stopLoading!();
                setDataAfterReload(data);
            }).catch(() => { stopLoading!(); setIsDataValid(false); });
        }
        else stopLoading!();
    }, [fireLoading, recordId, stopLoading]);

    useEffect(() => {
        refreshDiary();
    }, [recordId]);


    const theme = useTheme();

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            setSearchOpen(true);
        }
    }

    const handleMenuItemClick = useCallback((event: number) => {
        if (event === 0) {
            setLockDialogOpen(true);
        }
        else if (event === 1) {
            setCopyOpen(true);
        }
        else if (event === 2) {
            setExpanded([]);
        }
        else if (event === 3) {
            setExpanded([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }
        else if (event === 4) {
            if (recordId) {
                new ApiDiaryBaseHelper(false, true).RingOthersDiary(recordId);
            }
        }
        else if (event === 5) {
            setCreateUserOpen(true);
        }
    }, [recordId]);

    function searchClosed() { setSearchOpen(false); }
    function createUserClosed() { setCreateUserOpen(false); }
    function copyClosed(refresh: boolean) { setCopyOpen(false); if (refresh) { reloadData(); } setRefresh(true); }
    useEffect(() => {
        if (refresh)
            setRefresh(false);
    }, [refresh]);

    const onTiskInvestorClick = useCallback((e: number) => {
        if (!recordId)
            return;
        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }
        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(false, true).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(false, true).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK CELÉHO DENÍKU
        else if (e === 2) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, true).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, true).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, true).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU PRO DIG PODPIS
        else if (e === 3) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, true).PrintStatus(diaryId, false, true, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, true).PrintDiary(diaryId, false, true, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, true).PrintDiaryBackground(diaryId, false, true, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU S PŘÍLOHAMI
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, true).PrintStatus(diaryId, false, false, true).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, true).PrintDiary(diaryId, false, false, true, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, true).PrintDiaryBackground(diaryId, false, false, true, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
    }, [diaryId, diaryName, recordId, selectedDate]);

    const onTiskUserClick = useCallback((e: number) => {
        if (!recordId)
            return;

        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }

        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(false, true).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(false, true).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 2) {
            // nedělat nic
        }
        // TISK MYCH ZAZNAMU
        else if (e === 3) {
            // not for investor
        }
        // TISK CELEHO DENIKU
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, true).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, true).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, true).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }

        }
        else if (e === 5) {
            setIsSigniDialogOpen(true);
        }
    }, [recordId, diaryId, diaryName, selectedDate]);

    const onTiskClick = useCallback((e: number) => {
        onTiskInvestorClick(e);
    }, [onTiskInvestorClick, onTiskUserClick]);
    return (
        <>
            <Grid sx={{ background: 'white' }} container spacing={0} direction={'row'}>
                <Grid sx={{ maxWidth: '100vw' }} item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton sx={{ width: '40px', marginLeft: '8px', marginTop: '8px' }} onClick={() => { navigateBack(); }}><ArrowBack /></IconButton>
                        <DailyRecordMenuButtons
                            handleMenuItemClick={handleMenuItemClick}
                            isInvestor={true}
                            saveDiary={() => { }}
                            locked={locked}
                            readOnlySubcontractor={false}
                            loading={loading}
                            saveButtonDisabled={false}
                            isSubdodavatel={false}
                            onTiskClick={onTiskClick}
                            printing={printing}
                            buildingName={buildingName}
                            diaryName={diaryName}
                            setSearchOpen={setSearchOpen}
                            setSearchText={setSearchText}
                            handleKeyDown={handleKeyDown}
                            displayAsTabs={displayAsTabs ?? false}
                            setDisplayAsTabs={setDisplayAsTabs ?? (() => { })} permissions={null} investorPerm={null} />
                    </Box>
                    <Box>
                        <Timeline disabled={dataChanged} inputRecordId={recordId}
                            isInvestor={true}
                            isSubdodavatel={false}
                            onSelected={(e, d) => {
                                fireLoading!();
                                if (e)
                                    navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/main/tabs/diary/' + e);
                                else {
                                    setRecordId(e);
                                    setSelectedDate(d);
                                    stopLoading!();

                                }
                            }}
                            inputRecords={records}
                            fromExternal={fromExternal}
                        />
                    </Box>
                    {recordId !== undefined &&
                        <Box sx={{ px: 2 }}>
                            <div>
                                {isDataValid && !showDashboard
                                    && <Box sx={{ marginX: '12px', mb: 4 }}>
                                        <SignaturesWidget
                                            onExpandChange={(ex) => { { handleChange(0, ex) } }}
                                            onSigniClicked={() => { setIsSigniDialogOpen(true) }}
                                            refresh={refreshSignatures}
                                            onRefreshed={() => setRefreshSignatures(false)}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            isExpanded={expanded.indexOf(0) > -1}
                                            recordId={recordId}
                                            isLocked={locked}
                                            onSigned={() => { handleStateLessLoad(recordId); setRefreshLog(refreshLog === true ? false : true); }}
                                        />
                                        {(subdiaries === null || subdiaries.length === 0) ? <CompletedWorksWidgetOld
                                            onExpandChange={(ex) => handleChange(1, ex)}
                                            copyCompletedWork={copyCompletedWork}
                                            concurrency={undefined}
                                            isInvestor={true}
                                            isCopying={false}
                                            showGroups={true}
                                            buildingDiaryRecordId={recordId} groups={groups.current}
                                            onHtmlChanged={(text, group, member) => {
                                                if (group === null || group === undefined || member === null || member === undefined) {
                                                    if (text !== completedWorks.current)
                                                        setDataToSaveChanged(true);
                                                    completedWorks.current = text;
                                                }
                                                else {
                                                    let groupNew = groups.current.find(x => x.groupId === group.groupId);
                                                    if (groupNew && groupNew.members) {
                                                        let memberRemove = groupNew.members.find(x => x.userId === member.userId);

                                                        if (memberRemove) {
                                                            if (text !== memberRemove.content)
                                                                setDataToSaveChanged(true);
                                                            let gruopToUse = new GroupDTO({
                                                                groupId: groupNew.groupId, groupName: groupNew.groupName,
                                                                members: [...groupNew.members!.filter(x => x.userId !== member.userId), new GroupAdminDTO({ content: text, name: memberRemove!.name, userId: memberRemove!.userId, contentId: memberRemove!.contentId, changed: true })]
                                                            })
                                                            groups.current = [...groups.current.filter(x => x.groupId !== group.groupId), gruopToUse];
                                                        }
                                                    }

                                                }
                                            }}
                                            completedWorksInput={completedWorks.current}
                                            isExpanded={expanded.indexOf(1) > -1} />
                                            :
                                            <CompletedWorksWidget
                                                onExpandChange={(ex) => handleChange(1, ex)}
                                                copyCompletedWork={copyCompletedWork}
                                                concurrency={undefined}
                                                isInvestor={true}
                                                isSubdodavatel={false}
                                                isCopying={false}
                                                showSubdiaries={true}
                                                buildingDiaryRecordId={recordId} subdiaries={subdiaries} privateContents={privateContents.current}
                                                onHtmlChanged={(text, privContent) => {
                                                    if (privContent === null || privContent === undefined) {
                                                        if (text !== completedWorks.current)
                                                            setDataToSaveChanged(true);
                                                        completedWorks.current = text;
                                                    }
                                                    else if (privContent !== null && privContent !== undefined) {
                                                        let contNew = privateContents.current.find(x => x.contentId === privContent.contentId);
                                                        if (contNew) {
                                                            if (text !== contNew.content)
                                                                setDataToSaveChanged(true);

                                                            let contToUse = new GroupAdminDTO({
                                                                contentId: contNew.contentId, name: contNew.name,
                                                                content: text ? text : '', userId: contNew.userId,
                                                                changed: true
                                                            })
                                                            privateContents.current = [...privateContents.current.filter(x => x.contentId !== privContent.contentId), contToUse]
                                                        }
                                                    }
                                                }}
                                                completedWorksInput={completedWorks.current}
                                                isExpanded={expanded.indexOf(1) > -1}
                                            />
                                        }
                                        <PhotoWidget
                                            onExpandChange={(ex) => handleChange(2, ex)}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            isLocked={locked}
                                            recordId={recordId}
                                            isExpanded={expanded.indexOf(2) > -1}
                                            domain={params['domain']}
                                            recordDate={currentDate}
                                            onCopy={(text) => { setCopyCompletedWork(text); }}
                                        />
                                        <AttachmentsWidget
                                            onExpandChange={(ex) => handleChange(3, ex)}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            isLocked={locked}
                                            isExpanded={expanded.indexOf(3) > -1}
                                            recordId={recordId}
                                        />
                                        <WeatherWidget
                                            onExpandChange={(ex) => handleChange(4, ex)}
                                            date={selectedDate}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            onSaved={() => { setWeathersChanged(false); setRefreshLog(refreshLog === true ? false : true); }}
                                            onSaveChanged={() => { setWeathersChanged(true) }}
                                            isLocked={locked}
                                            saveChanged={saveGrids}
                                            isExpanded={expanded.indexOf(4) > -1}
                                            buildingDiaryRecordId={recordId}
                                            weatherNote={weatherFallback}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setWeatherFallback(e);
                                            }}
                                        />
                                        <WorkersWidget
                                            onExpandChange={(ex) => handleChange(5, ex)}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            buildingName={buildingName}
                                            date={selectedDate}
                                            isLocked={locked}
                                            isCopying={false}
                                            workersNote={workersFallback}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setWorkersFallback(e);
                                            }}
                                            onChanged={() => { setWorkersChanged(false); }}
                                            onChangeToSave={() => { setWorkersChanged(true); }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            saveChanged={saveGrids}
                                            isExpanded={expanded.indexOf(5) > -1}
                                            buildingDiaryRecordId={recordId}
                                            showGroups={showGroups}
                                            showWorkHours={showWorkHours}
                                            refresh={refresh}
                                        />
                                        <MaterialsWidget
                                            onExpandChange={(ex) => handleChange(6, ex)}
                                            currentDate={currentDate ? currentDate : new Date()}
                                            buildingDiaryId={diaryId}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            isLocked={locked}
                                            saveChanged={saveGrids}
                                            isCopying={false}
                                            materialsNote={materials}
                                            onChanged={() => { setMaterialsChanged(false); }}
                                            onChangeToSave={() => { setMaterialsChanged(true) }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setMaterials(e);
                                            }}
                                            buildingDiaryRecordId={recordId}
                                            isExpanded={expanded.indexOf(6) > -1}
                                            refresh={refresh}
                                        />
                                        <MachinesWidget
                                            onExpandChange={(ex) => handleChange(7, ex)}
                                            isInvestor={true}
                                            isSubdodavatel={false}
                                            isLocked={locked}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setMachines(e);
                                            }}
                                            onChanged={() => { setMachinesChanged(false); }}
                                            onChangeToSave={() => { setMachinesChanged(true) }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            saveChanged={saveGrids}
                                            isCopying={false}
                                            machinesNote={machines}
                                            buildingDiaryRecordId={recordId}
                                            isExpanded={expanded.indexOf(7) > -1}
                                            refresh={refresh}
                                        />


                                        <Accordion style={{ margin: '0px' }} className='expander' expanded={expanded.indexOf(8) > -1} onChange={(e, ex) => handleChange(8, ex)}>
                                            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                                                <RateReview color='secondary' className='accordion-icon' />
                                                <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('diary.anotherNotes')}</Typography>
                                                {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                {(investorPermissions && investorPermissions.buildingDiaryRecordsEdit === true) && <RichTextEditorComponent saveInterval={100} height={400} locale={i18n.language} value={notes} change={(eve: ChangeEventArgs) => {
                                                    if (notes !== eve.value) {
                                                        setNotes(eve.value);
                                                        setDataToSaveChanged(true);
                                                    }
                                                }} >
                                                    <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                                                </RichTextEditorComponent>}
                                                {(investorPermissions && investorPermissions.buildingDiaryRecordsEdit !== true) && <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: notes ? notes : "" }} />}
                                            </AccordionDetails>
                                        </Accordion>

                                        {<Accordion style={{ margin: '0px' }} className='expander' expanded={expanded.indexOf(9) > -1} onChange={(e, ex) => handleChange(9, ex)}>
                                            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header'>
                                                <Forum color='secondary' className='accordion-icon' />
                                                <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('dashboard.tasks')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                <Box sx={{ pt: 2 }}>

                                                    <Button sx={{ marginLeft: '32px', marginBottom: '4px', width: '150px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                                        setReloadTasks(false); setInvestorNewTaskOpen(true);
                                                    }} disabled={false} color="primary">
                                                        {t('Add')}
                                                    </Button>

                                                </Box>
                                                {recordId && <TasksInDiaryWidget isInvestor={true} isSubdodavatel={false} domain={params['domain']!} recordId={recordId} reloadData={reloadTasks} />}
                                            </AccordionDetails>
                                        </Accordion>}
                                        {<InvestorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(10) > -1} isInvestor={true} isSubdodavatel={false} onExpandChange={(ex) => handleChange(10, ex)} />}
                                    </Box>}

                            </div >
                        </Box >}
                </Grid>
            </Grid>

            {recordId === undefined &&
                <div style={{
                    display: 'flex', justifyContent: 'center', marginTop: '120px',
                    flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                }}>
                    <NoDataImageHolder title={t('diary.noRecordForChosenDate')} />
                    <div style={{ display: 'flex' }}><Typography>{t('diary.site')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {buildingName}</Typography></div>
                    <div style={{ display: 'flex' }}><Typography>{t('diary.diary')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {diaryName}</Typography></div>
                </div>
            }
            {
                !isDataValid && <div style={{
                    display: 'flex', justifyContent: 'center', marginTop: '120px',
                    flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                }}><NoDataImageHolder title='Data jsou chybná' /></div>
            }
            {recordId && <SignaturesUserInfoDialog isInvestor={true} diaryRecordId={recordId} isOpen={isSigniDialogOpen} onClose={() => { setIsSigniDialogOpen(false) }} />}
            {recordId && <DiaryPrintDialog isInvestor={true} isSubdodavatel={false} subcontractorPrintId={subcontractorPrintId}
                signDigitally={signDigitally} onlyMine={printOnlyMine}
                recordId={recordId} open={printOpen} onClose={() => { setPrintOpen(false); setSubcontractorPrintId(undefined); }} />}
            <DiarySearchDialog isInvestor={true} isSubdodavatel={false} onRefresh={() => { }} searchText={searchText} open={searchOpen} onClose={searchClosed} diaryId={diaryId} diaryDate={undefined} fromExternal={fromExternal === true ? true : false} />
            <InvestorTaskCreateDialog topMargin={200} onRefresh={() => { }} todo={new RequestsDTO({ buildingId: buildingId, buildingDiaryId: diaryId })} open={investorNewTaskOpen} selectedValue={t('singleTodo.newTask')} onClose={() => { setInvestorNewTaskOpen(false); refreshDiary(); setReloadTasks(true); }} />
            <MessageDialog onClose={() => setMessageOpen(false)} text={t('diary.diaryPrintLargeText')} title={t('diary.diaryPrintLargeTitle')} open={messageOpen} />
        </>
    );
}
export default withLoading(InvestorDiaryMainScene);
