import { Close } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog, IconButton, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from 'react-media-hook';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { RequestConversationDTO } from '../../../ApiOld/ApiServerVov';
import withLoading, { WithLoadingProps } from '../../Shared/Loader';

interface TodoInvestorChatSceneProps extends WithLoadingProps {
    requestId: number
}

function TodoInvestorChatScene(props: TodoInvestorChatSceneProps) {
    const { requestId, fireLoading, stopLoading } = props;
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [conversation, setConversation] = useState<RequestConversationDTO[]>([])
    const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
    const theme = useTheme();

    useEffect(() => {
        refreshData();
    }, []);


    function refreshData() {
        fireLoading!();
        new APIVovInvestorHelper().GetRequestConversation(requestId).then((r) => {
            setConversation(r);
            stopLoading!();
            new APIVovInvestorHelper().MarkRequestConversationAsRead(requestId);
            if (bottomScroll.current)
                bottomScroll.current!.scrollIntoView({ behavior: "auto" });
        });
    }

    function isImage(filename: string): boolean {
        var fileExt = filename.split('.').pop();
        if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png')
            return true;
        else
            return false;
    }
    const bottomScroll = useRef<HTMLDivElement>(null);
    return (<div>
        <div style={{
            borderRadius: '5px', backgroundColor: '#F5F5F5', borderColor: 'lightgray', paddingTop: '6px',
            borderWidth: '1px', borderStyle: 'solid', maxHeight: '300px', overflowY: 'auto'
        }}>
            {
                conversation && conversation.map((item, i) => {
                    return (<div style={{ position: 'relative' }}>
                        <div style={{
                            width: '45%', borderRadius: '5px', padding: '2px', margin: 'auto'
                            , marginLeft: item.investorId === null ? '12px' : 'auto', marginRight: item.investorId === null ? 'auto' : '12px'
                            , background: item.investorId === null ? '#F5F5F5' : 'white', border: '1px solid',
                            borderColor: item.investorId === null ? 'lightgray' : '#F5F5F5'
                        }}>
                            <div style={{ margin: '5px', marginLeft: '10px' }}>
                                <div style={{ fontSize: '10px', fontWeight: 'bold', borderRadius: '6px', background: item.investorId === null ? theme.palette.primary.main : theme.palette.success.main, padding: '1px 4px', paddingLeft: '10px' }}>{item.name}</div>
                                <div style={{ whiteSpace: 'pre-wrap', marginLeft: '4px', marginTop: '4px' }}>{item.text}</div>
                                <div style={{ fontSize: '12px', margin: '4px 2px' }}>
                                    {
                                        item.fileUrl && isImage(item.fileUrl) && <img onClick={() => { setSelectedImage(item.fileUrl) }} src={item.fileThumbnailUrl} width={80} height={60} placeholder={item.fileName}></img>
                                    }
                                    {
                                        item.fileUrl && !isImage(item.fileUrl) && <a style={{ wordWrap: 'break-word', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px' }} onClick={() => {
                                            window.open(item.fileUrl, '_blank');
                                        }} >{item.fileName !== undefined && item.fileName !== null && item.fileName !== '' ? item.fileName : item.fileUrl}</a>
                                    }
                                </div>
                                <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 2 }}>
                                    <div >
                                        <AccessTimeIcon sx={{ height: '14px', width: '14px' }} />
                                    </div>
                                    <div style={{ fontSize: '10px', marginTop: '5px', fontWeight: 'bold', marginLeft: '4px' }}>
                                        {item.datetime?.getTime() ? new Date(item.datetime.getTime() - (new Date().getTimezoneOffset() * 60000)).toLocaleDateString(i18n.language) : ''} {item.datetime?.getTime() ? new Date(item.datetime.getTime() - (new Date().getTimezoneOffset() * 60000)).toLocaleTimeString(i18n.language) : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={{ height: '8px' }}></div>
                    </div>);
                })
            }
            <div style={{ float: "left", clear: "both" }}
                ref={bottomScroll}>
            </div>
        </div>

        <Dialog maxWidth="lg" open={selectedImage !== undefined} sx={{ marginTop: '100px' }} onClose={() => { setSelectedImage(undefined) }}>
            <div >
                <IconButton onClick={() => { setSelectedImage(undefined) }} style={{ float: 'right' }}><Close /></IconButton>
                {selectedImage !== undefined && <img style={{ gridArea: '1/1' }} loading='lazy' src={selectedImage} />}
            </div>
        </Dialog>
    </div>);
};
export default withLoading(TodoInvestorChatScene);