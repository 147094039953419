

import { AddCircleOutline, Grading } from '@mui/icons-material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Column } from '@syncfusion/ej2-react-grids';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { BuildingForProjectsListPageDTO, GetBuildingsPermissionsResponse, SetBuildingIsActiveRequest, SetBuildingShowToEmployeesRequest, VovClient } from '../../../ApiOld/ApiServerVov';

import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIOldBuildingsClient } from '../../../ApiOld/ApiOldBuildingsClient';
import { Box, Button, Card, Switch, Tooltip } from '@mui/material';
import { useBuildingsPermissionContext } from '../../../Contexts/Permissions/BuildingsPermissionContext';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useApi } from '../../../ApiOld/Api/useApi';

InitSfGridLocale();
interface ProjectsListProps {
}

export default function ProjectsList(props: ProjectsListProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };


    const [projects, setProjects] = useState<BuildingForProjectsListPageDTO[] | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const { authorizedApi } = useApi(VovClient);

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (permissions === null) {
            reloadPermissions();
        }
        console.log(permissions);
    }, [reloadPermissions, permissions]);


    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const [loading, setLoading] = useState(true);



    function GridTemplate(props: BuildingForProjectsListPageDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.buildingsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{ fontWeight: hover ? 'bold' : 'normal', cursor: 'pointer', textDecoration: 'underline' }}>{props.name!}</a>
            </div>);
        else return <div >{props.name!}</div>
    }


    function navigateToDetail(data: BuildingForProjectsListPageDTO) {
        navigate(
            { pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/projectDetail/' + data.id!.toString(), },
            { state: { id: data.id, inputRecords: projects } }
        );
    }

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const excelQueryCellInfo = (args: ExcelQueryCellInfoEventArgs): void => {
        if ((args.column as Column).field === 'isActive') {
            if ((args.data as BuildingForProjectsListPageDTO).isActive) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'showToEmployees') {
            if ((args.data as BuildingForProjectsListPageDTO).showToEmployees) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }

    }

    const grid = useRef<GridComponent>(null);

    function renderAssignedUsers(arrayOfObjects: any) {
        let concatenatedNames = '';
        for (let i = 0; i < arrayOfObjects.length; i++) {
            concatenatedNames += arrayOfObjects[i].name;
            if (i < arrayOfObjects.length - 1) {
                concatenatedNames += ', ';
            }
        }
        return <Tooltip title={concatenatedNames}><Box>{concatenatedNames}</Box></Tooltip>;
    }



    const refresh = () => {
        setLoading(true);
        authorizedApi.projects(false).then((r) => {
            setProjects(r ? r.buildingsForProjectsListPage : []);
            setLoading(false);
        });
    }
    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    useEffect(() => {
        setBackMenuFunction(undefined);
        setMenuButtons(<Button
            size='small'
            sx={{ mr: 2 }}
            startIcon={<AddCircleOutline />}
            variant='contained' onClick={() => {
                if (permissions ? permissions.buildingsCreate : false)
                    navigate({ pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/projectDetail/' });
            }
            }>{t('Add')}</Button>);
        // console.log('refreshing');
        refresh();
    }, [setBackMenuFunction, setMenuButtons, permissions, i18n.language, params, navigate, t]);

    const changeIsActive = (args) => {
        if (permissions && permissions.buildingsEdit) {
            if (grid.current) {
                let rowDetails = grid.current.getRowInfo(args.currentTarget);
                if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                    (rowDetails.rowData as BuildingForProjectsListPageDTO).isActive = args.target.checked;

                    grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource

                    grid.current.refresh();
                    authorizedApi.setBuildingIsActive(new SetBuildingIsActiveRequest({ id: (rowDetails.rowData as BuildingForProjectsListPageDTO).id, isActive: args.target.checked }));
                }
            }
        }
    }

    const changeShowToEmps = (args) => {
        if (permissions && permissions.buildingsEdit) {
            if (grid.current) {
                let rowDetails = grid.current.getRowInfo(args.currentTarget);
                if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                    (rowDetails.rowData as BuildingForProjectsListPageDTO).showToEmployees = args.target.checked;

                    grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource

                    grid.current.refresh();
                    authorizedApi.setBuildingShowToEmployees(new SetBuildingShowToEmployeesRequest({ id: (rowDetails.rowData as BuildingForProjectsListPageDTO).id, showToEmployees: args.target.checked }));
                }
            }
        }
    }

    function cellTemplateActive(props: BuildingForProjectsListPageDTO) {
        return <Switch size='small' checked={props.isActive} onChange={(e, ch) => changeIsActive(e)} />
    }
    function cellTemplateShowToEmps(props: BuildingForProjectsListPageDTO) {
        return <Switch size='small' checked={props.showToEmployees} onChange={(e, ch) => changeShowToEmps(e)} />
    }
    function cellTemplateState(props: BuildingForProjectsListPageDTO) {
        switch (props.state) {
            case 0:
                return t('buildings.preparing');
            case 1:
                return t('buildings.realization');
            case 2:
                return t('buildings.completed');
            case 3:
                return t('buildings.cancelled');
        }

    }

    if (projects)
        return <div style={{ maxWidth: '100vw' }}>


            <Card sx={{ margin: 3, padding: 3, height: '100%' }} elevation={3}>
                <UpperMenu moduleName={t('buildings.projects')}
                    gridType={GridType.Projects}
                    canAdd={permissions ? permissions.buildingsCreate : false}
                    // onCreate={(e) => {

                    // }}
                    canTrash
                    canDelete={permissions ? permissions.buildingsDelete : false}
                    onDelete={(e) => { new APIOldBuildingsClient().deleteBuildings(e as number[]).then((e) => { if (e) refresh(); }) }}
                    canChangeEyeView={false}
                    onThrashClicked={() => { setTrashOpen(true); }}
                    grid={grid} gridId='grid-component-projects_list' canChangeGroup canToolbarGrid
                    menuName={t('View')}
                    searchLabel={t('SearchInProjects')}
                    canExcelExport
                />

                {!loading && <GridComponent ref={grid} id="grid-component-projects_list" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    excelQueryCellInfo={excelQueryCellInfo}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        navigateToDetail(e.rowData as BuildingForProjectsListPageDTO)
                    }}
                    rowHeight={30}
                    // toolbar={toolbarOptions}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    // searchSettings={searchOptions}
                    style={{ backgroundColor: 'white', overflow: 'auto', height: '100%' }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={projects}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='50' minWidth='50' />
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                        <ColumnDirective field='code' headerText={t('buildings.buildingCode')} width='120' minWidth='100' />
                        <ColumnDirective field='name' template={GridTemplate} headerText={t('buildings.buildingName')} width='120' minWidth='100' />
                        <ColumnDirective field='assignedUsersString' headerText={t('buildings.assignedUsers')} width='120' minWidth='100' allowFiltering={false} />
                        {/*  <ColumnDirective field='assignedUsers' template={(props: any) => renderAssignedUsers(props.assignedUsersForProjectListPage)} headerText={t('buildings.assignedUsers')} width='120' minWidth='100' filterTemplate={filterTemplate} /> */}
                        <ColumnDirective field='isActive' type='boolean' template={cellTemplateActive} headerText={t('active')} width='100' minWidth='100' />
                        <ColumnDirective field='showToEmployees' type='boolean' template={cellTemplateShowToEmps} headerText={t('showToEmployees')} width='100' minWidth='100' />
                        <ColumnDirective field='hourRate' headerText={t('hourlyRate')} width='120' minWidth='100' />
                        <ColumnDirective field='projectType' headerText={t('buildings.houseType')} width='120' minWidth='100' />
                        <ColumnDirective field='contractNumber' headerText={t('buildings.contractNumber')} width='120' minWidth='100' />
                        <ColumnDirective field='investor' headerText={t('Investor')} width='120' minWidth='100' />
                        <ColumnDirective field='projektant' headerText={t('Architect')} width='120' minWidth='100' />
                        <ColumnDirective field='address' headerText={t('address')} width='120' minWidth='100' />
                        <ColumnDirective field='state' headerText={t('buildings.projectState')} template={cellTemplateState} width='120' minWidth='100' allowFiltering={false} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, /* Search,  */Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent>}
            </Card>
            <div>
                <TrashGridDialog trashType={TrashType.Projects} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
            </div>
        </div>
    else if (permissions && !permissions.buildingsList)
        return <div>{t('noViewingPermissions')}</div>;
    else
        return <div>{t('noRecords')}</div>;
}
