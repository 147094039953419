import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';


interface HtmlConcurrencyViewerDialogProps {
    open: boolean;
    onClose: () => void;
    myHtml?: string;
    concurrencyHtml?: string;
}

export default function HtmlConcurrencyViewerDialog(props: HtmlConcurrencyViewerDialogProps) {
    const { open, onClose, myHtml, concurrencyHtml } = props;
    return (<Dialog
        open={open}
        sx={{ marginTop: '40px' }}
        maxWidth="xl"
        onClose={() => { onClose() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Texty byly změněny
        </DialogTitle>
        <DialogContent>
            {myHtml && concurrencyHtml && <div style={{ display: 'flex' }}>
                <div>
                    <div style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '20px' }}>Můj text</div>
                    <div style={{ minWidth: '250px', borderRadius: '6px', border: '1px solid', borderColor: 'lightgray', margin: '12px', padding: '8px' }} dangerouslySetInnerHTML={{ __html: myHtml }} />
                </div>
                <div>
                    <div style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '20px' }}>Nový změnený text</div>
                    <div style={{ minWidth: '250px', borderRadius: '6px', border: '1px solid', borderColor: 'lightgray', margin: '12px', padding: '8px' }} dangerouslySetInnerHTML={{ __html: concurrencyHtml }} />
                </div>
            </div>}
            {(myHtml === undefined || concurrencyHtml === undefined) && <div>bad data</div>}
        </DialogContent>
    </Dialog>)

}
