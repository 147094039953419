import { ChevronRight, CompressOutlined, Engineering, ExpandMore, GroupsOutlined, PrecisionManufacturingOutlined, RateReview, WidgetsOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import { useMediaPredicate } from 'react-media-hook';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import { DailyRecordSearchDTO, RecordSearchDTO } from '../../../ApiOld/ApiServerVov';
import moment from 'moment';
import { useParams } from "react-router";

interface DiarySearchRecordProps {
    recordId: number,
    dateInput: Date,
    employeesInput: RecordSearchDTO[] | undefined,
    machinesInput: RecordSearchDTO[] | undefined,
    materialsInput: RecordSearchDTO[] | undefined,
    worksInput: RecordSearchDTO[] | undefined,
    otherNotesInput: RecordSearchDTO[] | undefined,
    isInvestor?: boolean,
    isSubdodavatel?: boolean;
    fromExternal: boolean;
}
export default function DiarySearchRecord(props: DiarySearchRecordProps) {
    const { recordId, employeesInput, machinesInput, materialsInput, otherNotesInput, isInvestor, isSubdodavatel, fromExternal } = props;
    const grid = useRef<GridComponent>(null);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const [employees, setEmployees] = useState<RecordSearchDTO[] | undefined>();
    const [machines, setMachines] = useState<RecordSearchDTO[] | undefined>();
    const [materials, setMaterials] = useState<RecordSearchDTO[] | undefined>();
    const [works, setWorks] = useState<RecordSearchDTO[] | undefined>();
    const [otherNotes, setOtherNotes] = useState<RecordSearchDTO[] | undefined>();

    const [mainExpand, setMainExpand] = React.useState(false);
    const [machineExpand, setMachineExpand] = React.useState(true);
    const [employeesExpand, setEmployeesExpand] = React.useState(true);
    const [materialsExpand, setMaterialsExpand] = React.useState(true);
    const [worksExpand, setWorksExpand] = React.useState(true);
    const [otherNotesExpand, setOtherNotesExpand] = React.useState(true);
    const [datum, setDatum] = React.useState<Date | undefined>(undefined);
    const { t } = useTranslation();
    const theme = useTheme();
    const params = useParams();

    useEffect(() => {

    }, []);

    useEffect(() => {
        setSearchResult(props);
    }, [props]);

    function setDefaultState() {
        setEmployees([]);
        setMachines([]);
        setMaterials([]);
        setWorks([]);
        setOtherNotes([]);
    }

    function setSearchResult(data: DiarySearchRecordProps) {
        setEmployees(data!.employeesInput);
        setMachines(data!.machinesInput);
        setMaterials(data!.materialsInput);
        setWorks(data!.worksInput);
        setOtherNotes(data!.otherNotesInput);

        setDatum(data.dateInput);
    }

    return (
        <div>
            <div style={{ backgroundColor: '#F2F2F2', border: '1px solid', borderColor: '#C3C3C3', marginBottom: '2px', marginRight: '20px' }}>
                <div style={{ backgroundColor: '#F2F2F2' }}>
                    <Accordion className='expander' expanded={mainExpand} onChange={() => { setMainExpand(!mainExpand); }}>
                        <AccordionSummary sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }} aria-controls="panel1d-content" id="panel1d-header">
                            <div style={{ marginTop: '10px' }}>
                                {mainExpand ? <ExpandMore /> : <ChevronRight />}
                            </div>
                            <Typography fontWeight='bold' sx={{ marginTop: '10px', marginRight: '20px' }}><a style={{ color: theme.palette.secondary.main }} href={isInvestor === undefined || isInvestor === false ? (isSubdodavatel !== true ? ('/' + params['lang'] + '/' + params['domain'] + '/userlayout/diary/diaryvov/' + recordId) : ('/' + params['lang'] + '/' + params['domain'] + '/subcontractor/main/diaries/' + recordId)) : ('/' + params['lang'] + '/' + params['domain'] + '/investor/main/tabs/diary/' + recordId)} >{moment(datum).format("DD.MM.YYYY")}</a></Typography>
                            <div style={{ display: 'flex' }}>
                                {
                                    machines && machines.length > 0 &&
                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <div style={{ paddingRight: '10px', fontWeight: 'bold', fontSize: '12px', backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 6px 3px 1px', margin: '5px' }} >
                                            <PrecisionManufacturingOutlined sx={{ marginLeft: '10px', marginRight: '10px' }} />
                                            {t('diary.machines')}
                                        </div>
                                    </div>
                                }
                                {
                                    employees && employees.length > 0 &&
                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <div style={{ paddingRight: '10px', fontWeight: 'bold', fontSize: '12px', backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 6px 3px 1px', margin: '5px' }} >
                                            <GroupsOutlined sx={{ marginLeft: '10px', marginRight: '10px' }} />
                                            {t('diary.workers')}
                                        </div>
                                    </div>
                                }
                                {
                                    materials && materials.length > 0 &&
                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <div style={{ paddingRight: '10px', fontWeight: 'bold', fontSize: '12px', backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 6px 3px 1px', margin: '5px' }} >
                                            <WidgetsOutlined sx={{ marginLeft: '10px', marginRight: '10px' }} />
                                            {t('diary.materials')}
                                        </div>
                                    </div>
                                }
                                {
                                    works && works.length > 0 &&
                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <div style={{ paddingRight: '10px', fontWeight: 'bold', fontSize: '12px', backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 6px 3px 1px', margin: '5px' }} >
                                            <Engineering sx={{ marginLeft: '10px', marginRight: '10px' }} />
                                            {t('diary.completedWorks')}
                                        </div>
                                    </div>
                                }
                                {
                                    otherNotes && otherNotes.length > 0 &&
                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <div style={{ paddingRight: '10px', fontWeight: 'bold', fontSize: '12px', backgroundColor: '#FFC600', zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 6px 3px 1px', margin: '5px' }} >
                                            <RateReview sx={{ marginLeft: '10px', marginRight: '10px' }} />
                                            {t('diary.anotherNotes')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: '#F2F2F2' }}>
                            {
                                machines && machines.length > 0 && <Accordion className='expander' expanded={machineExpand} onChange={() => { setMachineExpand(!machineExpand) }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }}>
                                        <PrecisionManufacturingOutlined />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.machines')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ backgroundColor: '#FFFFFF' }}>
                                        {
                                            machines.map((item, i) => {
                                                return (<div key={i}> {item.name} {item.amount}{item.unit} </div>);
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                employees && employees.length > 0 && <Accordion className='expander' expanded={employeesExpand} onChange={() => { setEmployeesExpand(!employeesExpand); }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }}>
                                        <GroupsOutlined />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.workers')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            employees.map((item, i) => {
                                                return (<div key={i}> {item.name} {item.amount}{item.unit} {item.groupName}</div>);
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                materials && materials.length > 0 && <Accordion className='expander' expanded={materialsExpand} onChange={() => { setMaterialsExpand(!materialsExpand); }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }}>
                                        <WidgetsOutlined />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.materials')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            materials.map((item, i) => {
                                                return (<div key={i}> {item.name} {item.amount}{item.unit} </div>);
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                works && works.length > 0 && <Accordion className='expander' expanded={worksExpand} onChange={() => { setWorksExpand(!worksExpand); }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }}>
                                        <Engineering />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.completedWorks')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            works.map((item, i) => {
                                                return (<div key={i} dangerouslySetInnerHTML={{
                                                    __html: item.name ? item.name : ''
                                                }} />);
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                otherNotes && otherNotes.length > 0 && <Accordion className='expander' expanded={otherNotesExpand} onChange={() => { setOtherNotesExpand(!otherNotesExpand); }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#FFFFFF', border: '1px solid', borderColor: '#C3C3C3' }}>
                                        <RateReview />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.anotherNotes')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            otherNotes.map((item, i) => {
                                                return (<div key={i} dangerouslySetInnerHTML={{
                                                    __html: item.name ? item.name : ''
                                                }} />);
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>);
}