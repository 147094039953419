import { Check, Clear, DriveFileRenameOutline, EditOutlined, PictureAsPdf, Replay } from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Button, CircularProgress, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { FileParameter, GetSignaturesForRecordResponse, SignatureDTO } from '../../../../ApiOld/ApiServerVov';
import { Accordion, AccordionSummary, AccordionDetails } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';
interface SignaturesWidgetProps {
    recordId?: number;
    isExpanded: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    onSigned?: () => void;
    isLocked?: boolean;
    refresh?: boolean;
    onRefreshed?: () => void;
    onSigniClicked?: () => void;
    onExpandChange: (expanded: boolean) => void;
}

export default function SignaturesWidget(props: SignaturesWidgetProps) {
    const [signatures, setSignatures] = React.useState<GetSignaturesForRecordResponse | null>(null);
    const [signatureChanged, setSignatureChanged] = React.useState<boolean>(false);
    const [uploadingSignedPDF, setUploadingSignedPDF] = React.useState<boolean>(false);
    const [signedPDFPath, setSignedPDFPath] = React.useState<string | undefined>(undefined);
    const { recordId, isExpanded, isInvestor, onSigned, isLocked, isSubdodavatel, refresh, onRefreshed, onExpandChange } = props;
    const { permissions } = useDiaryTabsContext();
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(1100));
    const signRef = useRef<SignatureComponent>(null);
    const { t, i18n } = useTranslation();
    function refreshSignatures() {
        console.log("Isvestor", isInvestor);
        if (recordId !== undefined)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetSignaturesForRecord(recordId).then((data) => {
                if (data !== undefined) {
                    setSignatures(data); setSignedPDFPath(data.digitalSignaturePath);
                }

                if (onRefreshed)
                    onRefreshed();
            });
    }

    useEffect(() => {
        refreshSignatures();
    }, [recordId]);
    useEffect(() => {
        if (refresh === true)
            refreshSignatures();
    }, [refresh]);
    async function uploadSignedPDF(files: FileList) {
        if (recordId) {
            setUploadingSignedPDF(true);


            let fileParameter: FileParameter = { data: files.item(0)!, fileName: files.item(0)!.name };
            //let a: any = null;

            let a = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).UploadDigitallySignedPDF(fileParameter, recordId);

            // new APIVovHelper()(recordId, fileParameter).then((e) => {
            if (a.ok && a.url) {
                setUploadingSignedPDF(false);
                setSignedPDFPath(a.url);

                // if (isUploading)
                //     isUploading(false);
                // e.url
            }
            // });


            // .then((e) => {



        }

    }
    const inputFile = useRef<HTMLInputElement | null>(null);
    return (
        <div>
            <input

                accept="application/pdf"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files.length === 1)
                        uploadSignedPDF(e.target.files);

                }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            <Accordion sx={{ mt: 0, marginBottom: '-1px' }} className='expander' expanded={isExpanded} onChange={(e, ex) => { console.log('aha'); onExpandChange(ex); }}>
                <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel1d-content" id="panel1d-header">

                    <DriveFileRenameOutline color='secondary' className='accordion-icon' />
                    <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.signatures')}</Typography>

                    {signedPDFPath !== undefined && signedPDFPath !== null && !uploadingSignedPDF && <Tooltip enterTouchDelay={0} title={t('diary.DigitallySigned')}>
                        <div onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (signedPDFPath !== undefined) {
                                const link = document.createElement('a');
                                // link.download = "prdel.xlsx";
                                link.href = signedPDFPath;
                                link.target = '_blank';
                                document.body.appendChild(link);
                                link.click();
                                try {
                                    document.body.removeChild(link);
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }} style={{
                            cursor: 'pointer', width: '20px', height: '20px', background: theme.palette.success.main, borderRadius: '20px'
                            , marginLeft: '20px', marginRight: '10px', marginTop: '2px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px'
                        }}>
                            <Check style={{ width: '16px', height: '16px', color: 'white', marginLeft: '2px', marginTop: '-4px' }} />
                        </div>
                    </Tooltip>}
                    {uploadingSignedPDF && <CircularProgress sx={{ marginLeft: '20px' }} size={20} />}
                    {signatures !== null && signatures !== undefined && signatures.signatures !== undefined && <div style={{ display: 'flex' }}>
                        {biggerThan700 ? signatures.signatures.map((item) => {
                            return <div style={{
                                marginTop: '0px', marginLeft: '8px', backgroundColor: item.isInvestor ? theme.palette.success.main : (item.isMySignature ? theme.palette.primary.main : 'lightgray'),
                                padding: '4px 10px', borderRadius: '10px'
                            }}><Typography sx={{
                                color: item.isInvestor ? 'white' : theme.palette.secondary.main,
                                fontSize: '10px', fontWeight: 'bold'
                            }}>{item.userName}</Typography></div>
                        }) :
                            <Tooltip
                                enterTouchDelay={0}
                                title={signatures.signatures.map((signature) => signature.userName).join(', ')}
                            >
                                <AvatarGroup
                                    total={signatures.signatures.length}
                                    sx={{
                                        ml: 1,
                                        '& .MuiAvatar-root': { width: '20px', height: '20px' },
                                    }}
                                >
                                    {signatures.signatures[0] && (
                                        <Avatar
                                            sx={{
                                                bgcolor: signatures.signatures[0].isInvestor
                                                    ? 'white'
                                                    : theme.palette.secondary.main,
                                            }}
                                        >
                                            {signatures.signatures[0].userName?.charAt(0)}
                                        </Avatar>
                                    )}
                                </AvatarGroup>
                            </Tooltip>
                        }

                    </div>}

                </AccordionSummary>
                <AccordionDetails>
                    {(isLocked === undefined || isLocked === false) && <Tooltip enterTouchDelay={0} title={t('diary.uploadDigitallySignedPDF')}>
                        <Button variant='contained' disabled={uploadingSignedPDF} size='small' style={{ height: '26px', margin: '8px' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                inputFile.current!.click();
                            }}
                            startIcon={<PictureAsPdf />} >{biggerThan700 ? t('diary.uploadDigitallySignedPDF') : ''}</Button>
                    </Tooltip>}
                    {permissions && permissions.hasSigni && <Tooltip enterTouchDelay={0} title={t('diary.sign')}>
                        <Button variant='contained' disabled={uploadingSignedPDF} size='small' style={{ height: '26px', margin: '8px' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (props.onSigniClicked)
                                    props.onSigniClicked();
                            }}
                            startIcon={<EditOutlined />} >{biggerThan700 ? t('diary.tiskSigni') : ''}</Button>
                    </Tooltip>}
                    {recordId !== undefined &&
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {signatures === null || signatures === undefined || signatures.signedByLoggedIn === undefined || signatures.signedByLoggedIn === false &&
                                <div style={{ margin: '6px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'lightgray', padding: '0px', borderRadius: '6px', width: biggerThan700 ? '300px' : '100%' }}>
                                    <Typography sx={{
                                        fontWeight: '400', fontSize: '13px', margin: 1.5,
                                    }}>{t('diary.yourSignature')}</Typography>
                                    <SignatureComponent change={(e) => {
                                        setSignatureChanged(true);
                                    }} style={{
                                        maxWidth: biggerThan700 ? '300px' : '100%', margin: '0px',
                                        backgroundColor: '#F9F9F9'
                                    }}
                                        ref={signRef}></SignatureComponent>
                                    <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Button sx={{
                                            width: biggerThan700 ? '106px' : '32px', margin: '4px 12px', background: 'black', color: 'white', '&:hover': {
                                                background: '#444'
                                            }
                                        }} disabled={!signatureChanged} startIcon={<Replay />} variant='contained' size='small' onClick={(e) => {
                                            signRef.current!.clear();
                                            setSignatureChanged(false);
                                        }}>{biggerThan700 && t('Cancel')}</Button>
                                        <Button sx={{ width: biggerThan700 ? '106px' : '32px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Check />} variant='contained' size='small' onClick={(e) => {
                                            setSignatureChanged(false);
                                            var blob = signRef.current!.saveAsBlob();
                                            let fileParameter: FileParameter = { data: blob, fileName: 'sign.png' };
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).UploadSignature(fileParameter, recordId, isInvestor === undefined ? false : isInvestor).then((e) => {
                                                if (e.ok === true) {
                                                    refreshSignatures();
                                                    if (onSigned)
                                                        onSigned();
                                                }
                                            });
                                        }}>{biggerThan700 && t('diary.sign')}</Button>
                                    </div>
                                </div>}
                            {signatures && signatures.signatures && signatures!.signatures!.length > 0 && signatures!.signatures!.map((item, i) => {
                                return <div key={i} style={{ margin: '6px', textAlign: 'center', border: '1px solid', borderColor: 'lightgray', borderRadius: '6px' }}>
                                    <div style={{ position: 'relative', top: '6px', right: '0px', float: 'right', margin: '0px 10px' }}>
                                        <div style={{
                                            backgroundColor: item.isInvestor ? theme.palette.success.main : (item.isMySignature ? theme.palette.secondary.main : 'lightgray'),
                                            borderRadius: '6px'
                                        }}>
                                            <Typography sx={{
                                                margin: '4px 10px', fontWeight: 'bold', fontSize: '13px',
                                                color: item.isMySignature ? 'white' : (item.isInvestor ? 'white' : theme.palette.secondary.main)
                                            }}>{item.userName}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: '8px' }}>
                                            <Typography sx={{ marginRight: '4px', fontSize: '10px', color: 'gray' }}>{item.dateTime && item.dateTime.toLocaleDateString(i18n.language)}</Typography>

                                            <Typography sx={{ fontSize: '10px', color: 'gray' }}>{item.dateTime && item.dateTime.toLocaleTimeString(i18n.language)}</Typography>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '240px',
                                        height: '150px',
                                        overflow: 'hidden', transform: 'translateY(30%)',
                                    }}>
                                        <img style={{
                                            position: 'relative', width: '240px', backgroundColor: 'transparent'
                                        }} height={100} src={item.url} />
                                    </div>
                                </div>
                            })}
                        </div>}
                    {recordId === undefined && <Typography>
                        bad data
                    </Typography>}
                </AccordionDetails>
            </Accordion>
        </div >
    );
}