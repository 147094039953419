
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, IEditCell, Inject, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit, CheckBoxChangeEventArgs } from '@syncfusion/ej2-react-grids';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetMaterialsForRecordTableDTO } from '../../../../ApiOld/ApiServerVov';
import { useDiaryMaterialsCopyStore } from '../../../../Contexts/DiaryMaterialsCopyZContext';
import InitSfGridLocale from '../../../../Localization/SfGridLanguageInitializer';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import { TabPanel } from '../../../Shared/Tabs/TabPanel';
import tabProps from '../../../Shared/Tabs/TabProps';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';

InitSfGridLocale();

interface MaterialsCopyWidgetProps {
    buildingDiaryRecordId: number | undefined;
    materialsNote?: string;
    materialsInput?: GetMaterialsForRecordTableDTO[];
    noteChanged?: (t: string) => void;
    onSelectionChanged?: (sel: number[]) => void;
    saveChanged?: boolean;
    isCopying: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
}

export default function MaterialsCopyWidget(props: MaterialsCopyWidgetProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, materialsInput, materialsNote, noteChanged, saveChanged, isCopying, isInvestor, isSubdodavatel, onSelectionChanged } = props;
    const [materials, setMaterials] = useState<GetMaterialsForRecordTableDTO[]>();
    const [materialsNoteLocal, setMaterialsNoteLocal] = useState<string | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [copying, setCopying] = useState<boolean>(false);
    const { materialsStore } = useDiaryMaterialsCopyStore();

    const saving = useRef<boolean>(false);

    const theme = useTheme();


    useEffect(() => {
        setMaterials(materialsStore);
    }, [materialsStore]);
    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);
    useEffect(() => {
        setMaterialsNoteLocal(materialsNote);
    }, [materialsNote]);
    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);
    function reloadData() {
        if (isCopying) {
            setMaterials(materialsStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetMaterialsForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (d !== undefined && d !== null) {
                        if (grid.current)
                            grid.current!.dataSource = d;
                        setMaterials(d);
                    }
                    saving.current = false;
                });
            else
                saving.current = false;
        }
    }
    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        if (materialsInput)
            setMaterials(materialsInput);
    }, [materialsInput]);
    const grid = useRef<GridComponent>(null);


    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    const loaded = useRef(false);
    return (
        <div style={{ background: 'white' }}>
            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('table')} {...tabProps(0)} />
                        {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    {/* <div style={{ display: selectedTabIndex === 0 ? 'block' : 'none' }}> */}
                    <GridComponent style={{ borderColor: 'transparent' }} id="grid-component-materials" allowSorting={true}
                        dataSourceChanged={(e) => { grid.current!.refresh(); }}
                        ref={grid}
                        allowReordering={true} allowResizing={true} showColumnChooser={true}
                        allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                        locale={i18n.language}
                        enablePersistence={false}
                        filterSettings={standardFilterOptions}
                        resizeSettings={{ mode: 'Normal' }}
                        allowFiltering={false}
                        dataSource={materials}
                        checkBoxChange={(e: CheckBoxChangeEventArgs) => { if (onSelectionChanged) onSelectionChanged(e.selectedRowIndexes ? e.selectedRowIndexes : []); }}
                    >
                        <ColumnsDirective>
                            <ColumnDirective headerText='' type='checkbox' width='50' field='selected' />
                            <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                            <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='amount' editType='numericedit' maxWidth='400' textAlign='Right' edit={numericParams}
                                headerText='Množství' width='100' format='N2' />
                            <ColumnDirective field='unit' headerText='Jednotka' maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='200' />
                            <ColumnDirective field='adminCreatorName' headerText={t('CreatedBy')} allowEditing={false} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                            <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' />
                        </ColumnsDirective>
                        <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                    </GridComponent>
                    {/* </div> */}
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div style={{ margin: '-10px -24px' }}>
                        <RichTextEditorComponent actionBegin={(e) => {
                        }} height={400} locale={i18n.language} value={materialsNoteLocal} change={(eve: ChangeEventArgs) => {
                            if (isSubdodavatel !== true) {
                                setMaterialsNoteLocal(eve.value);
                                if (noteChanged)
                                    noteChanged(eve.value);
                            }
                        }} >
                            <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                </TabPanel>
            </Box>

        </div>);

}


