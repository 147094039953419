import { Button, IconButton, Tooltip, Grid, Typography, CircularProgress, Checkbox, Menu, MenuItem, Box, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowScroller } from 'react-virtualized';
import { useMediaPredicate } from 'react-media-hook';
import { AddAPhoto, AutoFixHighOutlined, Delete, Flaky, Update } from '@mui/icons-material';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import { ImageCategoryDTO, ImageDiaryDTO, SetImagesShowToInvestorRequest, VovClient } from '../../../../ApiOld/ApiServerVov';
import i18n from '../../../../Localization/i18nInitializer';
import { load } from '@syncfusion/ej2-react-grids';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { useApi } from '../../../../ApiOld/Api/useApi';
import RenameImageDialog from '../Dialogs/RenameImageDialog';

interface ProjectPhotosTilesSceneProps {
    width?: number;
    canDelete?: boolean;
    images: ImageDiaryDTO[];
    buildingId: number;
    selectedImagesIds: number[];
    loading: boolean;
    allSelect: boolean;

    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    onDelete?: (value: ImageDiaryDTO) => void;
    onSelect: (ids: number[] | undefined) => void;
    onImageClick: (index: number | undefined) => void;
    onNameChange: (value: string, id: number) => void;
    setImages: (data: ImageDiaryDTO[]) => void;
    setAllSelect: (value: boolean) => void;
    onCategoryChange: () => void;
}

export default function ProjectPhotosTilesScene(props: ProjectPhotosTilesSceneProps) {
    const { images, canDelete, onDelete, buildingId, onImageClick, onNameChange, setImages, selectedImagesIds, allSelect, setAllSelect, onCategoryChange } = props;
    const [toDelete, setToDelete] = useState<ImageDiaryDTO | undefined>();
    const [renameImage, setRenameImage] = useState<ImageDiaryDTO>();
    const [renameOpen, setRenameOpen] = useState<boolean>(false);
    const [selected, setSelected] = React.useState<ImageCategoryDTO>();
    const { t } = useTranslation();
    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    const { authorizedApi } = useApi(VovClient);

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        if (images && images.length > 0 && selectedImagesIds.length === 1) {
            const section = document.querySelector('#image_' + selectedImagesIds[0].toString());
            if (section) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
        }
    }, [selectedImagesIds]);

    function handleDelete(image: ImageDiaryDTO) {
        if (image && permissions && permissions.buildingsEdit) {
            if (onDelete) onDelete(image);
        }
    }

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, data: ImageDiaryDTO) => {
        event.preventDefault();
        event.stopPropagation();
        setRenameImage(data);
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };
    const theme = useTheme();

    if (props.loading)
        return <CircularProgress />
    else
        return (
            <div>
                <div style={{ color: allSelect ? '#FFC600' : 'black', margin: '4px', marginLeft: '16px', cursor: 'pointer' }} onClick={() => {
                    props.onSelect(!allSelect ? images.map(x => x.id!) : []);
                    setAllSelect(!allSelect);
                }}><Box><Flaky color='inherit' />{t('selectUnselectAll')}</Box></div>
                <WindowScroller>
                    {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                        //@ts-ignore
                        <div ref={registerChild}>
                            <Grid container spacing={2} style={{ padding: '16px' }}>
                                {images.map((image, index) => (
                                    <Grid item key={image.id} xs={12} sm={12} md={12} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Tooltip title={image.name} placement='right-start'>
                                            <div
                                                className='parent'
                                                id={'image_' + image.id?.toString()}
                                                style={{
                                                    padding: '8px',
                                                    position: 'relative',
                                                    background: selectedImagesIds.some(p => p === image.id) ? 'lightgray' : 'white',
                                                    borderRadius: '10px',
                                                }}
                                                onContextMenu={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    /* setSelected(image);
                                                    if (props.onSelect) props.onSelect([image.id!]); */
                                                    handleContextMenu(e, image);
                                                }}
                                            >
                                                {canDelete && (
                                                    <Checkbox
                                                        color='primary'
                                                        sx={{ position: 'absolute', top: '8px', left: '5px' }}
                                                        checked={selectedImagesIds.includes(image.id!)}
                                                        onChange={(event) => {
                                                            if (event.target.checked) {
                                                                props.onSelect([...selectedImagesIds, image.id!]);
                                                            } else {
                                                                props.onSelect(selectedImagesIds.filter(x => x !== image.id));
                                                            }
                                                        }}
                                                    />
                                                )}
                                                <Tooltip title={t('buildings.showToInvestors')}>
                                                    <Checkbox
                                                        color='primary'
                                                        className='hidden-child'
                                                        sx={{ position: 'absolute', top: '10px', right: '55px' }}
                                                        checked={image.showToInvestor}
                                                        onChange={(event) => {
                                                            authorizedApi.setImagesShowToInvestor(new SetImagesShowToInvestorRequest({ imageId: image.id, showToInvestor: event.target.checked }));

                                                            setImages(images.map((x) => {
                                                                if (x.id === image.id) {
                                                                    x.showToInvestor = event.target.checked;
                                                                }
                                                                return x;
                                                            }));
                                                        }}
                                                    />
                                                </Tooltip>
                                                {canDelete && (
                                                    <Tooltip title={t('removeImage')}>
                                                        <IconButton
                                                            onClick={() => setToDelete(image)}
                                                            className='hidden-child'
                                                            color='secondary'
                                                            sx={{ position: 'absolute', top: '8px', right: '5px' }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <img
                                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (onImageClick) onImageClick(images.findIndex(x => x.url === image.url)) }}
                                                    src={image.thumbnailUrl}
                                                    height={260}
                                                    width={280}
                                                    loading='lazy'
                                                    style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '10px', border: '1px solid lightgray', cursor: 'pointer' }}
                                                />
                                                <Grid container spacing={1} style={{ padding: '4px' }}>
                                                    <Grid item xs={6}>
                                                        <Typography variant='caption'>{image.created ? image.created.toLocaleDateString(i18n.language) : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant='caption'>{image.creator ? image.creator : ''}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </WindowScroller>

                <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (renameImage) {
                                setRenameOpen(true);
                            }
                        }}
                    >
                        {t('rename')}
                    </MenuItem>
                    <MenuItem
                        disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onCategoryChange();
                        }}
                    >
                        {t('buildings.moveToFolder')}
                    </MenuItem>
                </Menu>

                <DeleteDialog data={toDelete} onDeleteAgree={(data) => {
                    handleDelete(data);
                    setToDelete(undefined);
                }} onClose={() => {
                    setToDelete(undefined);
                }} open={toDelete !== undefined} />
                <RenameImageDialog oldName={renameImage && renameImage.name ? renameImage.name : ''} open={renameOpen} onClose={() => { setRenameImage(undefined); setRenameOpen(false); setContextMenu(null); }} imageId={renameImage ? renameImage.id : undefined} onConfirm={(id, name) => {
                    onNameChange(name, id);
                }} />
            </div>
        );
}